// Update these if the enums in heracles_backend/src/providers/Corecard/coreCardProvider.ts are updated
export const TransactionHistoryType = [
    { name: 'Settled', value: '1' },
    { name: 'Outstanding', value: '2' },
    { name: 'Expired', value: '3' },
    { name: 'Warehoused', value: '4' },
    { name: 'Unbilled', value: '5' },
]
export const CoreCardTransactionCode = [
    { name: 'Domestic_Sale', value: '834240' },
    { name: 'International_Sale', value: '834241' },
    { name: 'ACH_PAYMENT', value: '834780' },
    { name: 'AUTO_ACH_PAYMENT', value: '834221' },
    { name: 'BALANCE_TRANSFER', value: '834036' },
]

export enum CharCode {
    Backspace = '8',
    Tab = '9',
    Delete = '46',
    NumDelete = '110',
    DotGrater = '190',
    END = '35',
    DOWN_ARROW = '40',
    KEY_0 = '48',
    KEY_9 = '57',
    NUMPAD_0 = '96',
    NUMPAD_9 = '105',
}
export const CoreCardRedeemRewardsDecision = [
    { name: 'Add', value: '0' },
    { name: 'Reduce', value: '1' },
    { name: 'Redeem', value: '2' },
]

export enum TransactionStatus {
    Settled = '1',
    Outstanding = '2',
    Expired = '3',
    Warehoused = '4',
    Unbilled = '5',
}

export enum TransactionType {
    CashOut = 'CashOut',
    CreditCard = 'CreditCard',
}

export enum CoreCardEmbossingManualStatus {
    Active = 2,
    Delinquent = 3,
    Overlimit = 4,
    Fraud = 7,
    Blocked = 10,
    Closed = 11,
}

export const PayTowardsBalanceOptions = [{ name: 'No', value: '0' }]

export const ReimbursementReasons = [
    { name: 'Payment Made in Error', value: 'Payment made in error' },
    { name: 'Customer Satisfaction', value: 'Customer Satisfaction' },
    { name: 'Customer Requested', value: 'Customer Requested' },
    { name: 'Unauthorized Transaction', value: 'Unauthorized Transaction' },
    { name: 'Reimbursement for Fees', value: 'Reimbursement for Fees' },
    { name: 'System Error', value: 'System Error' },
    { name: 'CSR Error', value: 'CSR Error' },
    { name: 'Customer Error', value: 'Customer Error' },
    { name: 'Collector Error', value: 'Collector Error' },
    { name: 'Fraudulent Payment', value: 'Fraudulent Payment' },
    { name: 'Duplicate Payment', value: 'Duplicate Payment' },
    { name: 'Processor Late', value: 'Processor Late' },
    { name: 'Other', value: 'Other' },
]

export enum AciAccountSubType {
    PERSONAL_CHECKING = 'PERSONAL_CHECKING',
    PERSONAL_SAVINGS = 'PERSONAL_SAVINGS',
    BUSINESS_CHECKING = 'BUSINESS_CHECKING',
    BUSINESS_SAVINGS = 'BUSINESS_SAVINGS',
}

export const BankAccountTypes = [
    { name: 'Personal Checking', value: AciAccountSubType.PERSONAL_CHECKING },
    { name: 'Personal Savings', value: AciAccountSubType.PERSONAL_SAVINGS },
    { name: 'Business Checking', value: AciAccountSubType.BUSINESS_CHECKING },
    { name: 'Business Savings', value: AciAccountSubType.BUSINESS_SAVINGS },
]

export const AutopayCategory = [
    { name: 'STATEMENT_BALANCE', value: 'STATEMENT_BALANCE' },
    { name: 'CURRENT_BALANCE', value: 'CURRENT_BALANCE' },
    { name: 'MINIMUM_DUE', value: 'MINIMUM_DUE' },
    { name: 'CUSTOM_AMOUNT', value: 'CUSTOM_AMOUNT' },
]

export const NYC_TIMEZONE = 'America/New_York'
export const CORE_DATE_TIME_FORMAT = 'MM/DD/YYYY hh:mm:ss'
export const DISPLAY_DATE_FORMAT = 'MMM D, YYYY'
export const AVEN_DATE_TIME_FORMAT = 'YYYY-MM-DDThh:mm:ss.SSSZ' //2020-12-01T05:00:00.000Z
export const MMM_DD_DATE_FORMAT = 'MMM DD'
export const MONTH_YEAR_FORMAT = 'MMMM YYYY'
export const MONTH_FORMAT = 'MMMM'

export interface BalanceTransferFormData {
    transferAmount: number
    accountNumber: string
    bankName: string
}

export enum ApiRequireSingleUseToken {
    balanceTransferCashOut = '/balanceTransfer/cashOut',
    balanceTransferCashOutWithBankStatement = '/balanceTransfer/cashOutWithBankStatement',
}

export enum PlaidAccountSubType {
    checking = 'checking',
    savings = 'savings',
}

export enum PlaidAccountType {
    personal = 'Personal',
    business = 'Business',
}

export const PLAID_LINK_CUSTOMIZATION_NAME = 'aven_cashout'

export enum ResetError {
    GENERIC,
    EXPIRED_TOKEN,
}

export const MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS = 50000
export const MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS = 50000
export const MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT = 5000
export const PAYMENT_AMOUNT_BEFORE_BLOCKING_CASH_OUT = 10000
export const MIN_DAYS_BETWEEN_PAYMENT_AND_CASH_OUT = 14
export const MIN_NEED_TO_CONFIRM_PAYMENT_AMOUNT = 3000

// Bank info constants need to stay in sync with the same constants defined in backend
export const BANK_ROUTING_NUMBER_LENGTH = 9
export const BANK_ACCOUNT_NUMBER_MIN_LENGTH = 5
export const BANK_ACCOUNT_NUMBER_MAX_LENGTH = 17

export interface IAmortizationLoanTerm {
    apr: number
    loanAmount: number
    loanTermInMonths: number
    monthlyPaymentAmountInDollars: number
    fixedFinanceChargesPerMonth: number
    fixedPrincipalPerMonth: number
}

export const CardReplacementReason = {
    DAMAGED: 'DAMAGED',
    LOST: 'LOST',
}
export const MAX_IN_APP_CARD_REPLACEMENT = 3

export enum DisplayableBalanceTransferOrCashOutStatus {
    Pending = 'pending',
    Canceled = 'canceled',
    Reversal = 'reversal',
    Completed = 'completed',
}

export const ERROR_BALANCE_TRANSFER_NOT_CANCELABLE = 'ERROR_BALANCE_TRANSFER_NOT_CANCELABLE'

export enum AmortizationSource {
    CASH_OUT = 'CASH_OUT',
    CREDIT_CARD_BALANCE_TRANSFER = 'CREDIT_CARD_BALANCE_TRANSFER',
}

// Keep in sync with aven_ios/avenApp/avenApp/viewControllers/AvenBaseViewController.swift
// NOTE: these are based on CNLabelPhoneNumber... values from the Swift Contacts framework
// see https://developer.apple.com/documentation/contacts/cnlabeledvalue
export enum PhoneLabel {
    IPhone = 'iPhone',
    Main = 'main',
    Mobile = 'mobile',
    OtherFax = 'otherFax',
    WorkFax = 'workFax',
    HomeFax = 'homeFax',
    Pager = 'pager',
    Unknown = 'unknown',
}

export const PAYOFF_WIRE_TRANSFER_DETAILS = {
    routing_number: 125108405,
    account_number: 1500040298,
    beneficiary_name: 'Coastal Community Bank',
}

// https://www.twilio.com/docs/api/errors
// 60200 invalid code
// 20404 expired code
// 60202 Max check attempts reached
// 60203 Max send attempts reached.
// 60212 too many concurrent requests
export const TWILIO_ERROR_TO_I18N_NAMES = new Map([
    [60200, 'twilioError.60200'],
    [20404, 'twilioError.20404'],
    [60202, 'twilioError.60202'],
    [60203, 'twilioError.60203'],
    [60212, 'twilioError.60212'],
])
