import router, { getRootAndDeeplinkFromPath, RoutePaths } from '@/routes/router'
import store, { AvenCardProductType } from '../store'
import { i18n } from '@/utils/i18n'
import format from '@/mixins/format'
import { logger } from '@/utils/logger'
import { appSessionStorage, localStorageKey } from '@/utils/storage'

export const postLoginNavigation = async () => {
    if (store.getters.isCryptoProduct) {
        await postLoginNavigationForCryptoProduct()
    } else {
        await postLoginNavigationForHomeProduct()
    }
}

const postLoginNavigationForCryptoProduct = async () => {
    if (store.getters.isCryptoProduct) {
        // TODO Crypto: Only show if you have not deposited Crypto
        store.commit('setCurrentRootPath', RoutePaths.ACTIVITY)
        await router.push(RoutePaths.DEPOSIT_CRYPTO)
    } else if (store.getters.showEnrollAutoPayAndKeepAprDiscount && !store.getters.alreadyShownEnrollInAutoPayAndKeepAprDiscount) {
        store.commit('setCurrentRootPath', RoutePaths.ACTIVITY)
        await router.push(RoutePaths.SETUP_AUTOPAY)
    } else {
        store.commit('setCurrentRootPath', RoutePaths.ACTIVITY)
        await router.push(RoutePaths.ACTIVITY)
    }
}

const postLoginNavigationForHomeProduct = async () => {
    if (store.getters.deeplinkPath.length > 0) {
        logger.info(`postLoginNavigationForHomeProduct, deeplink: ${store.state.deeplinkPath}`)
        await router.push({
            path: store.getters.deeplinkPath,
            query: {
                fromDeeplink: 'true',
            },
        })
        store.commit('updateDeeplinkPath', '')
    } else if (store.getters.isFirstSessionForCustomer) {
        await router.push(RoutePaths.PAY_IT_FORWARD_CONTACT_LIST)
    } else if (store.getters.showSignOnBonusRealEstateAgentScreen) {
        await router.push(RoutePaths.PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT)
    } else if (store.getters.isEligibleToPerformPayItForwardTipping) {
        await router.push(RoutePaths.PAY_IT_FORWARD_SEND_TIP)
    } else if (store.getters.showEnrollAutoPayAndKeepAprDiscount && !store.getters.alreadyShownEnrollInAutoPayAndKeepAprDiscount) {
        await router.push(RoutePaths.SETUP_AUTOPAY)
    } else {
        await router.push(store.getters.currentRootPath)
    }
}

export const pifNativeShareUrlString = function (shareLink: string, amount: number): String {
    const encodedSubject = encodeURI(
        i18n
            .t('email.sharePifLink.subject', {
                maxAmount: format.methods.toFormattedUSDNoCents(amount),
            })
            .toString()
    )
    const encodedMessage = encodeURI(
        i18n
            .t('email.sharePifLink.body', {
                maxAmount: format.methods.toFormattedUSDNoCents(amount),
                pifLink: shareLink,
            })
            .toString()
    )
    return `/sharePifLink?link=${shareLink}&message=${encodedMessage}&subject=${encodedSubject}`
}

window.handleDeepLinkFromNative = function (deepLink: string) {
    logger.log(`handleDeepLinkFromNative - ${deepLink}`)
    parseForDeeplinkWithPathFromNative(deepLink)
}

// iOS calls this on a warm launch or while in the foreground.
const parseForDeeplinkWithPathFromNative = async (path: string) => {
    logger.info(`parseForDeeplinkWithPathFromNative, Received path: ${path}`)

    const result = getRootAndDeeplinkFromPath(path)
    if (!result) {
        logger.info('parseForDeeplinkWithPathFromNative - parsed', 'NOTHING')
        return
    }
    logger.info(`parseForDeeplinkWithPathFromNative - parsed ${JSON.stringify(result)}`)

    store.commit('setCurrentRootPath', result.root)

    if (result.deeplink === '') {
        logger.info(`parseForDeeplinkWithPathFromNative - no content path, tab switch to ${JSON.stringify(result)}`)
        await router.push(store.getters.currentRootPath)
        return
    }
    logger.info(`parseForDeeplinkWithPathFromNative - deeplink content path ${result.deeplink}`)
    store.commit('updateDeeplinkPath', result.deeplink)

    if ([RoutePaths.CARD_ARRIVAL_CHECK.toString(), RoutePaths.SETUP_AUTOPAY.toString()].includes(router.currentRoute.path)) {
        return
    }
    if (!appSessionStorage.getItem(localStorageKey.jwtToken)) {
        logger.info(`parseForDeeplinkWithPathFromNative - deeplink saved and will execute in postLoginNavigation, saved deeplink path: ${result.deeplink}`)
        return
    }
    if (store.state.overview.productType !== AvenCardProductType.NONE) {
        await router.push({
            path: store.state.deeplinkPath,
            query: {
                fromDeeplink: 'true',
            },
        })
        store.commit('updateDeeplinkPath', '')
    }
}

// native device needs to update device capabilities since user might have enrolled
// in biometrics or permitted push notifications
window.updateDeviceCapabilities = function (json: string) {
    const deviceCapabilities = JSON.parse(json)
    store.commit('setDeviceCapabilities', deviceCapabilities)
}
