<template>
    <div
        class="transaction-row"
        :class="[{ hoverable: showArrow }]"
        @click="handleClick"
    >
        <div
            v-if="icon"
            class="icon"
        >
            <img
                :src="require(`@/assets/images/components/transactionRow/${icon}`)"
                alt=""
                width="32"
                height="32"
            >
        </div>

        <div class="left">
            <span
                v-if="date"
                class="date text-muted me-2"
            >
                {{ date }}
            </span>
            <span
                class="title"
                :class="highlightColor"
            >
                {{ title }}
            </span>
        </div>
        <div class="right">
            <p :class="highlightColor">
                {{ value }}
                <span
                    v-if="valueSubText"
                    class="text-muted"
                >
                    <br>
                    {{ valueSubText }}
                </span>
            </p>
            <img
                v-show="showArrow"
                class="arrow ms-1"
                src="@/assets/images/components/transactionRow/Arrow.svg"
                alt=""
            >
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TransactionRow',
        props: {
            title: {
                type: String,
                required: true,
            },
            date: {
                type: String,
                required: false,
            },
            showArrow: {
                type: Boolean,
                default: false,
            },
            value: {
                type: String,
                required: true,
            },
            valueSubText: {
                type: String,
                required: false,
            },
            icon: {
                type: String,
                required: false,
            },
            highlightColor: {
                type: String,
                required: false,
            },
        },
        methods: {
            handleClick() {
                this.$emit('onClick')
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/transactionRow';
</style>
