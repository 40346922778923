<template>
    <div>
        <validation-provider
            ref="provider"
            :mode="validationMode"
            :rules="validation"
            :vid="name"
            v-slot="{ errors }"
        >
            <div class="input">
                <label
                    v-if="label"
                    :for="name"
                >
                    {{ label }}
                </label>
                <div
                    class="mb-2"
                    :class="containerClasses"
                >
                    <input
                        class="form-control"
                        :type="inputType"
                        ref="formFieldInput"
                        :id="name"
                        :name="name"
                        :placeholder="dynamicPlaceholder"
                        :class="errors[0] ? 'is-invalid' : null"
                        :value="value"
                        :autocomplete="autocomplete"
                        :inputmode="inputmode"
                        :maxlength="maxLength"
                        @input="onInput($event.target.value)"
                        :data-testid="dataTestid"
                    >
                    <span
                        v-show="loading"
                        class="spinner spinner-border text-primary spinner-border-sm align-self-center"
                    />
                    <div
                        class="invalid-feedback"
                        data-testid="form-field-validation-error-msg"
                    >
                        {{ errors[0] }}
                    </div>
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputMixin } from '@/mixins/formInputMixin'

    export default {
        name: 'FormField',
        mixins: [formInputMixin],
        props: {
            containerClasses: {
                type: String,
                default: '',
            },
            dataTestid: {
                type: String,
            },
        },
        methods: {
            applyError(errorMessage) {
                this.$refs.provider.applyResult({
                    errors: [errorMessage], // array of string errors
                    valid: false, // boolean state
                    failedRules: {}, // should be empty since this is a manual error.
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../../styles/components/base/formField';
</style>
