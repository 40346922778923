import store from '@/store'
import { appSessionStorage, localStorageKey } from './storage'
import router from '@/routes/router'
import { logger } from '@/utils/logger'
import inspect from 'object-inspect'
import { i18n } from '@/utils/i18n'

// TODO: can we purify this handler? it does redirects, logging, returns strings -- inconsistent return values
// for example, we redirect on a 401. this means in places where we don't want to redirect on 401, we have to add checks in every component.
const ApiErrorHandler = function (error) {
    if (error?.response?.status === undefined || error.response.status === 0) {
        logger.error(inspect(error))
        return i18n.tc('global.errors.generic')
    }

    const isMobileAppWebView = store.getters.isWebView
    if (!isMobileAppWebView) {
        switch (error.response.status) {
            case 401:
                appSessionStorage.removeItem(localStorageKey.jwtTokens)
                router.push({ path: 'Login', query: { reason: 'authorization' } }).catch((error) => logger.info(JSON.stringify(error)))
                break
            case 400:
                logger.error(`Bad request ${inspect(error)}`)
                return i18n.tc('global.errors.badRequest')
            default:
                logger.error(`API error ${inspect(error)}`)
                return i18n.tc('global.errors.generic')
        }
    } else {
        logger.error(inspect(error))
        return i18n.tc('global.errors.generic')
    }
}
export { ApiErrorHandler }
