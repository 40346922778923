import store, { AvenCardProductType } from '../store'
import { isIOS } from '@/utils/parseUserAgents'
import { pifNativeShareUrlString } from '@/utils/sharedLogic'
import { RoutePaths } from '@/routes/router'

export default {
    methods: {
        goToPayItForward: async function () {
            window.logEvent('click_card_pay_it_forward')
            if (store.getters.isWebView) {
                if (isIOS()) {
                    await this.$router.push(RoutePaths.PAY_IT_FORWARD_CONTACT_LIST)
                    return
                } else {
                    window.location.href = pifNativeShareUrlString(store.getters.payItForwardShareLink, store.getters.payItForwardMaxAmount).toString()
                }
            } else {
                await this.$router.push(RoutePaths.PAY_IT_FORWARD)
                return
            }
        },
    },
}
