import { session } from '@/utils/session'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { passwordLogin } from '@/services/avenAppApi'
import store from '@/store'
import { runWithRetryLogic } from '@/services/http-client'
import { postLoginNavigation } from '@/utils/sharedLogic'
import { mapActions, mapGetters } from 'vuex'

export enum BiometricsError {
    cancelledByUser = 'cancelledByUser',
    noPermission = 'noPermission',
    noCredentialsFound = 'noCredentialsFound',
    platFormError = 'platFormError',
    invalidSavedCredentials = 'invalidSavedCredentials',
}

export interface AuthInfo {
    accountPhoneNumber: string
    sessionId: string
    sessionAccessJWT: string
    jwt: JWTToken
    coreCardCustomerId?: string
    creditCardCustomerId?: string
    passcode?: string
}

export interface NativeBiometricsResult {
    coreCardCustomerId?: string
    creditCardCustomerId?: string
    accountPhoneNumber: string
    passcode: string
    errorCode: string
}

export interface JWTToken {
    accessJWT: string
    refreshJWT: string
    accessExpiry: number
}

export interface BlockAppEvent {
    url?: string
    title: string
    description: string
    buttonText: string
    buttonActionUrl: string
}

export class AppBlockedError extends Error {
    public readonly blockEvent: BlockAppEvent

    constructor(blockAppEvent: BlockAppEvent) {
        super('App Blocked')
        // Set the prototype explicitly.
        this.blockEvent = blockAppEvent
        Object.setPrototypeOf(this, AppBlockedError.prototype)
    }
}

export default {
    computed: {
        ...mapGetters(['isAccountClosed', 'cardRequiresActivation', 'isSingleWebView', 'isWebView']),
    },
    methods: {
        ...mapActions(['updateAccountOverview']),
        inflateFromNativeBiometricsResult(biometricsResult: NativeBiometricsResult) {
            appSessionStorage.setItem(localStorageKey.creditCardCustomerId, biometricsResult.creditCardCustomerId)
            appSessionStorage.setItem(localStorageKey.accountPhoneNumber, biometricsResult.accountPhoneNumber)
        },
        injectAuthInfoToNative(passcode: string, jwt: JWTToken, passcodeReset: boolean = false) {
            const info: AuthInfo = {
                accountPhoneNumber: appSessionStorage.getItem(localStorageKey.accountPhoneNumber),
                sessionId: appSessionStorage.getItem(localStorageKey.sessionId),
                sessionAccessJWT: appSessionStorage.getItem(localStorageKey.sessionAccessJWT),
                jwt,
                creditCardCustomerId: appSessionStorage.getItem(localStorageKey.creditCardCustomerId),
                passcode: encodeURIComponent(passcode),
            }
            const command = `/signedIn?session=${JSON.stringify(info)}&passcodeReset=${passcodeReset}`
            logger.log(`inject native with authInfo: ${command}`)
            window.location.href = command
        },
        async loginWithPassword(password: string) {
            if (!session.sessionId) {
                const responseData = await session.sessionBegin()
                if (responseData?.error === 'APP_BLOCKED_ERROR') {
                    console.log(responseData.payload)
                    throw new AppBlockedError(responseData.payload)
                }
            }
            console.log(`biometrics login with passcoe`)
            const response = await passwordLogin(password)
            const data = response.data

            logger.info(`Passcode response response.data:${JSON.stringify(data)}`)

            appSessionStorage.setItem(localStorageKey.jwtToken, response.data.payload.jwt.accessJWT)
            logger.info('Saved jwt in session storage')

            const shouldInjectSessionToNative = this.isSingleWebView && this.isWebView
            logger.info(`singleWebView: ${this.isSingleWebView}, isWebView: ${this.isWebView}`)
            if (shouldInjectSessionToNative) {
                this.injectAuthInfoToNative(password, response.data.payload.jwt)
                if (appSessionStorage.getItem(localStorageKey.nativeInjectionLog)) {
                    // deeplink parsing happens before session starts. log is written to session storage
                    // now we send to backend post login
                    const log = JSON.parse(appSessionStorage.getItem(localStorageKey.nativeInjectionLog))
                    logger.info(`${localStorageKey.nativeInjectionLog}: ${log.map((l, i) => `${i}: ${l}`).join('\n')}`)
                    appSessionStorage.removeItem(localStorageKey.nativeInjectionLog)
                }
            } else {
                console.log(`Didn't send reverse injection data, shouldInjectSessionToNative: ${shouldInjectSessionToNative}`)
            }

            if (appSessionStorage.getItem(localStorageKey.routerLog)) {
                // deeplink parsing happens before session starts. log is written to session storage
                // now we send to backend post login
                const log = JSON.parse(appSessionStorage.getItem(localStorageKey.routerLog))
                logger.info(`${localStorageKey.routerLog}: ${log.map((l, i) => `${i}: ${l}`).join('\n')}`)
                appSessionStorage.removeItem(localStorageKey.routerLog)
            }
        },
        presentAppBlockedView: function (payload) {
            console.log(`appBlocked config: ${JSON.stringify(payload)}`)
            const blockEvent = payload.blockEvent || payload

            // see POST '/aven_app/appBlockEvents' endpoint
            // block event can be either a url
            // or a configuration dictionary
            if (payload.url) {
                window.location.href = blockEvent.url
            } else {
                this.$router.push({
                    name: 'AppBlocked',
                    params: {
                        title: blockEvent.title,
                        description: blockEvent.description,
                        buttonText: blockEvent.buttonText,
                        buttonActionUrl: blockEvent.buttonActionUrl,
                    },
                })
            }
        },
        getAccountOverview: async function () {
            await runWithRetryLogic(() => this.updateAccountOverview(), 3, 1000)
            // goto card arrival check if account is not closed and card requires activation
            if (!this.accountIsClosed && this.cardRequiresActivation) {
                console.log(`CardArrivalCheck`)
                await this.$router.push({
                    name: 'CardArrivalCheck',
                })
            } else {
                console.log(`postLoginNavigation`)
                await postLoginNavigation()
            }
        },
    },
}
