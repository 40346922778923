<template>
    <div :class="{ 'bg-light': isTopLevelPage }">
        <div class="account-banner-container">
            <account-activation-banner />
            <account-status-banner />
        </div>
        <modal
            :show="showIdleWarningModal"
            :title="$t('global.idleWarning.title')"
            @close="closeIdleWarningModal"
        >
            <p class="text-center mb-3">
                {{ $t('global.idleWarning.message') }}
            </p>
            <base-button
                class="mt-3"
                @click="logout('click_logout')"
            >
                {{ $t('global.idleWarning.logout') }}
            </base-button>
            <base-button
                class="mt-2"
                @click="closeIdleWarningModal"
                button-classes="btn btn-secondary"
            >
                {{ $t('global.idleWarning.stay') }}
            </base-button>
        </modal>
        <div class="dashboard-layout">
            <nav v-if="showNav">
                <img
                    class="logo"
                    src="@/assets/images/global/aven.svg"
                    width="71"
                    height="27"
                    alt="Aven"
                >
                <div
                    class="nav-item"
                    @click="onClickActivity"
                    @keydown.enter="navigation !== 'activity' ? $router.push('activity') : null"
                >
                    <div class="icon">
                        <img
                            v-show="navigation === 'activity'"
                            src="@/assets/images/layouts/dashboard/Activity_Active.svg"
                            :alt="$t('pages.dashboard.Activity') + ' ' + $t('accessibility.active')"
                        >
                        <img
                            v-show="navigation !== 'activity'"
                            src="@/assets/images/layouts/dashboard/Activity_Inactive.svg"
                            :alt="$t('pages.dashboard.Activity')"
                        >
                    </div>
                    <p
                        :class="{
                            'fw-bold': navigation === 'activity',
                        }"
                    >
                        {{ $t('pages.dashboard.Activity') }}
                    </p>
                </div>
                <div
                    class="nav-item"
                    @click="onClickStatements"
                    @keydown.enter="navigation !== 'statements' ? $router.push('statements') : null"
                    data-testid="statements-button"
                >
                    <div class="icon">
                        <img
                            v-show="navigation === 'statements'"
                            src="@/assets/images/layouts/dashboard/Statements_Active.svg"
                            :alt="$t('pages.dashboard.Statements') + ' ' + $t('accessibility.active')"
                        >
                        <img
                            v-show="navigation !== 'statements'"
                            src="@/assets/images/layouts/dashboard/Statements_Inactive.svg"
                            :alt="$t('pages.dashboard.Statements')"
                        >
                    </div>
                    <p
                        :class="{
                            'fw-bold': navigation === 'statements',
                        }"
                    >
                        {{ $t('pages.dashboard.Statements') }}
                    </p>
                </div>
                <div
                    class="nav-item"
                    @click="onClickCard"
                    @keydown.enter="navigation !== 'card' ? $router.push('card') : null"
                    data-testid="account-button"
                >
                    <div class="icon">
                        <img
                            v-show="navigation === 'card'"
                            src="@/assets/images/layouts/dashboard/Card_Active.svg"
                            :alt="$t('pages.dashboard.Card') + ' ' + $t('accessibility.active')"
                        >
                        <img
                            v-show="navigation !== 'card'"
                            src="@/assets/images/layouts/dashboard/Card_Inactive.svg"
                            :alt="$t('pages.dashboard.Card')"
                        >
                    </div>
                    <p :class="{ 'fw-bold': navigation === 'card' }">
                        {{ $t('pages.dashboard.Card') }}
                    </p>
                </div>
            </nav>
            <!--https://stackoverflow.com/questions/61158306/vue-js-mounted-method-called-twice-->
            <!--tldr: using v-if will cause double mount-->
            <error-view
                v-show="showFullScreenError"
                image-path-in-assets="error-icon.svg"
                :title="$t('global.errors.title')"
                :description="$t('global.errors.generic')"
                :cta="$t('global.cta.retry')"
                @click="retryOnError"
            />
            <loading-indicator v-show="showFullScreenLoadingIndicator && !showFullScreenError" />

            <main v-show="!showFullScreenLoadingIndicator && !showFullScreenError">
                <slot />
            </main>
        </div>
    </div>
</template>

<script>
    import LoadingIndicator from '@/components/LoadingIndicator'
    import AccountStatusBanner from '@/components/AccountStatusBanner'
    import Modal from '@/components/Modal'
    import BaseButton from '@/components/base/BaseButton'
    import ErrorView from '@/components/ErrorView'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import throttle from 'lodash/throttle'
    import { logger } from '@/utils/logger'
    import { mapGetters, mapMutations } from 'vuex'
    import AccountActivationBanner from '@/components/AccountActivationBanner'
    import { RouteNames, RoutePaths } from '@/routes/router'

    const USER_ACTIVITY_THROTTLER_TIME_MSEC = 500
    const AUTOLOGOUT_THRESHOLD_MSEC = 60000 * 5 //idle auto logout threshold in milliseconds

    export default {
        name: 'DashboardLayout',
        props: {
            navigation: {
                type: String,
                validator: function (value) {
                    return ['activity', 'statements', 'card', ''].indexOf(value) !== -1
                },
            },
        },
        components: {
            'loading-indicator': LoadingIndicator,
            AccountActivationBanner,
            AccountStatusBanner,
            Modal,
            BaseButton,
            ErrorView,
        },
        computed: {
            showNav: function () {
                return !this.isWebView || (this.deviceInfo.platform === 'android' && this.isSingleWebView)
            },
            showFullScreenLoadingIndicator: function () {
                return this.loading && !this.isOverViewInitialized
            },
            // only present error view if data failed on initial loading
            // TODO: on refresh, we might want to display a ephemeral notice, so user can drag to refresh again if desired.
            showFullScreenError() {
                return this.error.length > 0 && !this.isOverViewInitialized
            },
            idleWarningThreshold() {
                return AUTOLOGOUT_THRESHOLD_MSEC - 30000 // msec
            },
            isTopLevelPage() {
                return this.navigation === 'activity' || this.navigation === 'statements' || this.navigation === 'card'
            },
            ...mapGetters(['loading', 'isWebView', 'isSingleWebView', 'deviceInfo', 'currentRootPath', 'isOverViewInitialized', 'error']),
        },
        data() {
            return {
                idleWarningTimeout: null,
                logoutTimeout: null,
                showIdleWarningModal: false,
                hasError: false,
                userActions: ['mousemove', 'scroll', 'keydown', 'wheel', 'touchstart'],
            }
        },
        mounted: async function () {
            logger.info(`--- Dashboard.${this.currentRootPath} mounted ---`)

            if (!this.isOverViewInitialized) {
                logger.info(`--- Dashboard.${this.currentRootPath} invoking updateAccountOverview ---`)
                await this.getAccountOverview()
            }
            // when not in webview, aka native apps
            // start monitor userAction events, defined above in data
            // create timer for displaying warning
            // create timer for auto logout
            if (!this.isWebView) {
                this.startMonitorUserAction()
                this.scheduleIdleWarningPopup()
                this.scheduleAutoLogout()
            }
            // when native tab bar button is clicked, this function will be invoked
            window.switchTab = this.switchTab
        },
        destroyed: function () {
            logger.info(`--- Dashboard.${this.currentRootPath} destroyed ---`)
            this.stopMonitoringUserAction()
            clearTimeout(this.logoutTimeout)
            clearTimeout(this.idleWarningTimeout)
        },
        methods: {
            ...mapMutations(['updateLoading', 'clearError', 'setCurrentRootPath']),
            retryOnError: async function () {
                await this.getAccountOverview()
            },
            getAccountOverview: async function () {
                this.clearError()
                try {
                    await this.$store.dispatch('updateAccountOverview')
                } catch (error) {
                    ApiErrorHandler(error)
                }
            },
            // listen to window events that indicates user is using the app
            // event handling is throttled with interval of 250 msec
            // every user interaction event, reset both idle warning modal timeout and auto logout timeout
            // when idle warning expires, we stop monitoring user interaction on the window.
            // auto logout will occur if user doesn't interact with the warning modal
            // auto logout timeout      |-------------AUTOLOGOUT_THRESHOLD_MSEC----------------|
            // idle warning timeout     |-----(AUTOLOGOUT_THRESHOLD_MSEC - 30000 msec)----|
            startMonitorUserAction: function () {
                // dev debugging only -- console.log('startMonitorUserAction')
                this.userActions.forEach((action) => window.addEventListener(action, this.throttledUserActivityHandler))
            },
            // stops listening for window events
            stopMonitoringUserAction: function () {
                this.userActions.forEach((action) => window.removeEventListener(action, this.throttledUserActivityHandler))
                // dev debugging only -- console.log('stopMonitorUserAction')
            },
            // cancel idle warning timeout
            // schedule new idle warning timeout which presents after (AUTOLOGOUT_THRESHOLD_MSEC - 30000 msec)
            scheduleIdleWarningPopup: function () {
                // dev debugging only -- console.log(`cancel - IdleWarningPopup`)
                clearTimeout(this.idleWarningTimeout)
                this.idleWarningTimeout = setTimeout(() => {
                    this.presentIdleWarningModal()
                }, this.idleWarningThreshold)
                // dev debugging only -- console.log(`schedule - IdleWarningPopup`)
            },
            // cancel auto logout timeout
            // schedule new uto logout timeout that occurs after AUTOLOGOUT_THRESHOLD_MSEC
            scheduleAutoLogout: function () {
                // dev debugging only -- console.log(`cancel - AutoLogout`)
                clearTimeout(this.logoutTimeout)
                this.logoutTimeout = setTimeout(() => {
                    this.logout('event_auto_logout')
                }, AUTOLOGOUT_THRESHOLD_MSEC)
                // dev debugging only -- console.log('schedule - AutoLogout')
            },
            // show idle warning modal
            // stop monitoring user interactions
            presentIdleWarningModal() {
                logger.info(`presentIdleWarningModal`)
                this.showIdleWarningModal = true
                this.stopMonitoringUserAction()
            },
            // handles "X" button or "Stay" button on the idle warning modal
            // closing idle warning, means user intends to continue their session
            // start monitoring user interaction
            // reschedules idle warning timeout and auto logout timeout
            closeIdleWarningModal: function () {
                logger.info(`closeIdleWarningModal`)
                clearTimeout(this.logoutTimeout)
                clearTimeout(this.idleWarningTimeout)
                this.startMonitorUserAction()
                this.scheduleIdleWarningPopup()
                this.scheduleAutoLogout()
                this.showIdleWarningModal = false
            },
            // handles "Logout" button on idle warning modal
            // also handles auto logout
            // clear all scheduled timeouts
            // stope monitoring user interactions
            logout: function (eventName) {
                window.logEvent(eventName)
                clearTimeout(this.logoutTimeout)
                clearTimeout(this.idleWarningTimeout)
                this.stopMonitoringUserAction()
                logger.info(`mSite(${this.currentRootPath}), idle logout occurred`)
                this.$router.push(RoutePaths.LOGOUT)
            },
            // called by the system whenever a userInteraction event occurs
            // reschedule both idle warning timeout and auto logout timeout
            throttledUserActivityHandler: throttle(function () {
                this.scheduleIdleWarningPopup()
                this.scheduleAutoLogout()
            }, USER_ACTIVITY_THROTTLER_TIME_MSEC),
            onClickActivity: function () {
                if (this.navigation !== this.currentRootPath) {
                    this.$router.push(RoutePaths.ACTIVITY)
                    this.setCurrentRootPath(RoutePaths.ACTIVITY)
                }
            },
            onClickStatements: function () {
                if (this.navigation !== this.currentRootPath) {
                    this.$router.push(RoutePaths.STATEMENTS)
                    this.setCurrentRootPath(RoutePaths.STATEMENTS)
                }
            },
            onClickCard: function () {
                if (this.navigation !== this.currentRootPath) {
                    this.$router.push(RoutePaths.CARD)
                    this.setCurrentRootPath(RoutePaths.CARD)
                }
            },
            // ==== invoked by native code ====
            switchTab: function (tabName) {
                logger.info(`switchTab: ${tabName}`)
                switch (tabName) {
                    case RouteNames.ACTIVITY:
                        this.onClickActivity()
                        break
                    case RouteNames.STATEMENTS:
                        this.onClickStatements()
                        break
                    case RouteNames.CARD:
                        this.onClickCard()
                        break
                    default:
                        logger.error(`webview Dashboard.switchTab received unknown value value ${tabName}`)
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/layouts/dashboard';
    @import '../styles/components/appHeader.scss';
    @import '../styles/components/appBase';
</style>
