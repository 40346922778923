import { AxiosResponse } from 'axios'
import { APIResponse } from '@/models/APIResponse'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { DeviceInfo } from '@/utils/deviceUtils'
import { logger } from '@/utils/logger'
import { initializeLogRocket } from '@/utils/crashlyticsUtils'
import { blockStatus, sessionId } from '@/services/avenAppApi'
import store from '@/store'

class Session {
    private static instance: Session

    static getInstance(): Session {
        if (this.instance) {
            return this.instance
        }

        this.instance = new Session()
        return this.instance
    }

    get sessionId(): string {
        return appSessionStorage.getItem(localStorageKey.sessionId)
    }

    // TODO: this will become request sessionId that doesn't require any customer identifying information
    // once customer logins successfully, then session is linked to customer.
    // then the session can associated with the correct device since browser fingerprint is not guaranteed to be unique
    public checkBlockStatus = async (deviceInfo: DeviceInfo) => {
        const response = await blockStatus({ platform: deviceInfo.platform, deviceGuid: deviceInfo.deviceGuid, appVersion: deviceInfo.appVersion })
        return response.data
    }

    // used for inflating session data injected from mobile apps (iOS and Android)
    public inflateSessionFromNative = async (deviceInfo: DeviceInfo, sessionId: string, sessionAccessJWT: string, creditCardCustomerId?: number) => {
        appSessionStorage.setItem(localStorageKey.sessionId, sessionId)
        appSessionStorage.setItem(localStorageKey.sessionAccessJWT, sessionAccessJWT)
        const userTraits = {
            ...deviceInfo,
            creditCardCustomerId,
        }

        initializeLogRocket(sessionId, userTraits)
    }

    public sessionBegin = async (): Promise<any> => {
        if (this.sessionId) {
            logger.info(`Session id exists ${this.sessionId}, no new session retreieved`)
            return null
        }
        const log: string[] = []
        log.push(`Preparing to get Session`)
        const deviceInfo = store.getters.deviceInfo
        log.push(`Get session for device: ${JSON.stringify(deviceInfo)}`)

        try {
            const response: AxiosResponse<APIResponse> = await sessionId(deviceInfo)
            log.push(`Get session response.data: ${JSON.stringify(response.data)}`)
            if (response.data.success && response.data.payload.sessionId) {
                appSessionStorage.setItem(localStorageKey.sessionId, response.data.payload.sessionId)
                appSessionStorage.setItem(localStorageKey.sessionAccessJWT, response.data.payload.sessionAccessJWT)
                logger.info(`Created new session with sessionId: ${appSessionStorage.getItem(localStorageKey.sessionId)}`)
                const userTraits = {
                    ...deviceInfo,
                }
                initializeLogRocket(response.data.payload.sessionId, userTraits)
            }
            logger.info(`Session bootstrap log: ${log.map((l, i) => `${i}: ${l}`).join('\n')}`)
            return response.data
        } catch (error) {
            console.error(JSON.stringify(error))
            throw error
        }
    }
}

export const session = Session.getInstance()
