<template>
    <div class="loading-indicator">
        <svg
            class="spinner"
            viewBox="0 0 48 48"
        >
            <circle
                class="path"
                cx="24"
                cy="24"
                r="19"
                fill="none"
                stroke-width="2"
            />
        </svg>
        <h5
            class="fw-light title mt-3"
            v-html="title"
        />
    </div>
</template>

<script>
    export default {
        name: 'LoadingIndicator',
        props: {
            title: String,
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/loadingIndicator.scss';
</style>
