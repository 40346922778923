// Set logging prototype as soon as possible
import Vue from 'vue'
Vue.prototype.$logEvent = window.logEvent

import { i18n } from './utils/i18n'
import './utils/validation'
import './utils/exception-handler'
import './utils/filters'
import VueCookies from 'vue-cookies'
import App from './App.vue'
import router from './routes/router'
import './utils/validation'
import './utils/exception-handler'
import store from './store'

Vue.component('./component/BaseButton')

Vue.config.productionTip = false

Vue.use(VueCookies)

declare global {
    interface Window {
        handleDeepLinkFromNative: any
        updateDeviceCapabilities: any
        logEvent: any
        previousPath: string
        Plaid: any
        zE: any
    }
}

new Vue({
    router,
    i18n,
    store,
    render: (h) => h(App),
}).$mount('#app')
