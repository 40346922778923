import Vue from 'vue'
import VueI18n from 'vue-i18n'
import { ENGLISH_TRANSLATIONS } from '@/localization/en'
import { SPANISH_TRANSLATIONS } from '@/localization/es'

Vue.use(VueI18n)

const TRANSLATIONS = {
    en: ENGLISH_TRANSLATIONS,
    es: SPANISH_TRANSLATIONS,
}

const navigator: any = window.navigator

let locale = 'en'
if (navigator.userLanguage) {
    locale = navigator.userLanguage.includes('es') ? 'es' : 'en'
} else if (navigator.language) {
    locale = navigator.language.includes('es') ? 'es' : 'en'
} else {
    console.info('Unable to determine user langauge from javascript, defaulting to "en"')
}

const i18n = new VueI18n({
    locale,
    messages: TRANSLATIONS,
})

export { i18n }
