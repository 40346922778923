// @ts-ignore
import { contact_info } from '@/utils/contact-info-dictionary'
import {
    MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS,
    MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT,
    MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS,
    MIN_DAYS_BETWEEN_PAYMENT_AND_CASH_OUT,
    PAYMENT_AMOUNT_BEFORE_BLOCKING_CASH_OUT,
} from '@/data/constants'
import generic from '@/utils/generic'

export const ENGLISH_TRANSLATIONS = {
    pages: {
        taxDocuments: {
            title: 'Tax Documents',
            webViewError: 'Your 1098 form is available on our website. Please log in and go to “Account” to view and download your tax documents.',
            webViewErrorCta: 'Go To Website',
            noTaxDocuments: "You don't have any tax documents currently available.",
        },
        statements: {
            title: 'Statements',
            remainingStatementBalance: 'Remaining Statement Balance',
            minimumDue: 'Minimum Due',
            due: 'Due {date}',
            pastDue: 'Past Due',
            paymentDueBy: 'Due by',
            scheduledOn: 'Scheduled For',
            nextStatement: 'Next Statement {date}',
            autoPay: 'AutoPay',
            paymentOptionsTitle: 'Make a Payment',
            makeAPayment: 'Make A Payment',
            minimumPaid: 'Minimum Paid',
            nothingDueNow: 'You do not have anything due. Your next statement will be available on {date}',
            nothingCurrentlyDue: 'Nothing Currently Due',
            FullBalance: 'Full Balance',
            StatementBalance: 'Statement Balance',
            Minimum: 'Minimum',
            CustomAmount: 'Enter an Amount',
            Cancel: 'Cancel',
            paidOn: 'Paid on',
            FundingSourceTitle: 'No Funding Source',
            FundingSource: 'In order to make a payment, please add a funding source, like a bank account or debit card.',
            FundingSourceButton: 'Add Funding Source',
            viewStatementError: {
                title: 'Error',
                description: `Couldn't load statement. Please contact support at ${contact_info.dashFormatted}`,
                unavailable: 'Your {name} statement PDF is still being prepared. Please try again in a few hours.',
            },
        },
        activity: {
            title: 'Activity',
            Available: 'Available',
            CurrentBalance: 'Current Balance',
            NoTransactionYet: 'No Activity Yet',
            AnticipationHtml: 'In the meantime, you can schedule a <br/>balance transfer from the account tab.',
            makePayment: 'swipe your card to start',
            Today: 'Today',
            Pending: 'Pending',
            Upcoming: 'Upcoming',
            autoPay: 'AutoPay',
        },
        AutoPay: {
            SetupAutoPay: 'Setup AutoPay',
            UpdateAutoPay: 'AutoPay',
        },
        SetupAutoPay: {
            description: 'Set up AutoPay for peace of mind and to keep your 0.25%&nbsp;discount',
            setupAutoPay: 'Set Up AutoPay',
            notNow: 'Not Now',
            Promo: {
                SetupCredit: 'Setup AutoPay to receive $25 in statement credit. Available for the next 7 days.',
                SetupCreditDetails: 'Aven will apply a one-time statement credit of $25 if AutoPay is turned on at the time any of your next two statements are generated.',
            },
        },
        MakePayment: {
            MakePayment: 'Make A Payment',
            PayNow: 'Pay {amount}',
            TurnOnAutoPay: 'Turn On AutoPay',
            TurnOffAutoPay: 'Turn Off AutoPay',
            UpdateAutoPay: 'Update AutoPay',
            Pay: 'Pay',
            Amount: 'Amount',
            ConfirmAmount: 'Type "{amount}" to confirm amount',
            ConfirmAmountValidationError: 'Please enter {amount} to confirm the amount. If it is not correct, please go back to update.',
            GetPaymentSourcesError: 'There was an issue trying to retrieve your payment sources. Please refresh and try again.',
            AddPaymentSourceError: 'There was an issue trying to add the payment. Please try again.',
            PaymentAuthorization: 'Payment Authorization',
            legalText1:
                'If you make your payment before 7:59 PM (EST) we will initiate the payment on the same day, but it might take a few days to have the funds withdrawn from your account and your available balance to be updated.',
            legalText2:
                'By clicking “Pay Now’ you authorize a one time payment of ${amount} on {date} from {source}. You can print or save the confirmation screen for a receipt of the scheduled payment.',
            PaymentSuccessTitle: 'Payment Successful',
            PaymentSuccessContent: 'Thank you for your payment of ${amount} from {source}. It might take up to 5 business days to see your available balance change.',
            PaymentSuccessButton: 'Done',
            ProcessingPayment: 'Processing Payment',
            ProcessingAutoPay: 'Setting up AutoPay',
            oneTime: 'One Time',
            autoPay: 'AutoPay',
            MinimumDueLabel: 'Minimum Due',
            StatementBalanceLabel: 'Statement Balance',
            CurrentBalanceLabel: 'Current Balance',
            FixedAmountLabel: 'Fixed Amount',
            OtherAmountLabel: 'Other Amount',
            PayFrom: 'Pay From...',
            AddNewAccount: 'Add New Account',
            SecondaryAutoPayEdit: 'To edit AutoPay, please have the primary cardholder login to make the change. If you need to make a payment, you can make a one-time payment using the tab above.',
            SecondaryAutoPayAdd: 'To turn on AutoPay, please have the primary cardholder login to make the change. If you need to make a payment, you can make a one-time payment using the tab above.',
            AddBankError: 'There was an error adding your bank account. Please check your routing and account number and try again.',
            GenericError: 'Something went wrong. Please try again.',
            terms1: 'If you make your payment before 7:59 PM (EST) we will reflect the payment on your account today, but you might not see your available balance change for up to 3 days.',
            terms2: 'By clicking “Confirm Payment“ you authorize a one time payment of {amount} on {date} from {source}. You can print or save the confirmation screen for a receipt of the scheduled payment.',
            madePaymentConfirmation: {
                title: 'Payment Successful',
                subTitle: 'Thank you for your payment of {paidAmount} from {source}. It might take up to 5 business days to see your available balance change.',
                doneCta: 'Done',
            },
            autoPayEnabledConfirmation: {
                title: 'AutoPay Turned On',
                subTitleMinimumDue: 'Thank you for turning on AutoPay. The Minimum Due will automatically be deducted from {source} on the due date.',
                subTitleStatementBalance: 'Thank you for turning on AutoPay. The Statement Balance will automatically be deducted from {source} on the due date.',
                subTitleFixedAmount: 'Thank you for turning on AutoPay. {amount} will automatically be deducted from {source} on the due date.',
                doneCta: 'Done',
            },
            autoPayDisabledConfirmation: {
                title: 'Turn Off AutoPay',
                subTitle1: 'Are you sure you want to turn off AutoPay? All future payments will need to be made manually.',
                subTitle2: 'You may also lose the 0.25% AutoPay discount that you have on your current rate.',
                keepOnBtnCta: 'Keep AutoPay On',
            },
        },
        PrivacyPolicy: {
            PrivacyPolicy: 'Privacy Policy',
        },
        TermsCondition: {
            TermsCondition: 'Terms & Condition',
        },
        CreditAgreement: {
            CreditAgreement: 'Credit Agreement',
        },
        card: {
            YourCard: 'Your Card',
            MakeAPayment: 'Make A Payment',
            CashOut: 'Cash Out',
            AutoPay: 'AutoPay',
            BalanceTransfer: 'Card Balance Transfers',
            Rewards: 'Rewards',
            AccountDetails: 'Account Details',
            DepositCrypto: 'Deposit Crypto',
            ReportLostOrStolen: 'Report Lost Or Stolen',
            Pif: 'Gift $400 To Friends',
            LogOut: 'Log Out',
        },
        yourCard: {
            ReportLostOrStolen: 'Report Lost or Stolen',
            CardActivation: 'Activate Card',
            YourCard: 'Your Card',
            CreditLimit: 'Credit Limit',
            APR: 'Current APR',
            AccountAgreement: 'Account Agreement',
            PolicyAndTerms: 'Policies and Agreements',
            PrivacyPolicy: 'Privacy Policy',
            TermsConditions: 'Terms & Conditions',
            Disclosures: 'Disclosures',
            CreditAgreement: 'Credit Agreement',
            AvailableCredit: 'Available Credit',
            Unlocked: 'Unlocked',
            cardTerms: 'Card Terms',
            refresh: 'Refresh',
            modal: {
                freezeCard: {
                    title: 'Card Locked',
                    description: `
                    <p>The following changes are in effect while your card is locked</p> 
                    <dl>
                      <dt>Will Stop</dt>
                      <dd>- New Purchases</dd>
                      <dd>- New Cash Advances</dd>
                      <dd>- New Balance Transfers</dd>
                      <dd>- Secondary Cards</dd>
                      <dt>Will Continue</dt>
                      <dd>- Pending Transactions</dd>
                      <dd>- Subscriptions</dd>
                      <dd>- Recurring Payments</dd>
                      <dd>- Refunds and Credits</dd>
                    </dl>
                    `,
                },
                unrecoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please contact Customer Service.</p>`,
                },
                recoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please try again.</p>`,
                },
                cta: 'Got it',
            },
        },
        login: {
            phonePlaceholder: 'Phone Number',
            codeHeadline: 'We texted a code to<br/>{contactInfo}',
            codePlaceholder: 'Confirmation Code',
            continue: 'Continue',
            notfound: 'Sorry, we could not find an account for {phoneNumber}',
            verificationNotCorrect: 'Verification code is incorrect',
            signInTitle: 'Welcome! Enter your mobile number to get started.',
            signInOrActivate: 'Sign In or Activate',
            biometrics: 'Unlock with Biometrics',
            Help: 'Help',
            LoginHelp: 'Login Help',
            helpDescription: 'If you did not get a text message, we can send it again.',
            resendCode: 'Resend Code',
            sendToEmail: 'Send to Email',
            ChangeNumber: 'Change Number',
            notValidPhoneNumber: 'Not a valid phone number',
            appConsent: "By clicking Sign In, you consent to the terms of our <a href='https://aven.com/docs/DigitalServicesAgreement.pdf' target='_blank'> Digital Services Agreement</a>.",
            loginHelpModal: {
                title: 'Need Login Help?',
            },
        },
        setPassword: {
            phonePlaceholder: 'Enter 6 digits',
            createPassword: 'Great, lets set your password',
            firstEntryPlaceholder: 'New Password',
            secondEntryPlaceholder: 'Confirm New Password',
            continue: 'Continue',
            confirm: 'Confirm',
            error: {
                personalInfoMismatchError: 'The personal information you have entered does not match our records. Please try again.',
                expiredTokenError: {
                    title: 'Session Expired',
                    description: 'Code reset session expired. Please try again',
                    cta: 'Go to Login',
                },
                maxIdentityVerificationAttemptsReachedError: {
                    title: 'Retry Limit Reached',
                    description: `Sorry, we could not verify your information. Please contact us at ${contact_info.dashFormatted} with any questions.`,
                    cta: 'Go to Login',
                },
            },
        },
        passwordLogin: {
            loginWithPassword: 'Enter your Password',
            biometrics: 'Unlock with Biometrics',
            help: 'Help',
            forgotPassword: 'Forgot Password',
            resetPasswordDescription: 'Reset your password by verifying Identity',
            error: {
                accountNotFound: 'Account Not Found. Please contact Customer Service',
                incorrectPassword: 'Incorrect Password',
            },
            placeHolder: 'Password',
        },
        unfreezeCard: {
            title: 'Unlock Card',
            message: 'Confirm your Password',
            freezeCardTitle: 'Freeze Card',
            cta: 'Continue',
            modal: {
                unfreezeCard: {
                    title: 'Card Unlocked',
                    description: `<p>Your card will now be fully functional and can be used for purchases, cash advances and balance transfers.</p>`,
                },
                unrecoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please contact Customer Service.</p>`,
                },
                recoverable: {
                    title: 'Error',
                    description: `<p>Unable to complete request. Please try again.</p>`,
                },
                cta: 'Got it',
            },
            error: {
                incorrectPassword: 'Incorrect Password',
            },
        },
        SSNVerification: {
            Last4ofSSN: 'Last 4 of SSN',
            DateOfBirth: 'Date of Birth',
            setupPassword: 'To set up a password, please confirm your identity.',
            resetPassword: 'To reset your password, please confirm your identity',
            dob: 'MM/DD/YYYY',
            cta: 'Continue',
        },
        CardActivation: {
            headerTitle: 'Activate Card',
            descTitle: 'Enter the last 4 digits of your<br/>new Aven card',
            Last4ofCardNumber: 'Last 4 of Card Number',
            continue: 'Continue',
            ERROR_CARD_ACTIVATION_DATA_INVALID: 'We were unable to find your card ending in {last4Digits}. Please double check the last 4 digits.',
            ERROR_IN_RESCISSION_PERIOD: 'You are currently within your 3-day cancellation period. Please activate after {date}.',
            ERROR_PRIMARY_CARD_NOT_ACTIVATED: 'Please have the primary cardholder activate their card first.',
            ERROR_BLOCKED_BY_PENDING_CONTINGENCIES: 'Thank you for completing your notarization session. We are finishing the paperwork for your account and will get back to you within 72 hours.',
            activationSuccessful: {
                imgAlt: 'Activation Successful',
                descTitle: 'Congrats! Your card is now active and ready to use',
                letsGo: "Let's Go",
            },
            unrecoverableActivationError: {
                description: 'Oh no, we were unable to activate your card. Please contact support.',
                descriptionNonMobile: `Oh no, we were unable to activate your card. Please contact support at ${contact_info.dashFormatted}`,
                buttonText: 'Call Support',
            },
        },
        CheckCardArrival: {
            description: 'Have you received your new Aven Card in the mail yet?',
            cta: 'Yes, I Have It',
            no_cta: 'No, Not Yet',
        },
        AddBankAccount: {
            AddBanksAccount: 'Add Bank Account',
            AccountHolderName: 'Account Name',
            AccountHolderNumber: 'Account Number',
            ConfirmAccountHolderNumber: 'Confirm Account Number',
            ValidationErrorMessage: 'Account numbers must match',
            Nickname: 'Account Nickname (ex. Chase Checking)',
            PhoneNumber: 'Phone Number',
            AccountType: 'Account Type',
            RoutingNumber: 'Routing Number',
            State: 'State',
            Zipcode: 'Zipcode',
            AddBankAccount: 'Add Bank Account',
            PersonalChecking: 'Personal Checking',
            PersonalSavings: 'Personal Savings',
            BusinessChecking: 'Business checking',
            BusinessSavings: 'Business Savings',
            YourDetails: 'Your Details',
            AccountDetails: 'Account Details',
        },
        appHeader: {
            HELP: 'HELP',
        },
        dashboard: {
            Activity: 'Activity',
            Statements: 'Statements',
            Card: 'Account',
        },
        ReportLostOrStolen: {
            ReportLostOrStolen: 'Report Lost or Stolen',
            Step1Title: '1) Temporarily De-Activate Card',
            Step1Subtitle: 'If you think you card is lost or stolen, we recommend that you temporarily pause your card first to prevent any unwanted charges.',
            CardActive: 'Card Active',
            Step2Title: '2) Replace Card',
            Step2Subtitle: 'If you can’t find your card or you see unwanted charges, you can cancel your current card and we will send a new one to:',
            cancelAndReplace: 'Replace Card',
            CardLocked: 'Card Locked',
            ModalTitle: 'The following changes are in effect while your card is locked.',
            CardStop: 'Will Stop:',
            CardStopItem1: '- New Purchases',
            CardStopItem2: '- New Cash Advances ',
            CardStopItem3: '- New Balance Transfers',
            CardStopItem4: '- Secondary Cards',
            CardContinue: 'Will Continue:',
            CardContinueItem1: '- Pending Transactions',
            CardContinueItem2: '- Subscriptions',
            CardContinueItem3: '- Recurring Payments',
            CardContinueItem4: '- Refunds and Credits',
            GotIt: 'Got It',
            CardStatusError: 'There was an error getting your card status. Please refresh and try again.',
            FreezeCardError: 'There was an error trying to freeze your card. Please refresh and try again.',
            CallUs: 'Call Us',
        },
        ReplaceCard: {
            limitReached: {
                title: 'Replace Card',
                description: 'Please contact support for a replacement Aven Card.',
                cta: 'Text Support',
            },
            lost: {
                title: 'Lost / Stolen',
                description: `Your current card will freeze and no longer work.<br><br>We will FedEx you a replacement card. It typically takes around 2 – 3 business days to receive your new Aven card.`,
            },
            damaged: {
                title: 'Damaged',
                description: `<b>You may continue using your card if:</b><br>- Date and CVC are legible<br>– Chip works<br>– Magnetic stripe is functional<br>– It’s not temporarily de-activated<br><br>We will FedEx you a replacement card. It typically takes around 2 – 3 business days to receive your new Aven card.`,
            },
            successModal: {
                title: 'Hoorah!',
                description: 'We received your request and your card is on the way.',
            },
            errorModal: {
                title: 'Request Error',
                description: 'Sorry, we could not complete your request. Please try again. If you continue to have trouble, please contact support.',
                textSupport: 'Text Support',
            },
            cta: 'Confirm Replacement',
            noThanks: 'No Thanks',
        },
        accountDetails: {
            AccountDetails: 'Account Details',
            PersonalDetails: 'Personal Details',
            PaymentSources: 'Payment Sources',
            NotificationSettings: 'Notification Settings',
            AvenPrimeSettings: 'Aven Prime',
            CardTerms: 'Card Terms',
            CreditLimit: 'Credit Limit',
            AvailableCredit: 'Available Credit',
            CurrentAPR: 'Current APR',
            BalanceTransferFee: 'Balance Transfer Fee',
            CashOutFee: 'Cash Out Fee',
            UnlimitedCashback: 'Unlimited Cashback',
            MonthlyFee: 'Monthly fee',
            PolicyAndAgreement: 'Policy And Agreement',
            AccountAgreement: 'Account Agreement',
            PrivacyPolicy: 'Privacy Policy',
            TermsAndConditions: 'Terms & Conditions',
            Disclosures: 'Disclosures',
            rescissionPeriodEnd: 'Rescission Period Until',
        },
        personalInformation: {
            PersonalInformation: 'Personal Details',
            Name: 'Name',
            Contact: 'Contact Information',
            MobileNumber: 'Mobile Number',
            Email: 'Email',
            Address: 'Address',
            UpdateAddressInfo: 'For security reasons, please call us at ',
            UpdateAddressInfo2: 'to change any of your personal information.',
        },
        PaymentSources: {
            PaymentSources: 'Payment Sources',
            PaymentSource: 'Payment Source',
            AddNewSource: 'Add Payment Source',
            ChaseChecking: 'Chase Checking',
            Amount: 'Amount',
            PayFrom: 'Pay From',
            PaymentSourceWarning: 'Please set the default payment source to AutoPay',
            Ok: 'OK',
            Type: 'Type',
            Account: 'Account',
            AddAccount: 'Add Account',
            RemoveSource: 'Remove Source',
            ConfirmRemoveText: 'Are you sure you want to remove this payment source? This could affect future payments or autopay.',
            ConfirmRemoveCta: 'Yes, Remove Source',
            AddDebitCard: 'Add Debit Card',
            AutoPayFormValidation: `Something went wrong. We were unable to turn off AutoPay. Please contact support at ${contact_info.dashFormatted}.`,
            secondaryCardHolderAutoPayOnMessage: 'To edit AutoPay, please have the primary cardholder login',
            secondaryCardHolderAutoPayOffMessage: 'To turn on AutoPay, please have the primary cardholder login to their account and turn on AutoPay',
            GetPaymentSourcesError: 'There was an issue trying to retrieve your payment sources. Please refresh and try again.',
            AddPaymentSourceError: 'There was an issue trying to add the payment. Please try again.',
        },
        PaymentSource: {
            PaymentSource: 'Payment Source',
            BillingAccountNotFound: 'No Payment Sources Yet',
            AddBank: 'Please add a payment source to make payments from.',
            ChaseChecking: 'Chase Checking',
            Cardending: 'Card ending ',
            AddPaymentMethod: 'Add Payment Source',
            AddBankAccount: 'Add Bank Account',
            AddDebitCard: 'Add Debit Card',
            DeleteFundingSource: 'Delete Funding Source?',
            SelectPaymentSource: 'Select Payment Source',
            Delete: 'Delete',
        },
        AutoPayAmount: {
            legalText1: 'We will initiate the payment on your due date (so you won’t be late for your payment), but it might take a few days to have the funds withdrawn from your account.',
            legalText2Html:
                "By clicking Turn On AutoPay you agree to the terms and conditions of the <a href='https://aven.com/docs/AutoPayAgreement.pdf' target='_blank'>Aven Automatic Payment Service Agreement</a>, which governs the use of automatic payments.",
        },
        NotificationSettings: {
            NotificationSettings: 'Notification Settings',
            SendWhen: 'Send When',
            NewTransaction: 'New Transaction',
            NewStatement: 'New Statement',
            PaymentMade: 'Payment Made',
            PushNotification: 'Notifications',
        },
        AvenPrimeSettings: {
            title: 'Aven Prime',
            toggle: 'Enable',
        },
        balanceTransfer: {
            title: 'Card Balance Transfer',
            startBalanceTransfer: 'Start Free Balance Transfer',
            balanceTransferSuccessTitle: 'Balance Transfer Success',
            balanceTransferFailureTitle: 'Balance Transfer Failed',
            balanceTransferSuccessContent: 'Your balance transfer has been successfully scheduled. Confirmation Number: {confirmationNumber}.',
            balanceTransferFailureContent: 'Your balance transfer request has failed. Please contact customer service for assistance.',
            balanceTransferSuccessButton: 'Done',
            balanceTransferFailureButton: 'Retry',
        },
        autoBalanceTransfer: {
            errorMessage: 'Sorry, something went wrong. Our team has been notified and will resolve the issue as soon as possible.',
            chooseLinkedCards: {
                title: 'Recurring Auto Transfers',
                subTitle: 'SELECT CARDS',
                cta: 'Confirm',
            },
            selectAmount: {
                title: 'Transfer Amount',
                subtitle: 'Enter the amount you want to auto transfer from <b>{accountName}</b>',
                placeholder: 'Transfer Amount',
                cta: 'Set Transfer Amount',
                noTransferFee: {
                    title: 'Congrats! No Transfer Fees',
                    message: 'You will not be charged a transfer fee because you have Auto Transfer turned on for this card.',
                },
            },
            balanceTransferRequested: {
                title: 'Auto Transfer Requested',
                message: '{amount} has been requested to transfer from your card {accountName}. This usually takes around 7 business days to reflect on your other card.',
                cta: 'Done',
            },
            information: {
                header: 'Recurring Auto Transfers',
                title: 'Save money with recurring auto balance transfers – $0 transfer fee.',
                message: "Once a month we'll ask how much you would like to balance transfer from your other credit cards & we'll do it hassle free - for $0.",
                ctaPrimary: 'Turn On',
                ctaSecondary: 'Not Now',
            },
        },
        cashOut: {
            title: 'Cash Out',
            ineligibleSecondaryCard: 'Unfortunately, Cash Out can only be initiated by the primary cardholder. Please have the primary cardholder perform a Cash Out.',
            emptyTitle: 'Cash Out To Your Bank',
            emptySubTitle: 'Get the same low rate as regular purchases. Just a simple 2% transfer fee. It usually take around 2 business days to reflect in your bank account.',
            cta: 'New Cash Out',
            linkBankCta: 'Select Your Bank Account',
            cashOutDailyLimitInfo: `For security reasons, there is a ${generic.prettyPrintDollarAmount(
                String(MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS)
            )} daily maximum for combined Cash Outs and Balance Transfers.`,
            cashOutLimitInfo: 'The maximum amount is based on your available credit minus the 2% Cash Out fee.',
            maxCashOutAfterLargePayment: `For your security, Cash Outs are restricted to ${generic.prettyPrintDollarAmount(
                String(MAX_AMOUNT_ALLOWED_FOR_CASH_OUT_AFTER_PAYMENT)
            )} for ${MIN_DAYS_BETWEEN_PAYMENT_AND_CASH_OUT} days after making a payment of greater than ${generic.prettyPrintDollarAmount(String(PAYMENT_AMOUNT_BEFORE_BLOCKING_CASH_OUT))}.`,
            plaidGenericError: 'We require additional information to complete the transaction',
            uploadBankStatements: 'Upload Bank Statement Instead',
            uploadBankStatementFormSubTitle: 'To confirm ownership of that account, please add a voided check or at least the first page of your latest bank statement.',
            uploadBankStatementOrVoidedCheckButton: 'Statement or Voided Check',
            bankAccountInformationFormSubtitle: 'To which bank account do you want to Cash Out?',
            submitBankStatementsCta: 'Continue',
            uploadBankStatementSourceError: 'There was an issue trying to upload bank statements. Please try again.',
            cashOutFormSubText: 'For security reasons, you will be asked to login to your online bank account. The maximum amount is based on your available credit minus the 2% fee.',
            verifyPassword: 'Confirm your Password',
            ssnVerify: 'Confirm the last 4 digits of your social security',
            ssnPlaceholder: 'Last 4 Social Security Number',
            ssnInvalid: 'Please check the last 4 digits of your social security number',
            addBankAccountPlaid: 'Link Account – Secure & Instant',
            addBankAccountUploadStatements: 'Manually Upload Information',
            selectCashOutAccountHeader: 'Send Cash to...',
            addNewBankAccountOptionLabel: 'Add New Bank Account...',
            submitCashOutForm: 'Continue',
            inputPlaceholder: '$0.00',
            inputLabel: 'Amount ({availableCredit} Maximum)',
            cashOutText: 'Amount + 2% Fee',
            cashOutConfirmAmount: 'Transfer To Bank',
            cashOutConfirmationFee: '{fee} Fee',
            cashOutConfirmationTotal: 'Added To Balance',
            cashOutViaPlaidSuccessTitle: 'Cash Out Requested',
            cashOutViaBankStmtUploadTitle: 'Reviewing Cash Out',
            cashOutViaPlaidSuccessSubTitle:
                '{cashOutAmount} has been requested to be sent to your bank account ending in {accountEnding}. They usually take around 2 business days to reflect in your bank account.',
            bankStatementReviewConfirmationSubTitle:
                'A Cash Out of {cashOutAmount} has been submitted. We are reviewing your statement upload. Once reviewed, typically within 1-2 business days, we will initiate the Cash Out.  After that, it usually takes around 2 business days to reflect in your bank account.',
            doneCta: 'Done',
            confirmCashOutCta: 'Confirm Cash Out',
            genericError: 'Sorry, something went wrong. Our team was notified and are quickly trying to resolve the issue.',
            unsupportedFileFormatError: 'Sorry, that format is not supported. Please upload an image or PDF.',
            cashOutViaPlaidConfirmationSubTitle: 'We will transfer <b>{cashOutAmount}</b> to your <b>bank account ending in {accountEnding}</b>.',
            accountTypeConfirmation: 'Please confirm if this is a personal or business bank account.',
            cashOutViaBankStmtUploadConfirmationSubTitle: 'A Cash Out of <b>{cashOutAmount}</b> to your <b>bank account ending in {accountEnding}</b> will be reviewed and initialized once approved.',
            pendingCashOutListSubtext:
                'We have sent the request to your bank. It typically takes 2 business days to reflect in your bank account. If you do not see it after that, please contact your bank.',
            cardNotActivatedMessage: 'For your security, please activate your card before you {featureName}. If your card is still in the mail, please wait until you get the card. ',
            whyCashOutTitle: 'Why Cash Out?',
            whyCashOutText:
                'With Aven’s Cash Out feature, you can transfer cash from your Aven account. This is great when you can’t use your Aven credit card, need to make a cash payment, or need the funds in your bank account.',
            areThereFeesTitle: 'Are there any fees?',
            areThereFeesText: 'There is a simple 2% fee on the amount being sent to your bank.',
            howLongTitle: 'How long does it take?',
            howLongText: 'It usually takes 2 business days for the funds to be available in your bank account.',
            howToPayBackTitle: 'How do I pay it back?',
            howToPayBackText: `
                <p class="mb-3">You have two options for monthy payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Set up fixed monthly payments – no interest, just a fixed monthly fee. You can lock in your payments for 5 or 10 years (15 or 20 years if over $25K).</p>
           `,
            limitForCashOutsTitle: 'Is there a limit for cash outs?',
            limitForCashOutText: 'There is a $50,000 daily limit on cash outs.',

            fixedInstallmentVsRevolvingPlanTitle: 'Fixed Installment vs. Revolving Plan?',
            fixedInstallmentVsRevolvingPlanText: `
                <p class="mb-3">You have two options for monthy payments: as a credit card minimum payment or fixed monthly payments.</p>
                <p class="mb-3">Option 1: Make payments like a regular credit card. Your minimum monthly payment will be 1% of the amount you cash out + your Aven interest rate (the same low rate as regular purchases). Your monthly payments will go down as your balance goes down.</p>
                <p class="mb-3">Option 2: Set up fixed monthly payments – no interest, just a fixed monthly fee. You can lock in your payments for 5 or 10 years (15 or 20 years if over $25K).</p>
            `,
        },
        cashOutById: {
            cancelTransactionCta: 'Cancel Transaction',
            confirmationMessage: 'Your transaction was successfully canceled.',
        },
        balanceTransferById: {
            cancelTransactionCta: 'Cancel Transaction',
            confirmationMessage: 'Your transaction was successfully canceled.',
            cancelatonRequestError: 'We were unable to cancel your balance transfer because it was already processed.',
        },
        bankConnect: {
            tryAgain: 'Unable to link bank account. Please try again or try another bank account. If the problem persists, please contact customer service for assistance.',
            loadingTitle: 'Processing...<br /> This could take up to a minute.',
        },
        rewards: {
            title: 'Rewards',
            altTitle: 'Redeem Rewards',
            listTitle: 'Redeemed',
            redeem: {
                title: 'Redeem Points',
                btnCta: 'Redeem',
            },
            redeemedTitle: 'Rewards Redeemed',
            inputLabel: 'How many points?',
            inputPlaceholder: '1,000',
            cashbackAmountText: 'Cashback Amount',
            availablePoints: 'Available Points',
            genericError:
                'There was an error during rewards redemption. Please refresh the page and try again. If this problem persists, please reach out to our support team for assistance with your rewards redemption.',
            ineligibleSecondaryCard: 'To redeem points, please have the primary cardholder login into their account.',
            redeemMoreThanRemainingError: 'Max points available for redemption: {maxPointsAvailableForRedemption}',
        },
        payItForward: {
            title: 'Pay It Forward',
            altTitle: 'Spread The Love',
            description: 'Tell your friends about Aven! When they sign up, they’ll get a {maxAmount} intro bonus from Aven. They can share it with you.',
            copyLink: 'COPY',
            linkCopied: 'COPIED',
            terms: "For more details, see <a href='https://aven.com/docs/PifTerms.pdf' target='_blank'>Pay It Forward Terms.</a>",
            getShareLinkError: 'There was an error getting your personal invite link. Please refresh and try again.',
            options: {
                textMsg: 'Text Message',
                email: 'Email',
                copyLink: 'Copy Invite Link',
                moreOptions: 'More Options',
            },
            smsMessage: 'Hey! I got the Aven Card – a card for homeowners – and love it. If you join using my link, you get {maxAmount} in credit. {pifLink}', //sms max length per message is 160, current string max length is ~150
        },
        payItForwardContactList: {
            title: '<b>Invite friends!</b> They get $400 and can share it with you.',
            invite: 'Invite',
            searchPlaceholder: 'Name or Phone',
            infoModalTitle: 'About Pay It Forward',
            infoModalContent:
                'When they sign up, they\'ll get $400 from us and can share it with you. For more details, see <a href="https://aven.com/docs/PifTerms.pdf" target="_blank" class="text-primary text-underline">Pay It Forward Terms</a>',
            infoModalClose: 'Close',
        },
        payItForwardTipping: {
            description: 'Because your friend {sourceName}, introduced you to Aven we are gifting you a {maxAmount} bonus as a statement credit. Would you like to share {tipAmount} of it with them?',
            customTipDescription: 'How much of your {maxAmount} bonus would you like to share with {sourceName}?',
            tippedSourceMessage: 'Great, thanks for sharing!',
            pickTip: 'PICK A TIP',
            otherAmount: 'Other Amount',
            noTip: `Don’t Tip`,
            cta: 'Confirm',
            noTipMessage: 'Welcome to Aven!</br>Enjoy the bonus 🎉',
            tipMessage: 'Great, thanks for sharing!',
            terms: "By accepting the bonus you agree to the <a class='text-underline text-muted' href='https://aven.com/docs/PifTerms.pdf' target='_blank'>Bonus Terms</a>.",
        },
        signOnBonus: {
            description: 'Because your fantastic realtor, <strong>{sourceName}</strong>, introduced you to Aven we are gifting you a <strong>{maxAmount}</strong> bonus as a statement credit.',
            acknowledged: 'Welcome to Aven!<br>Enjoy the bonus 🎉',
        },
        closeAccountInRescission: {
            title: 'Close Account',
            body:
                '<p>Your account is not opened yet. You have a legal right under federal law to cancel the account, without cost, within three business days of notarization.</p>' +
                '<p>When you cancel the account, we will close the Aven Card account. You will have to re-apply in the case you want a new Aven Card.</p>',
            confirmCheckboxLabel:
                '<b>Confirm Cancellation</b><br/>By checking the box, I understand that by continuing my account will be closed, and I will have to submit a new application to open an Aven card',
            logOutNotice: 'You will be logged out and we will send you an email confirmation.',
            confirmCta: 'Confirm Cancelation',
        },
        closeAccount: {
            title: 'Close Account',
            prompt: {
                text: 'Why are you looking to close your Aven account?',
            },
            permanentClose: {
                title: 'Close Permanently',
                subText1: 'When you close the account, we will close the Aven card account. You will have to re-apply in case you want an Aven card.',
                subText2: 'The county can take up to 14 days from account being fully paid off to record the lien reconvenyance.',
                buttonText: 'Continue',
            },
            keepCard: {
                text: 'Would you like to keep your Aven Card open for the future?',
                subText:
                    'The lien will be removed and the line will be reduced to {limitedModeCreditLimit} until you are ready to easily reactivate the full Aven Card. <b>No need to reapply from scratch.</b>',
                yesBtnText: 'Remove Lien & Keep Card',
                noBtnText: 'No, Close Permanently',
            },
            payoffDetails: {
                text: 'If you do not initiate the wire transfer by the end of the due date ({tomorrow}), please return here to get the latest amount.',
                errors: {
                    genericTitle: 'Payoff Details Failed',
                    genericDesc: 'Sorry, something went wrong. Please try again.',
                    payoffDateBeyondStatementTitle: 'Invalid Payoff Date',
                    payoffDateBeyondStatementDesc: 'Sorry, we could not generate your pay-off quote because tomorrow is the beginning of your new statement cycle. Please try tomorrow again.',
                },
            },
            emailPayoff: {
                title: 'Email PayOff Documents',
                placeholder: 'Email Address',
                addEmail: 'Add Other Email',
                sendConfirmTitle: 'Email Sent',
                sendConfirmText: 'We have sent the PayOff documents in an email to <b>{emails}</b>',
                sendError: 'Failed to send Pay Off document to emails',
            },
            wireTransfer: {
                confirmWireText: 'Confirm Wire Will Be Initiated',
                confirmWireSubText: 'I confirm I will initiate the wire transfer from my bank account for {amount} and will include the additional note for reference.',
                confirmCloseText: 'Confirm Close',
                confirmCloseSubText: 'By checking the box, I understand by continuing my account will be closed, and I will have to submit a new application to open an Aven card.',
                confirmKeepCardText: 'Confirm {limitedModeCreditLimit} Card',
                confirmKeepCardSubText: 'The lien will be removed and the line will be reduced to {limitedModeCreditLimit} until you are ready to easily reactivate the full Aven Card.',
                nothingToDoText: 'You are all set! We are working on our end to finalize everything. We will keep you updated.',
            },
        },
    },
    components: {
        formSelectAutocomplete: {
            noOptions: 'No Lenders Found',
        },
        accountStatusBanner: {
            ctaPayNow: 'Pay Now',
            pastDue: {
                title: 'Account Past Due',
                description: 'Please pay the outstanding balance (${amount}) to get back on track.',
            },
            delinquent: {
                title: 'Card Is Disabled',
                description: 'You have missed multiple minimum payments. Please pay the outstanding balance (${amount}) to enable your card again.',
            },
            overLimit: {
                title: 'Card Limit Exceeded',
                description: 'Your balance is currently over the limit. Your card is currently disabled.',
            },
            fraud: {
                title: 'Fraud Detected',
                description: 'For your security, we have frozen your card from making any further purchases and balance transfers.',
            },
            blocked: {
                title: 'Account Blocked',
                description: 'Your card has been blocked from making any further purchases and balance transfers.',
            },
            closed: {
                title: 'Account Closed',
                description: 'Your account has been closed.',
            },
            ctaViewDetails: 'View Details',
            pendingClose: {
                title: 'Account Pending Close',
                description: 'Your card is pending to be closed and will no longer function.',
            },
            pendingPayoffThenLimitedMode: {
                title: 'Pending Lien Removal',
                description: 'The lien is pending removal, and then your card will be transitioned to the {limitedModeCreditLimit} limited mode.',
            },
        },
        accountActivationBanner: {
            ctaActivate: 'Activate',
            activate: {
                title: 'Activate Card',
                description: 'Please activate your new card as soon as you receive it in the mail.',
            },
        },
        balanceTransfer: {
            empty: {
                title: 'Credit Card Balance Transfer',
                subTitle: 'Transfer balances from those high-interest cards and stop paying so much in interest. If you want to transfer a personal loan check out our Cash Out feature.',
            },
            linkedCards: {
                sectionHeader: 'LINKED CARDS',
            },
            listTransfers: {
                cta: 'New Credit Card Transfer',
            },
            pendingTransfer:
                'We have sent the request to your other card. It typically takes 7 business days to reflect on your other card. If you do not see it after that, please contact your other card.',
            error: {
                title: 'Balance Transfer Error',
                genericError:
                    'An error came up while processing your Balance Transfer request. Our team has been notified. Please refresh the page and try again. If this problem persists, please reach out to our support team for assistance with your Balance Transfer.',
                invalidAccountNumberFail: {
                    title: 'Credit Card Error',
                    subTitle: 'Sorry, the credit card number you entered could not be validated. Please try again or reach out to our support team.',
                },
                btForbiddenToCustomer:
                    'Unfortunately, Balance Transfers can only be initiated by the primary cardholder or co-applicant. Please have the primary cardholder or co-applicant perform the Balance Transfer.',
                invalidAmountFail: 'The amount you have entered is invalid. Please correct the amount and submit your Balance Transfer again.',
                bankNotSupported:
                    'Sorry, we do not currently support balance transfers from this credit card. Instead, you can use our Cash Out feature to send money to your bank account to pay off your card.',
                duplicateRequestError: {
                    title: 'Balance Transfer Exists',
                },
            },
            loadingTitle: 'Submitting...',
            accountList: {
                title: 'Transfer From...',
                otherAccountTitle: 'New Account',
            },
            iframe: {
                titleOther: 'New Account',
                ccOption: 'Credit Card',
                plOption: 'Personal Loan',
            },
            confirmation: {
                title: 'Transfer Requested',
                subTitle: '{amount} has been requested to transfer from your card ending in {last4OfTargetCard}. This usually takes around 7 business days to reflect on your other card.',
                doneCta: 'Done',
                transferOtherAccounts: 'Transfer Other Accounts',
            },
            summary: {
                title: 'Card Balance Transfer',
                subTitle: 'A Balance Transfer of <b>{amount}</b> from your <b>credit card ending in {last4OfTargetCard}</b> will be reviewed and initialized once approved.',
                transferAmountTitle: 'Transfer From ***{last4OfTargetCard}',
                transferFeeTitle: '{fee} Fee',
                transferTotalTitle: 'Added To Balance',
                confirmTransferCta: 'Confirm Transfer',
            },
            restricted: {
                title: 'Balance Transfer',
                secondaryNotAllowed: 'Unfortunately, balance transfers can only be initiated by the primary cardholder. Please have the primary cardholder perform the balance transfer.',
            },
            promo: {
                title: 'Get Paid to Balance Transfer',
                body: 'Complete a balance transfer to your Aven card by April 30th and get 1% back',
                cta: 'Start Balance Transfer',
            },
            balanceTransferDailyLimitInfo: `For security reasons, there is a ${generic.prettyPrintDollarAmount(
                String(MAX_TOTAL_DAILY_BT_PLUS_CASH_OUT_IN_DOLLARS)
            )} daily maximum for combined Cash Outs and Balance Transfers.`,
            balanceTransferCreditLimitInfo: 'The maximum amount is based on your available credit.',
        },
        featureNotAvailable: {
            title: 'Feature Not Available',
            subTitle: 'Sorry, the current feature is not available because your account is not current. Contact us if you have any questions.',
            contactUsBtnCta: 'Contact Us',
        },
        balanceTransferOrCashOutInfo: {
            paybackDetails: 'Payback Details',
            monthlyBreakdown: 'Monthly Breakdown',
            pending: 'Pending',
            completed: 'Completed',
            reversal: 'Refunded',
            canceled: 'Canceled',
            status: 'Status',
            from: 'From',
            dateInitiated: 'Date Initiated',
            amount: 'Amount',
            fee: 'Fee',
            totalRemaining: 'Total Remaining',
            method: 'Method',
            startDate: 'Start Date',
            termLength: 'Term Length',
            principal: 'Principal',
            fixedFinanceCharge: 'Fixed Finance Charge',
            totalMonthlyPayment: 'Total Monthly Payment',
            fixed: 'Fixed Installments',
            revolving: 'Standard Revolving',
            apr: 'APR',
        },
        amortizationLoanChoice: {
            amortizationOptionsSectionHeader: 'Payback Method',
            amortizationOptionsStandardText: 'Standard Credit Card Revolving Plan',
            amortizationOptionsStandardSubText: 'Pay a minimum of 1% of principal each month plus your standard Aven variable rate interest of {apr}%.',
            amortizationOptionsTermText: 'Fixed Monthly Installments',
            amortizationOptionsTermSubTextWithFee: 'Get predictable monthly payments using a fixed monthly finance charge and no interest. {fee} Fee separately charged.',
            amortizationOptionsTermSubText: 'Get predictable monthly payments using a fixed monthly finance charge and no interest.',
            amortizationOptionTitle: '{numYears} Years',
            amortizationOptionSubTitle: '{numPayments} Payments',
            amortizationOptionsSubText1: 'Principal',
            amortizationOptionsSubText2: 'Fixed Finance Charge',
        },
        learnMore: {
            graduationCapAlt: 'Graduation Cap Icon',
            title: 'Learn More',
        },
        commonActions: {
            cardBalance: 'Card Balance Transfer',
            cashOut: 'Cash Out<br>To Bank',
            makePayment: 'Make A Payment',
        },
        securedPasswordCheck: {
            title: 'Confirm your password',
            incorrectPassword: 'Incorrect Password',
        },
        securedSsnCheck: {
            title: 'Confirm the last 4 digits of your social security',
            ssnInvalid: 'Please check the last 4 digits of your social security number',
        },
    },
    header: {
        question: 'Need Help?',
        description: 'If you have any questions or comments, please feel free to text us.',
        desktopDescription: `If you have any questions or comments, please feel free to text us at <a href='sms:${contact_info.sms}'>${contact_info.smsFormatted}</a>.`,
        sendText: 'Send Text', //`<a class="text-white" href='tel:${contact_info.phone}'>Call</a>`,
        help: 'Help',
    },
    dashBoard: {
        call: `<a href='tel:${contact_info.phone}' style="color: black">${contact_info.dashFormatted}</a>`,
    },
    global: {
        // Page not found
        pageNotFound: {
            header: 'Page not found',
            subHeader: 'Sorry, but the page you were looking for does not seem to exist. To apply, visit the link below.',
            cta: 'Get started',
        },
        loadingMessage: {
            loading: 'Loading...',
            verifying: 'Verifying...',
            saving: 'Saving...',
        },
        cta: {
            continue: 'Continue',
            gotIt: 'Got It!',
            next: 'Next',
            goBack: 'Go Back',
            refresh: 'Refresh',
            retry: 'Retry',
            goToLogin: 'Go To Login',
            dismiss: 'Dismiss',
            done: 'Done',
            setupAutoPay: 'Set Up AutoPay',
            notNow: 'Not Now',
            forgotPassword: 'Forgot Password',
        },
        idleWarning: {
            title: 'Idle Warning',
            message: 'Your session is about to expire in 30 seconds due to inactivity',
            stay: 'Stay',
            logout: 'Logout',
        },
        errors: {
            title: 'Something Went Wrong',
            generic: 'Sorry, there was a problem with the network. Please try again.',
            genericRetry: 'Something went wrong. Please try again.',
            confirmAddress: 'Could not confirm address. Please check your entry',
            serverError: 'Oh no, something went wrong. Our team has been notified.',
            biometricsGenericError: 'Please sign in to enable biometrics',
        },
    },
    customValidationRules: {
        noQuotesAllowed: 'Single quote and double quotes are not allowed',
        twilioCode: 'Code must be exactly 6 digits',
        notValidPhoneNumber: 'Not a valid phone number',
        invalidCurrency: 'Please enter a valid amount',
        currencyTooSmall: 'Minimum allowed amount is {minAmount}',
        currencyTooLarge: 'Maximum allowed amount is {maxAmount}',
        pointsTooSmall: 'You can redeem a minimum of {minAmount} points',
        pointsTooLarge: 'You currently only have {maxAmount} points',
        maxAllowedByACI: `Maximum allowed is $${MAX_AMOUNT_ALLOWED_BY_ACI_FOR_CASH_OUT_IN_DOLLARS} per day`,
        confirmNoBusinessAccountManualCashout: 'Business accounts are currently not supported via a manual upload. Please use a personal checking or savings account or use "link account" instead.',
        passwordMismatch: "The passwords you entered don't match",
    },
    validation: {
        alpha: 'May only contain alphabetic characters',
        alpha_num: 'May only contain alpha-numeric characters',
        alpha_dash: 'May contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'May only contain alphabetic characters as well as spaces',
        between: 'Must be between {min} and {max}',
        confirmed: 'Confirmation does not match',
        digits: 'Must be numeric and exactly contain {length} digits',
        dimensions: 'Must be {width} pixels by {height} pixels',
        email: 'Must be a valid email',
        excluded: 'Is not a valid value',
        ext: 'Is not a valid file',
        image: 'Must be an image',
        integer: 'Must be an integer',
        length: 'Must be {length} characters long',
        max_value: 'Must be {max} or less',
        max: 'May not be greater than {length} characters',
        mimes: 'Must have a valid file type',
        min_value: 'Must be {min} characters or more',
        min: 'Must be at least {length} characters',
        numeric: 'May only contain numeric characters',
        oneOf: 'Is not a valid value',
        regex: 'Format is invalid',
        required_if: 'This field is required',
        required: 'This field is required',
        size: 'Size must be less than {size}KB',
    },
    accessibility: {
        error: 'Error',
        close: 'Close',
        loading: 'Loading',
        back: 'Back',
        clear: 'Clear',
        active: 'Active',
        transfer: 'Transfer',
        party: 'Party Emoji',
    },
    email: {
        sharePifLink: {
            body: `Hey! I've been using the Aven Card – a card for homeowners – and love it. If you join using my code, you get {maxAmount} in credit. Go to {pifLink} to get started.`,
            subject: `Get {maxAmount} in credit if you join Aven!`,
        },
    },
    twilioError: {
        generic: 'Sorry, Something went wrong. Please try again later.',
        60200: 'Invalid confirmation code',
        20404: 'Confirmation code has expired.',
        60202: 'Please wait 30 minutes, then request a new confirmation code.',
        60203: 'Too many codes requested. Please try again after 10 minutes.',
        60212: 'Max number of attempts reached. Please try again in 30 minutes.',
    },
}
