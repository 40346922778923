<template>
    <div class="container text-center">
        <div class="row justify-content-md-center">
            <div class="mt-3">
                <img
                    :src="require(`../assets/${this.imagePathInAssets}`)"
                    :width="imageWidth"
                    class="mb-3"
                    :alt="$t('accessibility.error')"
                >
                <h5
                    v-show="showTitle"
                    class="mb-2"
                >
                    {{ title }}
                </h5>
                <h5
                    class="fw-light mb-3 mx-lg-3"
                    v-show="showDescription"
                >
                    {{ description }}
                </h5>
                <base-button
                    v-if="cta"
                    type="submit"
                    :submitting="false"
                    @click="$emit('click')"
                >
                    {{ cta }}
                </base-button>
                <div class="mt-1">
                    <slot />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { i18n } from '@/utils/i18n'
    import BaseButton from '@/components/base/BaseButton'

    export default {
        name: 'ErrorView',
        components: {
            BaseButton,
        },
        props: {
            imagePathInAssets: {
                type: String,
                default: 'error-icon.svg',
            },
            imageWidth: {
                type: Number,
                default: 160,
            },
            showTitle: {
                type: Boolean,
                default: true,
            },
            title: {
                type: String,
                default: i18n.t('global.errors.title'),
            },
            showDescription: {
                type: Boolean,
                default: true,
            },
            description: {
                type: String,
                default: i18n.t('global.errors.generic'),
            },
            cta: {
                type: String,
            },
        },
    }
</script>
