<template>
    <div>
        <validation-provider
            ref="provider"
            :mode="validationMode"
            v-slot="{ valid, errors }"
            :vid="name"
            :rules="validationRules"
        >
            <div class="input">
                <label
                    v-if="label"
                    :for="name"
                >{{ label }}</label>
                <cleave
                    :data-testid="dataTestid"
                    ref="formFieldInput"
                    type="tel"
                    class="form-control"
                    :form-classes="formClasses"
                    :raw="true"
                    :id="name"
                    :options="options"
                    :class="[errors[0] ? 'is-invalid' : valid ? 'is-valid' : null]"
                    :name="name"
                    :placeholder="dynamicPlaceholder"
                    auto-complete="off"
                    :value="value"
                    v-model="cleaveValue"
                />
                <div class="invalid-feedback">
                    {{ errors[0] }}
                </div>
            </div>
        </validation-provider>
    </div>
</template>

<script>
    import { formInputCleaveMixin } from '@/mixins/formInputMixin'
    import 'cleave.js/dist/addons/cleave-phone.us'

    export default {
        name: 'FormFieldPhone',
        mixins: [formInputCleaveMixin],

        props: {
            validationRules: { type: String, default: 'required|phoneNumberRule' },
            options: {
                type: Object,
                default: function () {
                    return { phone: true, phoneRegionCode: 'US' }
                },
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
    }
</script>
