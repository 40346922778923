import { httpClient } from './http-client'
import { AccountClosureReason, AccountClosureRequester, AccountClosureRequestSource, AccountClosureType, ProductCategory } from '@/store'
import { AciAccountSubType, AmortizationSource, ApiRequireSingleUseToken } from '@/data/constants'
import { logger } from '@/utils/logger'

interface APIResponseData {
    success: boolean
    error: string
    payload: any
}

interface APIResponse {
    status: number
    statusText: string
    data: APIResponseData
}

export const submitLogRocketSessionUrl = async (sessionId, logRocketSessionUrl) => {
    try {
        await httpClient.post('/aven_app/ana/logRocketUrl', { sessionId, logRocketSessionUrl })
    } catch (error) {
        logger.info(`submission failed, LogRocket sessionUrl, ${logRocketSessionUrl}, ${error}`)
    }
}

// Transactions
const transactionHistory = async (pageIndex: string, type: string) => {
    return await httpClient.post('/avenGetCHTransactionHistory', { pageIndex, type })
}

// AccountDetails
const accountDetails = async () => {
    return await httpClient.post('/avenCoreCardAccountDetails')
}

// Account Payoff Details
const accountPayoffQuote = async (requestDateInNycTime: Date, payoffDateInNycTime: Date) => {
    return await httpClient.post('/aven_app/getPayoffQuote', { requestDateInNycTime, payoffDateInNycTime })
}

const accountPayoffQuotePdf = async (requestDateInNycTime: Date, payoffDateInNycTime: Date) => {
    return await httpClient.get('/aven_app/getPayoffQuotePdf', {
        responseType: 'blob',
        params: {
            requestDateInNycTime,
            payoffDateInNycTime,
        },
    })
}

const sendAccountPayoffQuotePdfToEmail = async (requestDateInNycTime: Date, payoffDateInNycTime: Date, emails: string[]) => {
    return await httpClient.post('/aven_app/sentPayoffQuoteToEmail', {
        requestDateInNycTime,
        payoffDateInNycTime,
        emails,
    })
}

// Card
const cardImage = async () => {
    return await httpClient.post('/avenCoreCardGetCardImageURL')
}

const cardHolderDetail = async () => {
    return await httpClient.post('/avenCoreCardGetCardHolderDetail')
}
// check AutoPay
const avenAutopayDetails = async () => {
    return await httpClient.get('/avenAutopayDetails')
}

// Get CreditCard Activation Status
const creditCardActivationStatus = async () => {
    return await httpClient.get('/avenCreditCardActivationStatus')
}

const getBalanceTransfers = async () => {
    return await httpClient.get('/balanceTransfer/balanceTransfersByCustomer')
}

const getCashOutById = async (cashOutId: number) => {
    return await httpClient.post('/cashOut/cashOutById', { cashOutId })
}

const getBalanceTransferById = async (balanceTransferId: number) => {
    return await httpClient.post('/balanceTransfer/balanceTransferById', { balanceTransferId })
}

const requestCashOutCancelation = async (balanceTransferId: number) => {
    return await httpClient.post('/cashOut/cancelCashOut', { balanceTransferId })
}

const registerCashOutUsingPlaid = async (amount: number, accountType: string, institutionName: string) => {
    return await httpClient.post('/cashOut/registerCashOutUsingPlaid', {
        amount,
        accountType,
        institutionName,
    })
}

const registerCashOut = async (amount: number, externalBankAccountId: string, hasBankStatementToUpload: boolean) => {
    return await httpClient.post('/cashOut/registerCashOut', {
        amount,
        externalBankAccountId,
        hasBankStatementToUpload,
    })
}

const requestBalanceTransferCancelation = async (balanceTransferId: number) => {
    return await httpClient.post('/balanceTransfer/cancelBalanceTransfer', { balanceTransferId })
}

const postCreditCardBalanceTransfer = async (balanceTransferId: number, amortizationLoanId: number, shouldAmortize: boolean) => {
    return await httpClient.post('/balanceTransfer/postBalanceTransfer', {
        balanceTransferId,
        amortizationLoanId: shouldAmortize ? amortizationLoanId : undefined,
    })
}

// Enable AutoPay
const avenAutopayOptIn = async (fundingSourceSubType: string, fundingTokenId: string, autopayCategory: string, autopayCustomAmount: string) => {
    return await httpClient.post('/avenAutopayOptIn', {
        fundingSourceSubType,
        fundingTokenId,
        autopayCategory,
        autopayCustomAmount,
    })
}

const activateAvenCard = async (last4DigitsAvenCard: string) => {
    return await httpClient.post('/aven_app/activateCard', {
        last4DigitsAvenCard,
    })
}

// Disable AutoPay
const avenAutopayOptOut = async () => {
    return await httpClient.post('/avenAutopayOptOut')
}

// Notification Status
const avenNotificationStatus = async () => {
    return await httpClient.get('/getPushNotificationSettingStatus')
}

// Notification Setting
const avenNotificationSetting = async (isEnabled: boolean) => {
    return await httpClient.post('/updatePushNotificationSetting', {
        isEnabled,
    })
}

// Add Payment method - Bank
const aciGenerateFundingTokenBankAccount = async (
    bankAccountType: string,
    routingNumber: string,
    bankAccountNumber: string,
    accountHolderName: string,
    state: string,
    postalCode: string,
    accountNickname: string
) => {
    return await httpClient.post('/avenAciGenerateFundingTokenBankAccount', {
        bankAccountType,
        routingNumber,
        bankAccountNumber,
        accountHolderName,
        state,
        postalCode,
        accountNickname,
    })
}

const uploadDocument = async (fileUrl, documentTypePath, documentIndex) => {
    const formData = new FormData()
    formData.append('file', fileUrl)
    return await httpClient.post(`/uploadDocument/${documentTypePath}/${documentIndex}`, formData)
}

// Update Card Holder Details
const UpdateCardHolderInformation = async (
    homePhoneNumber: string,
    addressLine1: string,
    addressLine2: string,
    city: string,
    state: string,
    country: string,
    postalCode: string,
    firstName: string,
    middleName: string,
    lastName: string,
    email: string
) => {
    return await httpClient.post('/avenCoreCardUpdateCardHolderInformation', {
        homePhoneNumber,
        addressLine1,
        addressLine2,
        city,
        state,
        country,
        postalCode,
        firstName,
        middleName,
        lastName,
        email,
    })
}

// List Funding sources
const aciSearchFundingSources = async () => {
    return await httpClient.post('/avenAciSearchFundingSources')
}

// getDefaultFundingSource
const aciGetDefaultFundingSource = async () => {
    return await httpClient.get('/avenAciGetDefaultFundingSource')
}

const getCardEmbossingStatus = async () => {
    return await httpClient.get('/avenCoreCardGetEmbossingManualStatus')
}

const freezeCard = async () => {
    return await httpClient.post('/freezeCard')
}

const unfreezeCard = async (password: string) => {
    return await httpClient.post('/unfreezeCard', { passcode: password })
}

// SetDefaultFundingSource
const aciSetDefaultFundingSource = async (fundingSourceSubType: string, fundingTokenId: string, fundingNickName: string) => {
    return await httpClient.post('/avenAciSetDefaultFundingSource', {
        fundingSourceSubType,
        fundingTokenId,
        fundingNickName,
    })
}

//delete Funding Source
const avenAciDeleteFundingSource = async (fundingTokenId: string) => {
    return await httpClient.post('/avenAciDeleteFundingSource', {
        fundingTokenId,
    })
}
// Make a payment
const aciMakePayment = async (payload: { fundingTokenId: string; fundingSourceSubType: string; paymentDate: Date; paymentAmount: string }) => {
    return await httpClient.post('/avenAciMakePayment', payload)
}

//for getting account agreement and other legal documents associated with the customer
const getLegalDocumentDownload = async (docType: string, taxYearFor1098Form?: number) => {
    return await httpClient.get('/legal/document', {
        responseType: 'blob',
        params: {
            docType,
            taxYearFor1098Form,
        },
    })
}

const generateLegalDocument = async (docType: string) => {
    return await httpClient.post('/legal', {
        docTypes: [docType],
    })
}

const postRedeemRewards = async (numLoyaltyPointsToRedeem: number, dollarValueOfPoints: string) => {
    return await httpClient.post('/primeCard/redeemRewards', {
        numLoyaltyPointsToRedeem,
        dollarValueOfPoints,
    })
}

const getRewardsSummary = async () => {
    return await httpClient.get('/primeCard/getRewardsSummary')
}

const postRequestSingleUseToken = async (password: string, last4Ssn: string, api: ApiRequireSingleUseToken) => {
    return await httpClient.post('/aven_app/requestSingleUseToken', {
        passcode: password,
        last4Ssn,
        api,
    })
}

const postVerifyLast4Ssn = async (last4Ssn: string) => {
    return await httpClient.post('/aven_app/verifyLast4Ssn', {
        last4Ssn,
    })
}

const postUpdateDocumentUploadIdForCashOut = async (cashOutId: number, documentUploadId: number) => {
    return await httpClient.post('/cashOut/updateBankStatementIdForCashOut', {
        cashOutId,
        documentUploadId,
    })
}

const postCashOutWithBankStatement = async (singleUseToken: string, cashOutId: number, amortizationLoanId: number, shouldAmortize: boolean) => {
    return await httpClient.post('/balanceTransfer/cashOutWithBankStatement', {
        singleUseToken,
        cashOutId,
        amortizationLoanId: shouldAmortize ? amortizationLoanId : undefined,
    })
}

const postCashOut = async (singleUseToken: string, cashOutId: number, amortizationLoanId: number, shouldAmortize: boolean) => {
    return await httpClient.post('/balanceTransfer/cashOut', {
        singleUseToken,
        cashOutId,
        amortizationLoanId: shouldAmortize ? amortizationLoanId : undefined,
    })
}

const getAmortizationLoanTerms = async (amount: number, amortizationSource: AmortizationSource) => {
    return await httpClient.post('/coreCard/getAmortizationLoanTerms', {
        amount,
        amortizationSource,
    })
}

const startPlaidReportFetch = async (plaidPublicToken: string, plaidDefaultAccountId: string, institutionInfo: string) => {
    return await httpClient.post('/startPlaidReportFetch', {
        public_token: plaidPublicToken,
        default_account_id: plaidDefaultAccountId,
        institutionInfo,
    })
}

const plaidReportFetchState = async () => {
    return await httpClient.get('/plaidReportFetchState')
}

const plaidBankAccountDetails = async (depositAccountId: string) => {
    return await httpClient.post('/plaidGetBankAccountDetails', {
        depositAccountId,
    })
}

const postDesiredProduct = async (desiredProduct: ProductCategory) => {
    return await httpClient.post('/postDesiredProduct', {
        desiredProduct,
    })
}

const hasNameMatch = async () => {
    return await httpClient.get('/balanceTransfer/hasNameMatch')
}

const hasCustomerNameMatch = async () => {
    return await httpClient.get('/hasCustomerNameMatch')
}

const closeAccountInRescission = async () => {
    return await httpClient.post('/closeAccountInRescission')
}

const initiateAccountClosureRequest = async (requestDateInNycTime: Date, requestType: AccountClosureType, requestReason: AccountClosureReason) => {
    return await httpClient.post('/aven_app/accountClosure/initiateRequest', {
        requestDateInNycTime,
        requestType,
        requester: AccountClosureRequester.USER,
        requestSource: AccountClosureRequestSource.SELF_SERVE,
        requestReason,
    })
}

const getActiveBalanceTransferAccountsEligibleForRecurrence = async (): Promise<APIResponse> => {
    return await httpClient.get('/aven_app/auto_bt/eligible_active_accounts')
}

const getActiveBalanceTransferAccount = async (token: string): Promise<APIResponse> => {
    return await httpClient.get(`/aven_app/auto_bt/active_account/${token}`)
}

interface IBalanceTransferAccountUpdate {
    accountId: number
    accountName: string
    isAutoBalanceTransferEnabled: boolean
}

const updateAutoBalanceTransferEnabledAccounts = async (accounts: IBalanceTransferAccountUpdate[]): Promise<APIResponse> => {
    return await httpClient.post('/aven_app/auto_bt/update_auto_bt_enabled', {
        updatedActiveBalanceTransferAccounts: accounts,
    })
}

interface IInitiateBalanceTransferRequest {
    token: string
    amount: number
}

const initiateBalanceTransferForRecurringAccount = async (transfer: IInitiateBalanceTransferRequest): Promise<APIResponse> => {
    return await httpClient.post('/aven_app/auto_bt/initiate', transfer)
}

const getSavedBankAccounts = async (): Promise<APIResponse> => {
    return await httpClient.get(`/getSavedBankAccounts`)
}

const registerNewBankAccountUsingBankStatementUpload = async (accountType: AciAccountSubType, institutionName: string, accountNumber: string, routingNumber: string): Promise<APIResponse> => {
    return await httpClient.post('/cashOut/registerNewBankAccountUsingBankStatementUpload', {
        accountType,
        institutionName,
        accountNumber,
        routingNumber,
    })
}

export {
    postDesiredProduct,
    transactionHistory,
    cardImage,
    cardHolderDetail,
    UpdateCardHolderInformation,
    aciGenerateFundingTokenBankAccount,
    aciSearchFundingSources,
    aciMakePayment,
    avenAutopayDetails,
    avenNotificationSetting,
    avenNotificationStatus,
    avenAutopayOptIn,
    avenAutopayOptOut,
    accountDetails,
    accountPayoffQuote,
    accountPayoffQuotePdf,
    sendAccountPayoffQuotePdfToEmail,
    aciSetDefaultFundingSource,
    aciGetDefaultFundingSource,
    avenAciDeleteFundingSource,
    creditCardActivationStatus,
    activateAvenCard,
    getCardEmbossingStatus,
    freezeCard,
    unfreezeCard,
    getLegalDocumentDownload,
    getBalanceTransfers,
    postRedeemRewards,
    getRewardsSummary,
    postRequestSingleUseToken,
    postCashOut,
    postVerifyLast4Ssn,
    startPlaidReportFetch,
    plaidReportFetchState,
    plaidBankAccountDetails,
    hasCustomerNameMatch,
    uploadDocument,
    postCashOutWithBankStatement,
    hasNameMatch,
    getCashOutById,
    getBalanceTransferById,
    requestCashOutCancelation,
    closeAccountInRescission,
    initiateAccountClosureRequest,
    requestBalanceTransferCancelation,
    getAmortizationLoanTerms,
    postCreditCardBalanceTransfer,
    generateLegalDocument,
    getActiveBalanceTransferAccountsEligibleForRecurrence,
    getActiveBalanceTransferAccount,
    IBalanceTransferAccountUpdate,
    updateAutoBalanceTransferEnabledAccounts,
    IInitiateBalanceTransferRequest,
    initiateBalanceTransferForRecurringAccount,
    registerCashOutUsingPlaid,
    registerCashOut,
    postUpdateDocumentUploadIdForCashOut,
    getSavedBankAccounts,
    registerNewBankAccountUsingBankStatementUpload,
}
