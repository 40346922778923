<template>
    <div
        v-if="visibility"
        class="modal fade px-3 questionModal"
        :class="{ show: visibility }"
        :style="{ display: visibility ? 'block' : 'none' }"
    >
        <div
            class="modal-dialog modal-dialog-centered"
            role="document"
        >
            <div class="modal-content position-relative p-3">
                <div class="position-relative p-0">
                    <img
                        class="mclose"
                        src="../assets/images/global/close.svg"
                        data-dismiss="modal"
                        @click="closeModal"
                        @keydown.enter="closeModal"
                        :alt="$t('accessibility.close')"
                    >
                    <h5 class="text-center">
                        {{ title }}
                    </h5>
                    <h6
                        class="text-center my-3"
                        v-html="description"
                    />
                    <a
                        v-show="showButton"
                        class="btn btn-primary w-100"
                        @click="sendSupportText"
                        @keydown.enter="sendSupportText"
                    >
                        <span style="color: white">{{ $t('header.sendText') }}</span>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { isMobile } from '@/utils/parseUserAgents'

    export default {
        name: 'Help',
        props: {
            visibility: {
                type: Boolean,
                required: true,
                default: false,
            },
            title: {
                type: String,
                default: i18n.t('header.question'),
            },
            description: {
                type: String,
                default: isMobile() ? i18n.t('header.description') : i18n.t('header.desktopDescription'),
            },
            showButton: {
                type: Boolean,
                default: isMobile(),
            },
        },
        methods: {
            closeModal() {
                this.$emit('close')
            },
            sendSupportText() {
                logger.info(`SMS - onClick, sms:${contact_info.sms}`)
                window.location.href = `sms:${contact_info.sms}`
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/appHeader.scss';
    @import '../styles/components/appBase';
</style>
