<template>
    <card-block
        v-show="!accountIsClosed"
        class="text-center"
    >
        <p class="mb-0 fw-bold">
            Friends get ${{ payItForwardMaxAmount }} on us!
        </p>
        <p class="mt-0 text-muted">
            They can share it with you.
        </p>
        <div class="d-block w-100">
            <base-button @click="goToPayItForward">
                Invite Friends
            </base-button>
        </div>
    </card-block>
</template>

<script>
    import { mapGetters } from 'vuex'
    import BaseButton from '@/components/base/BaseButton'
    import CardBlock from '@/components/CardBlock'
    import goToPayItForward from '@/mixins/goToPayItForward'

    export default {
        name: 'PayItForwardCard',
        components: { CardBlock, BaseButton },
        mixins: [goToPayItForward],
        computed: {
            ...mapGetters(['payItForwardMaxAmount', 'accountIsClosed']),
        },
    }
</script>
