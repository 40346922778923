import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import advanced from 'dayjs/plugin/advancedFormat'

import { AVEN_DATE_TIME_FORMAT, CORE_DATE_TIME_FORMAT, DISPLAY_DATE_FORMAT, NYC_TIMEZONE, MONTH_YEAR_FORMAT, MONTH_FORMAT } from '@/data/constants'
import { logger } from '@/utils/logger'

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export default {
    // Returns Formatted phone numbers : (123)456-7890
    formatPhoneNumber(phoneNumber: String) {
        const cleaned = ('' + phoneNumber).replace(/\D/g, '')
        const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
            const intlCode = match[1] ? '1 ' : ''
            return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('')
        } else {
            return phoneNumber
        }
    },

    // Returns Formatted Amount like : $1,234.56
    // ✓ 0 => 0.00
    // ✓ 0.123456 => 0.123456
    // ✓ 100 => 100.00
    // ✓ 100.123456 => 100.123456
    // ✓ 1000 => 1,000.00
    // ✓ 1000.123456 => 1,000.123456
    // ✓ 10000 => 10,000.00
    // ✓ 10000.123456 => 10,000.123456
    // ✓ 100000 => 100,000.00
    // ✓ 100000.123456 => 100,000.123456
    // ✓ 1000000 => 1,000,000.00
    // ✓ 1000000.123456 => 1,000,000.123456
    // ✓ 10000000 => 10,000,000.00
    // ✓ 10000000.123456 => 10,000,000.123456
    prettyPrintDollarAmount: function (amount: String) {
        const convertedAmount = amount.toString()
        if (convertedAmount == null) {
            return '$0'
        }
        if (convertedAmount.indexOf('-') > -1) {
            const removeMinus = convertedAmount.replace('-', '')
            return '-$'.concat(
                Number(removeMinus).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                })
            )
        } else {
            return '$'.concat(
                Number(convertedAmount).toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 4,
                })
            )
        }
    },

    prettyPrintPoints: function (number: String) {
        const convertedNumber = number.toString()
        if (convertedNumber == null) {
            return '0'
        }
        return Number(convertedNumber).toLocaleString()
    },

    validEmail(email: String) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        return email.match(re)
    },

    validPhoneNumber(value: String) {
        if (!isNaN(Number(value)) && value.length === 10) {
            return true
        }
        return false
    },

    getCurrentTimeInUtc(): Date {
        const currentDateTime = dayjs.utc().toDate()
        logger.info(`Current date time: ${currentDateTime}`)
        return currentDateTime
    },

    getCurrentJSDateInNycTz(): Date {
        return dayjs.tz(new Date(), NYC_TIMEZONE).toDate()
    },

    getTomorrowJSDateInNycTz(): Date {
        return dayjs.tz(new Date(), NYC_TIMEZONE).add(1, 'day').toDate()
    },

    getGivenFutureOrCurrentDayJSDateInNycTz(givenFutureOrCurrentDay: number): Date {
        logger.info(`converting givenFutureOrCurrentDay: ${givenFutureOrCurrentDay} to JS Date in NYC TZ`)
        const todayDate = dayjs.tz(new Date(), NYC_TIMEZONE)
        logger.info(`todayDate: ${todayDate}`)
        let givenFutureOrCurrentDate = dayjs.tz(new Date(), NYC_TIMEZONE).date(givenFutureOrCurrentDay)
        if (givenFutureOrCurrentDate.isBefore(todayDate, 'date')) {
            givenFutureOrCurrentDate = givenFutureOrCurrentDate.add(1, 'month')
        }
        logger.info(`givenFutureOrCurrentDate: ${givenFutureOrCurrentDate}`)
        return givenFutureOrCurrentDate.isAfter(todayDate, 'date') ? givenFutureOrCurrentDate.toDate() : this.getTomorrowJSDateInNycTz()
    },

    utcToTimeZone(inputDateTime: string | Date, outputTimezone = NYC_TIMEZONE, outputFormat = DISPLAY_DATE_FORMAT) {
        if (inputDateTime === null) {
            return null
        }
        const dateJsTime = dayjs.utc(inputDateTime, AVEN_DATE_TIME_FORMAT)
        const timeZone = dateJsTime.tz(outputTimezone)
        return timeZone.format(outputFormat)
    },

    convertDateTimeFormat(
        inputDateTime: string | Date,
        { inputDateTimeFormat = CORE_DATE_TIME_FORMAT, inputTimezone = NYC_TIMEZONE, outputTimezone = NYC_TIMEZONE, outputFormat = DISPLAY_DATE_FORMAT } = {}
    ) {
        if (inputDateTime === null) {
            return null
        }
        return dayjs.tz(dayjs(inputDateTime, inputDateTimeFormat), inputTimezone).tz(outputTimezone).format(outputFormat)
    },

    isCurrentYear(date) {
        return dayjs(date).tz(NYC_TIMEZONE).year() == dayjs().tz(NYC_TIMEZONE).year()
    },
}
