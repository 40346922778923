<template>
    <div
        v-if="showAlert"
        class="banner"
        data-testid="account-status-banner"
        :class="`banner-${alertName}`"
    >
        <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
            <div>
                <h5
                    class="banner-heading fw-bold mb-1"
                    data-testid="account-status-banner-title"
                >
                    {{ $t(`components.accountStatusBanner.${alertName}.title`) }}
                </h5>
                <p>{{ $t(`components.accountStatusBanner.${alertName}.description`, { amount: amountPastDue, limitedModeCreditLimit: toFormattedUSDNoCents(this.limitedModeCreditLimit) }) }}</p>
            </div>

            <div class="w-100 d-grid">
                <a
                    class="ms-md-auto"
                    :class="`btn btn-${alertName}`"
                    data-testid="account-status-banner-cta"
                    @click.prevent="btnOnClickHandler"
                >
                    {{ cta }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { AvenAccountStanding } from '@/store'
    import { mapGetters } from 'vuex'
    import { contact_info } from '@/utils/contact-info-dictionary'
    import { logger } from '@/utils/logger'
    import { i18n } from '@/utils/i18n'
    import { RouteNames } from '@/routes/router'
    import format from '@/mixins/format'

    const avenAccountStandingsForShowingAlerts = [
        AvenAccountStanding.delinquent,
        AvenAccountStanding.pastDue,
        AvenAccountStanding.overLimit,
        AvenAccountStanding.fraud,
        AvenAccountStanding.blocked,
        AvenAccountStanding.closed,
        AvenAccountStanding.pendingClose,
        AvenAccountStanding.pendingPayoffThenLimitedMode,
    ]

    export default {
        name: 'AccountStatusBanner',
        mixins: [format],
        computed: {
            showAlert: function () {
                logger.info(`Account Standing = ${this.accountStanding}`)
                if (this.accountStanding === AvenAccountStanding.unknown) {
                    return false
                }
                // show account closed or pendingClose banner everywhere
                if (
                    this.accountStanding === AvenAccountStanding.closed ||
                    this.accountStanding === AvenAccountStanding.pendingClose ||
                    this.accountStanding === AvenAccountStanding.pendingPayoffThenLimitedMode
                ) {
                    return true
                }
                const showOnRoute = ['activity', 'statements', 'card'].some((name) => name === this.$route.name.toLowerCase())

                return showOnRoute && avenAccountStandingsForShowingAlerts.includes(this.accountStanding)
            },
            alertName: function () {
                logger.info(`AccountStanding = ${this.accountStanding}`)
                if (avenAccountStandingsForShowingAlerts.includes(this.accountStanding)) {
                    return this.accountStanding
                }
                return ''
            },
            cta: function () {
                if (this.alertName === AvenAccountStanding.pendingClose) {
                    return i18n.t('components.accountStatusBanner.ctaViewDetails')
                } else if (this.alertName === AvenAccountStanding.pendingPayoffThenLimitedMode) {
                    return i18n.t('components.accountStatusBanner.ctaViewDetails')
                } else if (this.alertName === AvenAccountStanding.fraud || this.alertName === AvenAccountStanding.blocked) {
                    return i18n.t('pages.ReportLostOrStolen.CallUs')
                } else if (this.alertName !== AvenAccountStanding.closed) {
                    return i18n.t('components.accountStatusBanner.ctaPayNow')
                }
                return ''
            },
            ...mapGetters({
                accountStanding: 'avenAccountStanding',
                amountPastDue: 'minimumDue',
                limitedModeCreditLimit: 'limitedModeCreditLimit',
            }),
        },
        methods: {
            reportLostCall: function () {
                location.href = `tel:${contact_info.phone}`
            },
            btnOnClickHandler: function () {
                if (this.alertName === AvenAccountStanding.pendingClose || this.alertName === AvenAccountStanding.pendingPayoffThenLimitedMode) {
                    this.$router.push({
                        name: RouteNames.CLOSE_ACCOUNT_PAYOFF,
                    })
                } else if (this.alertName === AvenAccountStanding.fraud || this.alertName === AvenAccountStanding.blocked) {
                    this.reportLostCall()
                } else if (this.alertName !== AvenAccountStanding.closed && avenAccountStandingsForShowingAlerts.includes(this.accountStanding)) {
                    this.$router.push({
                        name: 'MakePayment',
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/banner';
</style>
