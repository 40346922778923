<template>
    <div id="app">
        <component
            v-if="HasLayout"
            :is="LayoutComponent"
            :navigation="LayoutNavigation"
            :key="LayoutComponent.name || LayoutComponent.__file"
        >
            <transition
                name="fade"
                mode="out-in"
            >
                <router-view :key="$route.fullPath" />
            </transition>
        </component>
        <div
            id="modalBackdrop"
            class="modal-backdrop"
            style="display: none"
        />
    </div>
</template>

<script>
    export default {
        name: 'App',
        computed: {
            HasLayout: function () {
                return this.$route.meta.layout !== undefined
            },
            LayoutComponent: function () {
                if (this.$route.meta.layout === 'auth') {
                    return require('@/layouts/Auth').default
                } else {
                    return require('@/layouts/Dashboard').default
                }
            },
            LayoutNavigation: function () {
                return this.$route.meta.navigation || ''
            },
        },
    }
</script>

<style lang="scss">
    @import 'styles/main.scss';
</style>
