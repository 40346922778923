export const SPANISH_TRANSLATIONS = {
    pages: {
        statements: {
            title: 'Statements',
        },
    },
    global: {
        // Page not found
        pageNotFound: {
            header: 'Page not found',
            subHeader: 'Sorry, but the page you were looking for does not seem to exist. To apply, visit the link below.',
            cta: 'Get started',
        },
        loadingMessage: {
            loading: 'Loading...',
            verifying: 'Verifying...',
            saving: 'Saving...',
        },
        cta: {
            continue: 'Continue',
            next: 'Next',
        },
        errors: {
            generic: 'Sorry, there was a problem with the network. Please try again. thanks',
            confirmAddress: 'Could not confirm address. Please check your entry',
        },
    },
    customValidationRules: {
        twilioCode: 'Code must be exactly 4 digits',
        notValidPhoneNumber: 'Not a valid phone number',
        confirmNoBusinessAccountManualCashout: 'Business accounts are currently not supported via a manual upload. Please use a personal checking or savings account or use "link account" instead.',
    },
    validation: {
        alpha: 'May only contain alphabetic characters',
        alpha_num: 'May only contain alpha-numeric characters',
        alpha_dash: 'May contain alpha-numeric characters as well as dashes and underscores',
        alpha_spaces: 'May only contain alphabetic characters as well as spaces',
        between: 'Must be between {min} and {max}',
        confirmed: 'Confirmation does not match',
        digits: 'Must be numeric and exactly contain {length} digits',
        dimensions: 'Must be {width} pixels by {height} pixels',
        email: 'Must be a valid email',
        excluded: 'Is not a valid value',
        ext: 'Is not a valid file',
        image: 'Must be an image',
        integer: 'Must be an integer',
        length: 'Must be {length} characters long',
        max_value: 'Must be {max} or less',
        max: 'May not be greater than {length} characters',
        mimes: 'Must have a valid file type',
        min_value: 'Must be {min} characters or more',
        min: 'Must be at least {length} characters',
        numeric: 'May only contain numeric characters',
        oneOf: 'Is not a valid value',
        regex: 'Format is invalid',
        required_if: 'This field is required',
        required: 'This field is required',
        size: 'Size must be less than {size}KB',
    },
}
