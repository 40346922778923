<template>
    <auth-layout
        :loading="loading"
        :loading-title="$t('global.loadingMessage.loading')"
    >
        <div class="container">
            <div class="row">
                <div class="col-12 col-md-6 offset-md-3 col-lg-4 offset-lg-4">
                    <img
                        src="../assets/images/global/aven.svg"
                        width="71"
                        class="mb-5"
                        alt="Aven"
                    >
                    <div
                        v-show="!askConfirmationCode"
                        data-testid="login-group"
                    >
                        <h5
                            class="mb-4"
                            v-html="$t('pages.login.signInTitle')"
                        />
                        <form-container
                            ref="phoneForm"
                            @onSubmit="requestOtpCode"
                            id="login-form"
                            data-testid="login-form"
                        >
                            <form-field-phone
                                ref="phoneField"
                                v-model="customerContactInfo"
                                name="phoneField"
                                classes="text-center"
                                class="mb-2"
                                input-type="number"
                                validation-rules="required|min:10|max:10|phoneNumberRule"
                                validation-mode="lazy"
                                :placeholder="$t('pages.login.phonePlaceholder')"
                                data-testid="login-form_phone-input"
                            />
                            <base-button
                                type="submit"
                                :submitting="submitting"
                                data-testid="login-form_submit"
                            >
                                {{ $t('pages.login.signInOrActivate') }}
                            </base-button>
                        </form-container>
                    </div>
                    <div
                        v-show="askConfirmationCode"
                        data-testid="confirmation-group"
                    >
                        <h5
                            class="mb-4"
                            v-html="verifyCodeMessage"
                        />
                        <form-container
                            ref="confirmPhoneForm"
                            @onSubmit="verifyOtpCode"
                            data-testid="confirm-form"
                        >
                            <form-field
                                ref="confirmationCode"
                                v-model="confirmationCode"
                                name="confirmationCode"
                                validation="required|twilioCode|min:6|max:6"
                                input-type="tel"
                                inputmode="decimal"
                                classes="text-center"
                                autocomplete="new-password"
                                :max-length="twilioCodeLength"
                                class="mb-2"
                                :placeholder="$t('pages.login.codePlaceholder')"
                                data-testid="confirm-form_code-input"
                            />
                            <div class="mt-2 mb-2 text-start">
                                <a
                                    class="small text-muted"
                                    v-html="$t('pages.login.appConsent')"
                                />
                            </div>
                            <base-button
                                :submitting="submitting"
                                type="submit"
                                data-testid="confirm-form_submit"
                            >
                                {{ $t('pages.login.continue') }}
                            </base-button>
                        </form-container>
                    </div>
                    <div class="other-options mt-2">
                        <div v-if="!biometricsEnabled || askConfirmationCode">
                            <button>
                                <a
                                    class="btn btn-tertiary"
                                    @click="showHelpModal"
                                >{{ $t('pages.login.Help') }}</a>
                            </button>
                        </div>
                        <div v-else>
                            <button @click="unlockUsingBiometrics">
                                {{ $t('pages.login.biometrics') }}
                            </button>
                            &nbsp;•&nbsp;
                            <button @click="showHelpModal">
                                {{ $t('pages.login.Help') }}
                            </button>
                        </div>
                    </div>
                </div>
                <p class="small text-muted text-center mt-2">
                    {{ deviceInfo.appVersion }}
                </p>
            </div>

            <modal
                :title="$t('pages.login.LoginHelp')"
                :show="helpModalVisibility && askConfirmationCode"
                @close="helpModalVisibility = false"
            >
                <p class="text-center mb-3">
                    {{ $t('pages.login.helpDescription') }}
                </p>
                <base-button
                    class="mt-3"
                    @click="resendCode"
                    button-classes="btn btn-secondary"
                >
                    {{ $t('pages.login.resendCode') }}
                </base-button>
                <base-button
                    class="mt-3"
                    @click="sendOtpToEmail"
                    button-classes="btn btn-secondary"
                >
                    {{ $t('pages.login.sendToEmail') }}
                </base-button>
                <base-button
                    class="mt-2"
                    @click="changeNumber"
                    button-classes="btn btn-secondary"
                >
                    {{ $t('pages.login.ChangeNumber') }}
                </base-button>
            </modal>
            <help
                :title="$t('pages.login.loginHelpModal.title')"
                :visibility="helpModalVisibility && !askConfirmationCode"
                @close="helpModalVisibility = false"
            />
        </div>
    </auth-layout>
</template>

<script lang="js">
    import LogRocket from 'logrocket';
    import { appSessionStorage, localStorageKey } from "@/utils/storage";
    import { helpModalMixin } from '@/mixins/helpModalMixin'
    import AuthLayout from "@/layouts/Auth"
    import FormFieldPhone from "@/components/base/FormFieldPhone";
    import BaseButton from "@/components/base/BaseButton";
    import FormContainer from "@/components/base/FormContainer";
    import generic from "@/utils/generic";
    import {logger} from "@/utils/logger";
    import { session } from "@/utils/session";
    import {i18n} from "@/utils/i18n";
    import Modal from "@/components/Modal";
    import FormField from "@/components/base/FormField";
    import {
        requestOtpCode,
        verifyOtpCode,
    } from "@/services/avenAppApi";
    import {mapGetters, mapMutations} from "vuex";
    import biometricsPassCodeMixin, {AppBlockedError, BiometricsError} from "@/utils/biometricsPassCodeMixin";
    import inspect from "util-inspect";
    import {getDeviceInfo} from "@/utils/deviceUtils";
    import {getRootAndDeeplinkFromPath, RouteNames} from '@/routes/router'
    import {TWILIO_ERROR_TO_I18N_NAMES} from "@/data/constants";

    export default {
        components: {
            AuthLayout,
            BaseButton,
            FormContainer,
            FormFieldPhone,
            Modal,
            FormField,
        },
        mixins: [helpModalMixin, biometricsPassCodeMixin],
        data() {
            return {
                loading: false,
                customerContactInfo: "",
                displayContactInfo: "",
                customerEmail : "",
                customerPhoneNumber: "",
                confirmationCode: "",
                askConfirmationCode: false,
                submitting: false,
                biometricsLogin: false,
                sid: "",
                helpModalVisibility : false,
                twilioCodeLength: 6,
                showBiometricsPromptOnMount: false,
                RouteNames
            }
        },
        async created() {
            if (this.$route.query.bootstrapData) {
                logger.info(`Login.vue, created(), received queryParam bootStrapData: ${JSON.stringify(this.$route.query.bootstrapData)}`)
                this.setSingleWebView(true)
                this.setIsWebView(true)

                let bootStrapData = JSON.parse(this.$route.query.bootstrapData)
                this.setDeviceInfo(bootStrapData.deviceInfo)
                this.setDeviceCapabilities(bootStrapData.deviceCapabilities)
                appSessionStorage.setItem(localStorageKey.creditCardCustomerId, bootStrapData.creditCardCustomerId)
                this.showBiometricsPromptOnMount = bootStrapData.showBiometricsPrompt || false

                if (bootStrapData.deeplinkPath) {
                    const result = getRootAndDeeplinkFromPath(bootStrapData.deeplinkPath)
                    this.setCurrentRootPath(result.root)
                    this.updateDeeplinkPath(result.deeplink)
                }

                if (bootStrapData.sessionId && bootStrapData.sessionAccessJWT) {
                    await session.inflateSessionFromNative(this.deviceInfo, bootStrapData.sessionId, bootStrapData.sessionAccessJWT, bootStrapData.creditCardCustomerId)
                }
            } else {
                logger.info(`Login.vue mounted, isSingleWebView = false`)
                const info = await getDeviceInfo()
                this.setDeviceInfo(info)
                appSessionStorage.setItem(localStorageKey.deviceGuid, info.deviceGuid)
                logger.info(`App Mounted, set deviceInfo: ${JSON.stringify(info)}`)

                await session.sessionBegin()
                logger.info(`Login.vue, created(), no query params, ${JSON.stringify(this.$route.query)}`)
            }
            await this.initializeDeviceInfoAndCheckBlockStatus()
        },
        mounted() {
            this.$nextTick(() => this.$refs.phoneField.setFocus())
            logger.info(`Login.vue mounted`)
            if (this.isSingleWebView) {
                window.location.href = `/mounted?routeName=${RouteNames.LOGIN}`
            }
            if (this.showBiometricsPromptOnMount) {
                this.unlockUsingBiometrics()
            }
        },
        computed: {
            ...mapGetters(['biometricsEnabled', 'deviceInfo', 'isSingleWebView']),
            verifyCodeMessage() {
                return i18n.t('pages.login.codeHeadline', {contactInfo: this.displayContactInfo})
            },
        },
        methods: {
            ...mapMutations(['disableBiometrics', 'setDeviceInfo', 'setDeviceCapabilities', 'setSingleWebView', 'setIsWebView', 'setIsPasscodeSet', 'setCurrentRootPath', 'updateDeeplinkPath']),
            initializeDeviceInfoAndCheckBlockStatus: async function () {
                if (this.isSingleWebView) {
                    window.onBiometricsComplete = this.onBiometricsComplete
                    logger.info(`Login.vue mounted, isSingleWebView = true`)
                }

                const responseData = await session.checkBlockStatus(this.deviceInfo)
                if (responseData?.error === 'APP_BLOCKED_ERROR') {
                    console.log(responseData.payload)
                    await this.presentAppBlockedView(responseData.payload)
                    return
                }
            },
            requestOtpCode: async function () {
                const isValid = await this.$refs.phoneForm.$refs.observer.validate()
                if (!isValid) {
                    return
                }
                this.submitting = true
                this.clearGeneralError()
                session.id = ""

                const userEnteredEmail = generic.validEmail(this.customerContactInfo)
                this.customerPhoneNumber = userEnteredEmail ? null : this.customerContactInfo
                this.customerEmail = userEnteredEmail ? this.customerContactInfo : null

                await this.otpRequest(this.getOtpPayloadByDeliveryMethod(this.customerPhoneNumber ? 'sms' : 'email'))

            },
            otpRequest: async function (requestOtpPayload) {
                logger.info('Login: loginRequest() starting ' + JSON.stringify(requestOtpPayload))
                try {
                    await session.sessionBegin(this.customerPhoneNumber, this.customerEmail)
                    const response = await requestOtpCode(requestOtpPayload)
                    logger.info('otp request succeeded')
                    LogRocket.identify(appSessionStorage.getItem(localStorageKey.sessionId), {
                        creditCardCustomerId: appSessionStorage.getItem(localStorageKey.creditCardCustomerId),
                    })

                    if (response.data.success) {
                        const storageKey = requestOtpPayload.codeDeliveryMethod === 'sms' ? localStorageKey.displayPhoneNumber : localStorageKey.displayEmail
                        this.displayContactInfo = appSessionStorage.getItem(storageKey)
                        this.sid = response.data.payload.sid
                        this.askConfirmationCode = true;
                        this.submitting = false
                        this.$nextTick(() => this.$refs.confirmationCode.setFocus());
                    } else {
                        this.submitting = false
                        console.error(response.data.error)
                        if (response.data.error === "INVALID_US_PHONE_NUMBER_ERROR") {
                            this.applyGeneralErrorOnPhoneForm(i18n.t('pages.login.notValidPhoneNumber'))
                        } else if (response.data.error === "TWILIO_ERROR") {
                            // TODO: handle specific codes, response.data.twilioErrorCode
                            // twilio verify v2 lack documentation on commonly occurring codes.
                            // display generic error for now. will add as we encounter codes
                            // https://www.twilio.com/docs/api/errors
                            logger.info(`Error: /aven_app/2FARequestCode\n${JSON.stringify(response.data)}`)
                            this.applyGeneralErrorOnPhoneForm(i18n.t('global.errors.generic'))
                        }
                        logger.error(`Login request fail with :${response.data.error}`)
                    }
                } catch (error) {
                    this.submitting = false
                    if (error?.response?.data?.error === "ERROR_NOT_FOUND") {
                        appSessionStorage.clear()
                        this.applyGeneralErrorOnPhoneForm(i18n.t('pages.login.notfound', {phoneNumber: generic.formatPhoneNumber(this.customerPhoneNumber)}))
                    } else {
                        this.applyGeneralErrorOnPhoneForm(i18n.t('global.errors.generic'))
                    }
                }
            },
            verifyOtpCode: async function() {
                const isValid = await this.$refs.confirmPhoneForm.$refs.observer.validate()
                console.log(`loginAuth input is ${isValid}`)
                if (!isValid) {
                    return
                }
                try {
                    this.clearGeneralError()
                    this.submitting = true
                    logger.info(`Login button clicked`)
                    const payload = {
                        verificationCode: this.confirmationCode,
                        sid: this.sid,
                    }
                    const response = await verifyOtpCode(payload)
                    this.submitting = false
                    if (response.data.success) {
                        appSessionStorage.setItem(localStorageKey.accountPhoneNumber, this.customerPhoneNumber)
                        console.log("Phone number verification complete for ", this.customerPhoneNumber);
                        this.setIsPasscodeSet(response.data.payload.isPasscodeSet)
                        if (response.data.payload.isPasscodeSet) {
                            await this.$router.push({
                                name: RouteNames.PASSWORD_LOGIN
                            })
                        } else {
                            await this.$router.push({
                                name: RouteNames.SET_PASSWORD
                            })
                        }
                    } else {
                        this.handleErrorResponse(response.data)
                    }
                } catch (error) {
                    this.submitting = false
                    this.applyGeneralErrorOnConfirmPhoneForm(i18n.t('global.errors.generic'))
                }
            },
            handleErrorResponse(data) {
                logger.info(`verify otp response.data: ${JSON.stringify(data)}`)
                if (data.error === "TWILIO_2FA_INCORRECT_CODE") {
                    this.applyGeneralErrorOnConfirmPhoneForm(i18n.t('pages.login.verificationNotCorrect'))
                } else if (data.error === "ERROR_NOT_FOUND") {
                    this.applyGeneralErrorOnConfirmPhoneForm(i18n.t('pages.login.notfound'))
                } else if (data.error === "TWILIO_ERROR") {
                    // TODO: handle specific codes, response.data.twilioErrorCode
                    // twilio verify v2 lack documentation on commonly occurring codes.
                    // display generic error for now. will add as we encounter codes
                    // https://www.twilio.com/docs/api/errors
                    const twilioErrorCode = data.payload.twilioErrorCode

                    let errorMessage = i18n.t('twilioError.generic')
                    if (TWILIO_ERROR_TO_I18N_NAMES.has(twilioErrorCode)) {
                        errorMessage = i18n.t(TWILIO_ERROR_TO_I18N_NAMES.get(twilioErrorCode))
                    }
                    this.askConfirmationCode = false
                    this.applyGeneralErrorOnPhoneForm(errorMessage)

                } else {
                    logger.error(`Unexpected error :${data.error}`)
                    throw new Error(data.error)
                }
            },
            applyGeneralErrorOnPhoneForm (errorMessage){
                logger.info("applyGeneralErrorOnPhoneForm")
                this.$nextTick(() => this.$refs.phoneForm.applyGeneralError(errorMessage))
            },
            applyGeneralErrorOnConfirmPhoneForm(errorMessage){
                logger.info(`applyGeneralErrorOnConfirmPhoneForm: ${errorMessage}`)
                this.$nextTick(() => this.$refs.confirmPhoneForm.applyGeneralError(errorMessage))
            },
            clearGeneralError() {
                logger.info("clearGeneralError")
                this.$refs.phoneForm.clearErrorMessage()
                this.$refs.confirmPhoneForm.clearErrorMessage()
            },
            showHelpModal(){
                //there are two help modals on this screen
                // call support help modal when screen is in phone number input state
                // when screen is in otp verification state, a modal with {resend, send to email, change phone}
                this.helpModalVisibility = true
            },
            async resendCode(){
                logger.info('Login: resendCode() starting')
                this.helpModalVisibility = false
                await this.otpRequest(this.getOtpPayloadByDeliveryMethod(this.customerPhoneNumber ? 'sms' : 'email'))
                logger.info('Login: resendCode() finished')
            },
            changeNumber(){
                this.customerContactInfo = ''
                this.customerPhoneNumber = ''
                this.customerEmail = ''
                this.$refs.phoneField.clearValue()
                this.$refs.phoneForm.clearErrorMessage()
                this.helpModalVisibility = false
                this.askConfirmationCode = false
                appSessionStorage.clear()
            },
            sendOtpToEmail() {
                this.submitting = true
                this.otpRequest(this.getOtpPayloadByDeliveryMethod('email'))
                this.helpModalVisibility = false
            },
            presentAppBlockedView: function (payload) {
                console.log(`appBlocked config: ${JSON.stringify(payload)}`)
                let { blockEvent } = payload
                if (!blockEvent) {
                    blockEvent = payload
                }
                if (payload.url) {
                    window.location.href = blockEvent.url
                } else {
                    this.$router.push({
                        name: "AppBlocked",
                        params: {
                            title: blockEvent.title,
                            description: blockEvent.description,
                            buttonText: blockEvent.buttonText,
                            buttonActionUrl: blockEvent.buttonActionUrl,
                        }
                    })
                }
            },
            getOtpPayloadByDeliveryMethod: function(deliveryMethod) {
                logger.info(`get otp payload by delivery method: ${deliveryMethod}`)
                if (deliveryMethod === 'sms') {
                    return {
                        phoneNumber: this.customerPhoneNumber,
                        deviceGuid: this.deviceInfo.deviceGuid,
                        codeDeliveryMethod : 'sms'
                    }
                } else {
                    return {
                        email: this.customerEmail ?? appSessionStorage.getItem(localStorageKey.displayEmail),
                        deviceGuid: this.deviceInfo.deviceGuid,
                        codeDeliveryMethod : 'email'
                    }
                }
            },
            // ==== invoked by native code ====
            unlockUsingBiometrics: function() {
                window.location.href = "/biometrics?callBack=onBiometricsComplete"
            },
            /*
            handles successful bimoetrics login and error cases from native side.
            biometricsResult {
                passcode?: string
                errorCode: string
            }
             */
            // ==== invoked by native code ====
            onBiometricsComplete: async function(jsonString) {
                console.info(`onBiometricsComplete: ${jsonString}`)
                const biometricsResult = JSON.parse(jsonString)
                if (biometricsResult.errorCode && biometricsResult.errorCode !== BiometricsError.cancelledByUser) {
                    this.disableBiometrics()
                    this.applyGeneralErrorOnPhoneForm(i18n.t("global.errors.biometricsGenericError"))
                    return
                }

                if (!biometricsResult.errorCode) {
                    this.inflateFromNativeBiometricsResult(biometricsResult)
                    await this.loginOnSuccessfulBiometrcs(biometricsResult.passcode)
                }
            },
            loginOnSuccessfulBiometrcs: async function(password) {
                try {
                    this.submitting = true
                    await this.loginWithPassword(password)
                    console.log('loginOnSuccessfulBiometrcs')
                    await this.getAccountOverview()
                } catch (error) {
                    if (error instanceof AppBlockedError) {
                        console.info(`loginOnSuccessfulBiometrics AppBlockedError`)
                        await this.presentAppBlockedView(error.blockEvent)
                    } else if (error.response?.status === 400) {
                        console.info(`loginOnSuccessfulBiometrics 400`)
                        this.disableBiometrics()
                        window.location.href = "/disableBiometrics"
                    } else if (error.response?.status === 401) {
                        console.info(`loginOnSuccessfulBiometrics 401`)

                        if (error.response.data.error === 'INCORRECT_PASSCODE_ERROR') {
                            this.applyGeneralErrorOnPhoneForm(i18n.t("pages.passwordLogin.error.incorrectPassword"))
                            window.location.href = "/disableBiometrics"
                            this.disableBiometrics()
                        } else {
                            appSessionStorage.clear()
                            session.id = ''
                            await this.$router.replace('/login')
                        }
                    } else if (error.response?.status === 404) {
                        console.info(`loginOnSuccessfulBiometrics 404`)
                        this.applyGeneralErrorOnPhoneForm(i18n.t("pages.passwordLogin.error.accountNotFound"))
                    } else {
                        console.info(`loginOnSuccessfulBiometrics ${inspect(error)}`)
                        this.applyGeneralErrorOnPhoneForm(i18n.t("global.errors.generic"))
                    }
                } finally {
                    this.submitting = false
                }
            },
        }
    };
</script>

<style lang="scss" scoped>
    @import '../styles/components/appBase';
    @import '../styles/components/appHeader.scss';

    .form-group {
        margin-bottom: 0;
    }

    .other-options {
        font-size: $font-size-base;
        color: $black;
        display: flex;
        justify-content: center;
        align-items: center;
        height: $input-height;
        button {
            background: none;
            border: none;
            font-weight: $font-weight-bold;
            color: $black;
            &:first-child {
                margin-right: 4px;
            }
            &:last-child {
                margin-left: 4px;
            }
        }
    }
</style>
