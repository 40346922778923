<template>
    <div class="d-grid">
        <button
            :class="buttonClasses"
            :type="type"
            v-bind="$attrs"
            v-on="$listeners"
            :disabled="submitting || disabled"
            :data-testid="dataTestid"
        >
            <img
                v-show="imageSrc"
                class="me-1"
                width="16"
                :src="getImageSrc"
                alt=""
            >
            <span
                v-if="submitting"
                class="spinner-border spinner-border-sm"
            />
            <span v-else><slot /></span>
        </button>
    </div>
</template>

<script>
    export default {
        name: 'BaseButton',
        props: {
            type: {
                type: String,
                default: 'submit',
            },
            submitting: {
                type: Boolean,
                default: false,
            },
            imageSrc: {
                type: String,
                default: null,
            },
            showIcon: {
                type: Boolean,
                default: true,
            },
            buttonClasses: {
                type: String,
                default: 'btn btn-primary',
            },
            disabled: {
                type: Boolean,
                default: false,
            },
            dataTestid: {
                type: String,
                required: false,
            },
        },
        computed: {
            getImageSrc() {
                if (!this.imageSrc) {
                    return null
                }

                return require(`../../assets/images/${this.imageSrc}`)
            },
        },
    }
</script>
