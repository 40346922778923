<template>
    <div>
        <app-header
            title="Account"
            class="mobile-header"
        />
        <div class="container px-0 px-md-3">
            <card-block
                class="mb-2"
                title="Home Card"
            >
                <list-row
                    :title="$t('pages.card.AccountDetails')"
                    :show-arrow="true"
                    @onClick="$router.push('accountDetails')"
                    row-icon="images/pages/card/Pen.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    v-show="!accountIsClosed"
                    :title="$t('pages.card.BalanceTransfer')"
                    :show-arrow="true"
                    @onClick="$router.push('balanceTransfer')"
                    row-icon="images/pages/card/BalanceTransfer.svg"
                    class="mx-0 px-0"
                />
                <feature-flag
                    name="cashOut"
                    :active="false"
                >
                    <list-row
                        v-show="showCashOutFeature && !accountIsClosed"
                        :title="$t('pages.card.CashOut')"
                        :show-arrow="true"
                        @onClick="$router.push('cashOut')"
                        row-icon="images/pages/card/Cash.svg"
                        data-testid="cash-out-item"
                        class="mx-0 px-0"
                    />
                </feature-flag>
                <list-row
                    v-show="isLoyaltyProduct"
                    :title="$t('pages.card.Rewards')"
                    :show-arrow="true"
                    @onClick="$router.push('rewards')"
                    row-icon="images/pages/card/Rewards.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    :title="$t('pages.card.MakeAPayment')"
                    :show-arrow="true"
                    @onClick="$router.push('makePayment')"
                    row-icon="images/pages/card/Payment.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    :title="$t('pages.card.AutoPay')"
                    :show-arrow="true"
                    @onClick="$router.push('autoPay')"
                    row-icon="images/pages/card/AutoPay.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    v-show="!cardRequiresActivation && !accountIsClosed"
                    :title="$t('pages.card.ReportLostOrStolen')"
                    :show-arrow="true"
                    @onClick="$router.push('reportLostOrStolen')"
                    row-icon="images/pages/card/Location.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    :title="$t('pages.card.Pif')"
                    :show-arrow="true"
                    @onClick="goToPayItForward"
                    row-icon="images/pages/card/PayItForward.svg"
                    class="mx-0 px-0"
                />
            </card-block>

            <card-block
                v-show="isCryptoProduct"
                title="Crypto"
                class="mb-2"
            >
                <list-row
                    :title="$t('pages.card.DepositCrypto')"
                    :show-arrow="true"
                    @onClick="$router.push('depositCrypto')"
                    row-icon="images/pages/card/Coin.svg"
                />
            </card-block>

            <card-block title="Settings">
                <list-row
                    :title="$t('pages.accountDetails.PersonalDetails')"
                    show-arrow
                    @onClick="$router.push('PersonalDetails')"
                    row-icon="images/pages/card/Pen.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    v-show="!accountIsClosed"
                    :title="$t('pages.accountDetails.PaymentSources')"
                    show-arrow
                    @onClick="$router.push('paymentSource')"
                    row-icon="images/pages/card/BankAccount.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    :title="$t('pages.accountDetails.NotificationSettings')"
                    show-arrow
                    @onClick="$router.push('notificationSetting')"
                    row-icon="images/pages/card/Bell.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    v-show="!isWebView && hasTaxDocumentsToView"
                    :title="$t('pages.taxDocuments.title')"
                    show-arrow
                    @onClick="$router.push('taxDocuments')"
                    row-icon="images/pages/card/DocumentMoney.svg"
                    class="mx-0 px-0"
                />
                <list-row
                    :title="$t('pages.card.LogOut')"
                    :show-arrow="true"
                    @onClick="logout"
                    href="logoutAvenCustomer"
                    row-icon="images/pages/card/Logout.svg"
                    class="mx-0 px-0"
                />
            </card-block>
        </div>
    </div>
</template>

<script>
    import Header from '../components/AppHeader'
    import ListRow from '@/components/ListRow'
    import { mapActions, mapGetters } from 'vuex'
    import FeatureFlag from '@/components/FeatureFlag'
    import { RoutePaths } from '@/routes/router'
    import CardBlock from '@/components/CardBlock'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import goToPayItForward from '@/mixins/goToPayItForward'

    export default {
        name: 'Card',
        components: {
            CardBlock,
            ListRow,
            'app-header': Header,
            FeatureFlag,
        },
        mixins: [goToPayItForward],
        computed: {
            ...mapGetters(['isLoyaltyProduct', 'cardRequiresActivation', 'showCashOutFeature', 'accountIsClosed', 'isCryptoProduct', 'isWebView', 'taxDocuments']),
            hasTaxDocumentsToView: function () {
                return this.taxDocuments.length > 0
            },
        },
        mounted: function () {
            this.$logEvent('view_card')
            // for consistency since app's will attempt to invoke this method when view is foregrounded.
            window.refreshData = this.refreshData
            this.loadTaxDocuments()
        },
        methods: {
            ...mapActions(['updateAccountOverview', 'getExistingBalanceTransfers']),
            // for consistency since app's will attempt refresh when view is foregrounded.
            refreshData: async function () {
                await this.updateAccountOverview()
                await this.getExistingBalanceTransfers()
            },
            logout: function () {
                this.$logEvent('click_logout')
                this.$router.push(RoutePaths.LOGOUT)
            },
            loadTaxDocuments: async function () {
                if (this.taxDocuments.length === 0) {
                    try {
                        await this.$store.dispatch('getTaxDocuments')
                    } catch (error) {
                        ApiErrorHandler(error)
                    }
                }
                logger.info(`${JSON.stringify(this.taxDocuments)}`)
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/appBase';
</style>
