/**
 * App Storage class
 * @description This will be responsible for storing data into the application.
 * Commonly, people use LocalStorage or SessionStorage. This is just a wrapper over them
 * because to restrict the usage of Global window storage throughtout the application
 * Default, this is just using the LocalStorage
 */
import { logger } from '@/utils/logger'

class AppStorage {
    constructor(private readonly storage: Storage) {
        this.storage = storage || window.localStorage

        /** Is storage is supported or not */
        if (!this.isSupported()) {
            throw new Error('Storage is not supported by browser!')
        }
    }

    setItem(key: string, value: string) {
        this.storage.setItem(key, value)
    }

    getItem(key: string) {
        return this.storage.getItem(key)
    }

    removeItem(key: string) {
        this.storage.removeItem(key)
    }

    clear() {
        logger.info(`clear session storage`)
        this.storage.clear()
    }

    /**
     * @description Check for storage support
     * @returns {boolean} supported
     * * */
    isSupported() {
        let supported = true

        if (!this.storage) {
            supported = false
        }

        return supported
    }
}

/**
 * Creating the instance of storage. Default will be localStorage
 * but if you want to create instance for session storage then pass window.sessionStorage as parameter
 */
const appLocalStorage = new AppStorage(window.localStorage)
const appSessionStorage = new AppStorage(window.sessionStorage)

const localStorageKey = {
    sessionId: 'sessionId',
    sessionAccessJWT: 'sessionAccessJWT',
    applicantId: 'applicantId',
    loanApplicationId: 'loanApplicationId',
    coreCardCustomerId: 'coreCardCustomerId',
    creditCardCustomerId: 'creditCardCustomerId',
    displayPhoneNumber: 'displayPhoneNumber',
    accountPhoneNumber: 'accountPhoneNumber',
    displayEmail: 'displayEmail',
    jwtToken: 'jwtToken',
    tokenId: 'token-id',
    fundingSourceSubType: 'fundingSourceSubType',
    fundingNickName: 'fundingNickName',
    autopayCategory: 'payCategory',
    maskCard: 'maskCard',
    deviceGuid: 'deviceGuid',
    platform: 'platform',
    completedAccounts: 'completedAccounts',
    logRocketUrl: 'logRocketUrl',
    routerLog: 'routerLog',
    nativeInjectionLog: 'nativeInjectionLog',
}

export enum platformType {
    android = 'android',
    web = 'web',
    iOS = 'iOS',
}

const saveDebugLog = (key: string, log: string) => {
    const logs = appSessionStorage.getItem(key) ? JSON.parse(appSessionStorage.getItem(key)) : []
    logs.push(log)
    appSessionStorage.setItem(key, JSON.stringify(logs))
}

export { appLocalStorage, appSessionStorage, localStorageKey, saveDebugLog }
