<template>
    <div class="card p-3 p-md-4">
        <h5
            v-if="title"
            class="fw-bold mb-2"
        >
            {{ title }}
        </h5>
        <slot />
    </div>
</template>

<script>
    export default {
        name: 'CardBlock',
        props: {
            title: {
                type: String,
                required: false,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/cardBlock';
</style>
