import Vue from 'vue'
import VueRouter, { NavigationGuardNext, Route, RouteConfig } from 'vue-router'
import { appSessionStorage, localStorageKey, saveDebugLog } from '@/utils/storage'
import Login from '@/views/Login.vue'
import Card from '@/views/Card.vue'
import Activity from '@/views/Activity.vue'
import Statements from '@/views/Statements.vue'
import { logger } from '@/utils/logger'
import store from '@/store'

Vue.use(VueRouter)

// enums cannot be used in templates so they are only for
// this file for now.
// TODO: import https://github.com/davestewart/vue-enums
export enum RouteNames {
    SPLASH = 'Splash',
    LOGIN = 'Login',
    ACTIVITY = 'Activity',
    STATEMENTS = 'Statements',
    TAX_DOCUMENTS = 'TaxDocuments',
    CARD = 'Card',
    LOGOUT = 'Logout',
    PAY_IT_FORWARD = 'PayItForward',
    PAY_IT_FORWARD_CONTACT_LIST = 'PayItForwardContactList',
    PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT = 'pifSignOnBonusAgent',
    PAY_IT_FORWARD_SEND_TIP = 'PayItForwardSendTip',
    CASH_OUT_BY_ID = 'CashOutById',
    PASSWORD_LOGIN = 'PasswordLogin',
    SET_PASSWORD = 'SetPassword',
    BALANCE_TRANSFER = 'BalanceTransfer',
    CASH_OUT = 'CashOut',
    REPLACE_CARD = 'ReplaceCard',
    BALANCE_TRANSFER_BY_ID = 'BalanceTransferById',
    PERSONAL_DETAILS = 'PersonalDetails',
    CLOSE_ACCOUNT_IN_RESCISSION = 'CloseAccountInRescissionPeriod',
    RECURRING_BT_SELECT_CARDS = 'RecurringBtSelectCards',
    RECURRING_BT_TRANSFER_AMOUNT = 'RecurringBtTransferAmount',
    RECURRING_BT_REQUESTED = 'RecurringBtRequested',
    RECURRING_BT_INFORMATION = 'RecurringBtInformation',
    PROMO_SETUP_AUTOPAY_CREDIT = 'PromoSetupAutoPayCredit',
    SETUP_AUTOPAY = 'SetupAutoPay',
    AUTO_PAY = 'AutoPay',
    ENROLL_IN_AUTOPAY = 'EnrollInAutoPay',
    DEPOSIT_CRYPTO = 'DepositCrypto',
    CLOSE_ACCOUNT = 'CloseAccount',
    CLOSE_ACCOUNT_PERMANENT = 'CloseAccountPermanent',
    CLOSE_ACCOUNT_KEEP_CARD = 'CloseAccountKeepCard',
    CLOSE_ACCOUNT_PAYOFF = 'CloseAccountPayoff',
    CLOSE_ACCOUNT_EMAIL_PAYOFF = 'CloseAccountEmailPayoff',
    CLOSE_ACCOUNT_WIRE = 'CloseAccountWire',
    CARD_ARRIVAL_CHECK = 'CardArrivalCheck',
}

export enum RoutePaths {
    SPLASH = '/splash',
    ROOT = '/',
    LOGIN = '/login',
    ACTIVITY = '/activity',
    STATEMENTS = '/statements',
    TAX_DOCUMENTS = '/taxDocuments',
    CARD = '/card',
    LOGOUT = '/logoutAvenCustomer',
    PAY_IT_FORWARD = '/payItForward',
    PAY_IT_FORWARD_CONTACT_LIST = '/payItForwardContactList',
    PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT = '/payItForwardAgent',
    PAY_IT_FORWARD_SEND_TIP = '/payItForwardSendTip',
    CASH_OUT_BY_ID = '/cashOut/:id',
    PASSWORD_LOGIN = '/passwordLogin',
    SET_PASSWORD = '/setPassword',
    BALANCE_TRANSFER = '/balanceTransfer',
    CASH_OUT = '/cashOut',
    REPLACE_CARD = '/replaceCard',
    BALANCE_TRANSFER_BY_ID = '/balanceTransfer/:id',
    PERSONAL_DETAILS = '/personalDetails',
    CLOSE_ACCOUNT_IN_RESCISSION = '/closeAccountInRescission',
    RECURRING_BT_SELECT_CARDS = '/balanceTransfer/recurring/selectCards',
    RECURRING_BT_TRANSFER_AMOUNT = '/balanceTransfer/recurring/transferAmount/:token',
    RECURRING_BT_REQUESTED = '/balanceTransfer/recurring/transferRequested',
    RECURRING_BT_INFORMATION = '/balanceTransfer/recurring/information',
    PROMO_SETUP_AUTOPAY_CREDIT = '/promo/autopaycredit',
    SETUP_AUTOPAY = '/setupAutoPay', // shows 0.25% APR discount promotion
    AUTO_PAY = '/autoPay', // uses MakePayment.vue but only show auto pay tab. tab selection is hidden
    ENROLL_IN_AUTOPAY = '/enrollInAutoPay', // uses MakePayment.vue, ui is configured same as AUTO_PAY but showing Close button instead of Back
    DEPOSIT_CRYPTO = '/depositCrypto',
    CLOSE_ACCOUNT = '/closeAccount', // the prompt page for close account, asking the reason of closing
    CLOSE_ACCOUNT_PERMANENT = '/closeAccount/permanent', // the notice page for close account
    CLOSE_ACCOUNT_KEEP_CARD = '/closeAccount/keepCard', // shows the option of keeping the card instead of permanent close
    CLOSE_ACCOUNT_PAYOFF = '/closeAccount/payoff', // shows the payoff quote information
    CLOSE_ACCOUNT_EMAIL_PAYOFF = '/closeAccount/emailPayoff', // email the payoff quote
    CLOSE_ACCOUNT_WIRE = '/closeAccount/wire', // shows the wire transfer information and the CTA to block the account (i.e. put the account into pending-close)
    CARD_ARRIVAL_CHECK = '/cardArrivalCheck',
}

// All crypto routes should be added to this Array
export const CryptoRoutePaths = [RoutePaths.DEPOSIT_CRYPTO]

const routes: Array<RouteConfig> = [
    {
        path: RoutePaths.ROOT,
        redirect: { name: 'Login' },
        meta: {
            public: true,
        },
    },
    {
        path: RoutePaths.LOGIN,
        name: RouteNames.LOGIN,
        component: Login,
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: RoutePaths.CARD,
        name: RouteNames.CARD,
        component: Card,
        meta: {
            layout: 'dashboard',
            navigation: 'card',
        },
    },
    {
        path: RoutePaths.STATEMENTS,
        name: RouteNames.STATEMENTS,
        component: Statements,
        meta: {
            layout: 'dashboard',
            navigation: 'statements',
        },
    },
    {
        path: RoutePaths.ACTIVITY,
        name: RouteNames.ACTIVITY,
        component: Activity,
        meta: {
            layout: 'dashboard',
            navigation: 'activity',
        },
    },
    {
        path: RoutePaths.SET_PASSWORD,
        name: RouteNames.SET_PASSWORD,
        component: () => import('@/views/SetPassword.vue'),
        meta: {
            layout: 'auth',
            navigation: RouteNames.SET_PASSWORD,
            allowedWithSessionJwt: true,
        },
    },
    {
        path: RoutePaths.PASSWORD_LOGIN,
        name: RouteNames.PASSWORD_LOGIN,
        component: () => import('@/views/PasswordLogin.vue'),
        props: true,
        meta: {
            layout: 'auth',
            navigation: RouteNames.PASSWORD_LOGIN,
            allowedWithSessionJwt: true,
        },
    },
    {
        path: '/cardArrivalCheck',
        name: 'CardArrivalCheck',
        component: () => import('@/views/CardArrivalCheck.vue'),
        meta: {
            layout: 'auth',
            navigation: 'CardArrivalCheck',
        },
    },
    {
        path: '/cardActivation',
        name: 'CardActivation',
        component: () => import('@/views/CardActivation.vue'),
        meta: {
            layout: 'auth',
            navigation: 'CardActivation',
        },
    },
    {
        path: '/paymentSourceForm',
        name: 'PaymentSourceForm',
        component: () => import('@/views/PaymentSourceForm.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/paymentSourceDetail',
        name: 'PaymentSourceDetail',
        component: () => import('@/views/PaymentSourceDetail.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/makePayment',
        name: 'MakePayment',
        component: () => import('@/views/MakePayment.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.AUTO_PAY,
        name: RouteNames.AUTO_PAY,
        component: () => import('@/views/MakePayment.vue'),
        props: { configureAutoPay: true },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SETUP_AUTOPAY,
        name: RouteNames.SETUP_AUTOPAY,
        component: () => import('@/views/SetupAutoPay.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.ENROLL_IN_AUTOPAY,
        name: RouteNames.ENROLL_IN_AUTOPAY,
        component: () => import('@/views/MakePayment.vue'),
        props: { configureAutoPay: true, isModal: true },
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.DEPOSIT_CRYPTO,
        name: RouteNames.DEPOSIT_CRYPTO,
        component: () => import('@/views/DepositCrypto.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/reportLostOrStolen',
        name: 'ReportLostOrStolen',
        component: () => import('@/views/ReportLostOrStolen.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.REPLACE_CARD,
        name: RouteNames.REPLACE_CARD,
        component: () => import('@/views/ReplaceCard.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/notificationSetting',
        name: 'NotificationSetting',
        component: () => import('@/views/NotificationSetting.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.TAX_DOCUMENTS,
        name: RouteNames.TAX_DOCUMENTS,
        component: () => import('@/views/TaxDocuments.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/avenPrimeSetting',
        name: 'AvenPrimeSettings',
        component: () => import('@/views/AvenPrimeSettings.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/accountDetails',
        name: 'AccountDetails',
        component: () => import('@/views/AccountDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PERSONAL_DETAILS,
        name: RouteNames.PERSONAL_DETAILS,
        component: () => import('@/views/PersonalDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/paymentSource',
        name: 'PaymentSource',
        component: () => import('@/views/PaymentSource.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/activateCard',
        name: 'ActivateCard',
        component: () => import('@/views/ActivateCard.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/cardHolderDetails',
        name: 'CardHolderDetails',
        component: () => import('@/views/CardHolderDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/statement/:id',
        name: 'StatementId',
        component: () => import('@/views/StatementId.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.SPLASH,
        name: RouteNames.SPLASH,
        component: () => import('@/views/Splash.vue'),
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: '/error',
        name: 'Error',
        component: () => import('@/views/Error.vue'),
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: '/appBlocked',
        name: 'AppBlocked',
        component: () => import('@/views/AppBlocked.vue'),
        meta: {
            public: true,
            layout: 'auth',
        },
    },
    {
        path: '/unfreezeCard',
        name: 'UnfreezeCard',
        component: () => import('@/views/UnfreezeCard.vue'),
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/balanceTransfer/promo/:promoName',
        name: 'BalanceTransferPromo',
        component: () => import('@/views/BalanceTransferPromo.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.BALANCE_TRANSFER,
        name: RouteNames.BALANCE_TRANSFER,
        component: () => import('@/views/BalanceTransfer.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BT_SELECT_CARDS,
        name: RouteNames.RECURRING_BT_SELECT_CARDS,
        component: () => import('@/views/recurringBalanceTransfer/SelectRecurringBalanceTransferCards.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BT_TRANSFER_AMOUNT,
        name: RouteNames.RECURRING_BT_TRANSFER_AMOUNT,
        component: () => import('@/views/recurringBalanceTransfer/SelectRecurringBalanceTransferAmount.vue'),
        // We expect a 'token' query param
        props: true,
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BT_REQUESTED,
        name: RouteNames.RECURRING_BT_REQUESTED,
        component: () => import('@/views/recurringBalanceTransfer/RecurringBalanceTransferRequested.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.RECURRING_BT_INFORMATION,
        name: RouteNames.RECURRING_BT_INFORMATION,
        component: () => import('@/views/recurringBalanceTransfer/RecurringBalanceTransferInformation.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.LOGOUT,
        name: RouteNames.LOGOUT,
        component: () => import('@/views/Logout.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: '/rewards',
        name: 'Rewards',
        component: () => import('@/views/Rewards.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT,
        name: RouteNames.CASH_OUT,
        component: () => import('@/views/CashOut.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CASH_OUT_BY_ID,
        name: RouteNames.CASH_OUT_BY_ID,
        component: () => import('@/views/CashOutById.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.BALANCE_TRANSFER_BY_ID,
        name: RouteNames.BALANCE_TRANSFER_BY_ID,
        component: () => import('@/views/BalanceTransferById.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAY_IT_FORWARD,
        name: RouteNames.PAY_IT_FORWARD,
        component: () => import('@/views/PayItForward.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAY_IT_FORWARD_CONTACT_LIST,
        name: RouteNames.PAY_IT_FORWARD_CONTACT_LIST,
        component: () => import('@/views/PayItForwardContactList.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT,
        name: RouteNames.PIF_SIGN_ON_BONUS_REAL_ESTATE_AGENT,
        component: () => import('@/views/SignOnBonusRealEstateAgent.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.PAY_IT_FORWARD_SEND_TIP,
        name: RouteNames.PAY_IT_FORWARD_SEND_TIP,
        component: () => import('@/views/PayItForwardSendTip.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        // Always goes back to activity, so when used as a deeplink, must
        // be, e.g., my.aven.com/activity/promo/amazon50CreditFirstPurchase.
        // Otherwise native mobile app navigation will get screwed up.
        path: '/promo/amazon50CreditFirstPurchase',
        name: 'PromoAmazon50CreditFirstPurchase',
        component: () => import('@/views/PromoAmazon50CreditFirstPurchase.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        // Always goes back to activity, so when used as a deeplink, must
        // be, e.g., my.aven.com/activity/promo/starbucksFreeCoffee.
        // Otherwise native mobile app navigation will get screwed up.
        path: '/promo/starbucksFreeCoffee',
        name: 'PromoStarbucksFreeCoffee',
        component: () => import('@/views/PromoStarbucksFreeCoffee.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        // Always goes back to activity, so when used as a deeplink, must
        // be, e.g., my.aven.com/activity/promo/netflixFirst3MonthsFree.
        // Otherwise native mobile app navigation will get screwed up.
        path: '/promo/netflixFirst3MonthsFree',
        name: 'PromoNetflixFirst3MonthsFree',
        component: () => import('@/views/PromoNetflixFirst3MonthsFree.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        // Always goes back to activity, so when used as a deeplink, must
        // be, e.g., my.aven.com/activity/promo/starbucksFreeCoffee.
        // Otherwise native mobile app navigation will get screwed up.
        path: RoutePaths.PROMO_SETUP_AUTOPAY_CREDIT,
        name: RouteNames.PROMO_SETUP_AUTOPAY_CREDIT,
        component: () => import('@/views/PromoSetupAutoPayCredit.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        // Always goes back to activity, so when used as a deeplink, must
        // be, e.g., my.aven.com/activity/closeAccountInRescission otherwise, native
        // mobile app navigation will get screwed up.
        path: RoutePaths.CLOSE_ACCOUNT_IN_RESCISSION,
        name: RouteNames.CLOSE_ACCOUNT_IN_RESCISSION,
        component: () => import('@/views/CloseAccountInRescission.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT,
        name: RouteNames.CLOSE_ACCOUNT,
        component: () => import('@/views/CloseAccountPrompt.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_PERMANENT,
        name: RouteNames.CLOSE_ACCOUNT_PERMANENT,
        component: () => import('@/views/CloseAccountPermanentlyNotice.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_KEEP_CARD,
        name: RouteNames.CLOSE_ACCOUNT_KEEP_CARD,
        component: () => import('@/views/CloseAccountKeepCard.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_PAYOFF,
        name: RouteNames.CLOSE_ACCOUNT_PAYOFF,
        component: () => import('@/views/CloseAccountPayOffDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_EMAIL_PAYOFF,
        name: RouteNames.CLOSE_ACCOUNT_EMAIL_PAYOFF,
        component: () => import('@/views/CloseAccountEmailPayoff.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    {
        path: RoutePaths.CLOSE_ACCOUNT_WIRE,
        name: RouteNames.CLOSE_ACCOUNT_WIRE,
        component: () => import('@/views/CloseAccountWireTransferDetails.vue'),
        meta: {
            layout: 'dashboard',
        },
    },
    // Disable for now. Need review before removing from source
    // {
    //   path: "/AddDebitCard",
    //   name: "AddDebitCard",
    //   component: () => import("@/views/AddDebitCard.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/profile",
    //   name: "Profile",
    //   component: () => import("@/views/Profile.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/transactionsList",
    //   name: "TransactionsList",
    //   component: () => import("@/views/TransactionsList.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/postSingleTransaction",
    //   name: "PostSingleTransaction",
    //   component: () => import("@/views/PostSingleTransaction.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/generateRandomTransactions",
    //   name: "GenerateRandomTransactions",
    //   component: () => import("@/views/GenerateRandomTransactions.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/rewardsSummary",
    //   name: "RewardsSummary",
    //   component: () => import("@/views/RewardsSummary.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/rewardsEdit",
    //   name: "RewardsEdit",
    //   component: () => import("@/views/RewardsEdit.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/billPay",
    //   name: "BillPay",
    //   component: () => import("@/views/BillPay.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // // not being used as of 2021-01-22
    // {
    //   path: "/paymentHistory",
    //   name: "PaymentHistory",
    //   component: () => import("@/views/PaymentHistory.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // // not being used as of 2021-01-22
    // {
    //   path: "/fundingPortal",
    //   name: "FundingPortal",
    //   component: () => import("@/views/FundingPortal.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // // not being used as of 2021-01-22
    // {
    //   path: "/debitCardInput",
    //   name: "DebitCardInput",
    //   component: () => import("@/views/DebitCardInput.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // // not being used as of 2021-01-22
    // {
    //   path: "/bankInput",
    //   name: "BankInput",
    //   component: () => import("@/views/BankInput.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // // not being used as of 2021-01-22
    // {
    //   path: "/paymentReimbursement",
    //   name: "PaymentReimbursement",
    //   component: () => import("@/views/PaymentReimbursement.vue"),
    //   meta: {
    //     layout: "dashboard",
    //   }
    // },
    // {
    //   path: "/privacyPolicy",
    //   name: "PrivacyPolicy",
    //   component: () => import("@/views/PrivacyPolicy.vue"),
    // },
    // {
    //   path: "/termsCondition",
    //   name: "TermsCondition",
    //   component: () => import("@/views/TermsCondition.vue"),
    // },
    // {
    //   path: "/about",
    //   name: "About",
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () =>
    //     import(/* webpackChunkName: "about" */ "../views/About.vue")
    // }
]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
})

router.beforeEach((routeTo, routeFrom, next) => {
    // attempt to parse for deeplink if conditions are met.
    // comments inside function maybeParseForDeeplink
    const signedIn = !!appSessionStorage.getItem(localStorageKey.jwtToken)
    const hasSession = !!appSessionStorage.getItem(localStorageKey.sessionId)
    const routerLogMessage = `router.beforeEach, from: ${routeFrom.path}  to: ${routeTo.path}`
    if (!hasSession) {
        saveDebugLog(localStorageKey.routerLog, routerLogMessage)
    } else {
        logger.info(routerLogMessage)
    }
    const result = maybeParseForDeeplink(routeTo, routeFrom)
    if (result?.root) {
        saveDebugLog(localStorageKey.routerLog, `save root ${result.root}`)
        store.commit('setCurrentRootPath', result.root)
    }
    if (result?.deeplink && !signedIn) {
        store.commit('updateDeeplinkPath', result.deeplink)
        saveDebugLog(localStorageKey.routerLog, `save deeplink: ${result.deeplink}`)
        saveDebugLog(localStorageKey.routerLog, `next route: ${RoutePaths.LOGIN}`)
        // mobile app injects device info via query param, we need to propagate query
        // to login.vue
        return next({ name: RouteNames.LOGIN, query: routeTo.query })
    }
    // route name could be empty for both to and from but that doesn't mean they are the same
    if (routeTo.path === routeFrom.path) {
        logger.info(`routeFrom.path: ${routeFrom.path} === routeTo.path ${routeTo.path}, navigation cancelled`)
        return
    }
    // Only allow crypto product customers to go to CryptoRoutes
    if (CryptoRoutePaths.map((route) => route.toString()).includes(routeTo.path) && !store.getters.isCryptoProduct) {
        logger.info(`routeFrom.path: ${routeFrom.path} === routeTo.path ${routeTo.path}, navigation cancelled`)
        if (store.getters.isSingleWebView) {
            logger.info(`single webview, next route is activity, update navtive tab bar with name: ${RouteNames.ACTIVITY}`)
            window.location.href = `/tabUpdate?tabName=${RouteNames.ACTIVITY}`
        }
        logger.info(`next route is activity`)
        return next({ name: RouteNames.ACTIVITY })
    }

    // Store the previous path to send with analytics events.
    window.previousPath = routeFrom.path

    if ([RoutePaths.ACTIVITY.toString(), RoutePaths.STATEMENTS.toString(), RoutePaths.CARD.toString()].includes(routeTo.path) && store.getters.isSingleWebView) {
        logger.info(`update native tab bar with name: ${routeTo.name} to: ${routeTo.path}, from: ${routeFrom.path}`)
        window.location.href = `/updateTabBar?tabName=${routeTo.name}`
    }

    const isPublic = routeTo.matched.some((record) => record.meta.public)
    //any route that is marked public is allowed with no further checks
    if (isPublic) {
        logger.info(`navigate from ${routeFrom.name} to ${routeTo.name}`)
        return next()
    }

    if (store.getters.isSingleWebView && !signedIn) {
        logger.info(`SingleWebview next is routeTo.path: ${routeTo.path}`)
        return next()
    }

    //any route that is allowed with only session jwt
    const allowedWithSessionJwt = routeTo.matched.some((record) => record.meta.allowedWithSessionJwt)
    const haveSessionJwt = !!appSessionStorage.getItem(localStorageKey.sessionAccessJWT)
    if (allowedWithSessionJwt && haveSessionJwt) {
        logger.info(`navigate from ${routeFrom.name} to ${routeTo.name}`)
        return next()
    }

    //all other routes require access token which is obtained after successful password login
    if (signedIn) {
        logger.info(`navigate from ${routeFrom.name} to ${routeTo.name}`)
        if (routeTo.path === RoutePaths.LOGOUT && !store.getters.isSingleWebView) {
            appSessionStorage.clear()
        }
        logger.info(`navigate from ${routeFrom.name} to ${routeTo.name}`)
        return next()
    }
    logger.info(`navigate from ${routeFrom.name} to Login`)
    return next({ name: RouteNames.LOGIN })
})

const maybeParseForDeeplink = (routeTo: Route, routeFrom: Route): { root: string; deeplink: string } | undefined => {
    // If signed in then dont bother
    const logMessagePrefix = `router.beforeEach | isSingleWebView ${store.getters.isSingleWebView} | ${routeFrom.path} ---> ${routeTo.path},`
    if (appSessionStorage.getItem(localStorageKey.jwtToken)) {
        logger.info(`${logMessagePrefix}, not parsing for deeplink because the user is logged in`)
        return
    }

    let ignoreDeeplink
    if (store.getters.isSingleWebView) {
        ignoreDeeplink = routeFrom.path !== RoutePaths.SPLASH
    } else {
        ignoreDeeplink = routeFrom.path !== RoutePaths.ROOT || [RoutePaths.LOGIN.toString(), RoutePaths.LOGOUT.toString(), RoutePaths.SPLASH.toString()].includes(routeTo.path)
    }

    if (ignoreDeeplink) {
        return
    }

    logger.info(`${logMessagePrefix}, parse for deeplink`)

    return getRootAndDeeplinkFromPath(routeTo.path)
}

export const getRootAndDeeplinkFromPath = (path: string): { root: string; deeplink: string } | undefined => {
    // here on, a url with deeplink launched mSite.
    // my.aven.com/{root}/{deeplink} - compatible with both mobile apps and mSite
    // my.aven.com/{deeplink} - mSite only, will not work with mobile apps
    saveDebugLog(localStorageKey.routerLog, `input: ${path}`)
    const routeToComponents = path.split('/')
    saveDebugLog(localStorageKey.routerLog, `components: ${JSON.stringify(routeToComponents)}`)
    routeToComponents.shift() // first index is always an empty string due to leading / in the routeTo.path
    saveDebugLog(localStorageKey.routerLog, `components shifted: ${JSON.stringify(routeToComponents)}`)
    if (routeToComponents.length === 0) {
        saveDebugLog(localStorageKey.routerLog, `no deeplink in path ${path}`)
        return null
    }

    if (RoutePaths.LOGIN.endsWith(routeToComponents[0])) {
        saveDebugLog(localStorageKey.routerLog, `deeplink ${path}, first component is RouterPaths.LOGIN, discard by shiting by 1`)
        routeToComponents.shift()
        saveDebugLog(localStorageKey.routerLog, `after shifting, components: ${JSON.stringify(routeToComponents)}`)
    }

    if (routeToComponents.length === 0) {
        saveDebugLog(localStorageKey.routerLog, `no deeplink in path ${path}`)
        return null
    }

    // save initial path in vuex store for post login
    // handles /cashOut or /card/cashOut
    // when root (activity, card, statements) is not in the path. By default vuex store initializes currentRootPath to activity
    // root is stored in vuex store currentRootPath.
    // deeplink has to match routes declared above in routes
    let root = RoutePaths.ACTIVITY.toString()
    if ([RoutePaths.ACTIVITY.toString(), RoutePaths.STATEMENTS.toString(), RoutePaths.CARD.toString()].includes(`/${routeToComponents[0]}`)) {
        root = `/${routeToComponents.shift()}`
        saveDebugLog(localStorageKey.routerLog, `root is ${root}`)
    }

    let deeplink = ''
    routeToComponents.forEach((component) => {
        deeplink += `/${component}`
    })

    // only return result if there is a valid deeplink or root
    // i.e.
    // input: "/card/makePayment" -> root = card, deeplink is /makePayment
    // input "/makePayment" -> root = activity, deeplink is /makePayment
    // input "/card -> root = card, deeplink is ''
    // input "/" this is caught by above statement "if (routeToComponents.length === 0)" and null is returned
    return {
        root,
        deeplink,
    }
}

export default router
