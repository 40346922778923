import startCase from 'lodash/startCase'

export default {
    methods: {
        toThousands: function (val: number) {
            return val.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
        },
        toFormattedUSD: function (number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
            }).format(number)
        },
        toFormattedUSDNoCents: function (number: number) {
            return new Intl.NumberFormat('en-US', {
                style: 'currency',
                currency: 'USD',
                maximumSignificantDigits: 3,
            }).format(number)
        },
        toPrettyPhoneNumber: (value: number) => {
            const cleaned = ('' + value).replace(/\D/g, '')
            const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
            if (match) {
                const intlCode = match[1] ? '1 ' : ''
                return [intlCode, '(', match[2], ') ', match[3], ' ', match[4]].join('')
            } else {
                return value
            }
        },
        formatApr(value) {
            return `${parseFloat(String(value * 100)).toFixed(2)}%`
        },
        toFormattedAprStripTrailingZerosAfterDecimalPoint(value) {
            const stringNum = parseFloat(String(value * 100)).toFixed(2)
            if (stringNum.endsWith('.00')) {
                return stringNum.slice(0, stringNum.length - 3) + '%'
            }
            if (/\..0$/.test(stringNum)) {
                // match XX.X0 to drop the last 0
                return stringNum.slice(0, stringNum.length - 1) + '%'
            }
            return stringNum + '%'
        },
        capitalize(value = '') {
            return startCase(value.toLowerCase())
        },
        last4Chars(value = '') {
            return value.substr(value.length - 4)
        },
        toFormattedTermLength(termLength) {
            return `${termLength / 12} Years (${termLength} Payments)`
        },
    },
}
