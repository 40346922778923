<template>
    <div
        v-if="showAlert"
        class="banner"
        :class="`banner-activate`"
    >
        <div class="d-md-flex align-items-center justify-content-around banner-container my-1 mx-auto">
            <div>
                <h5 class="banner-heading fw-bold mb-1">
                    {{ $t(`components.accountActivationBanner.activate.title`) }}
                </h5>
                <p>
                    {{ $t(`components.accountActivationBanner.activate.description`) }}
                </p>
                <p />
            </div>
            <div class="w-100 d-grid">
                <a
                    class="ms-md-auto"
                    :class="`btn btn-activate`"
                    @click.prevent="onClick"
                >
                    {{ $t('components.accountActivationBanner.ctaActivate') }}
                </a>
            </div>
        </div>
    </div>
</template>

<script>
    import { PATHS } from '@/utils/paths'
    import { mapGetters } from 'vuex'
    import { AvenAccountStanding, CardActivationStatus } from '@/store'
    import { RouteNames } from '@/routes/router'

    export default {
        name: 'AccountActivationBanner',
        computed: {
            ...mapGetters(['cardRequiresActivation', 'isWebView', 'isSingleWebView', 'avenAccountStanding']),
            showAlert: function () {
                // account status banner take precedence over activation banner
                if (this.avenAccountStanding !== AvenAccountStanding.current) {
                    return false
                }
                const routes = []
                routes.push(RouteNames.ACTIVITY, RouteNames.STATEMENTS, RouteNames.CARD)
                const showOnRoute = routes.includes(this.$route.name)
                // my.aven.com has already fetched card activation status by the time Dashboard component loads after login completes.
                // mobile app login is part of native code. accountOverview api is not executed until dashboard.vue loads.
                // isCardActivated remains in unknown state until accountOverview completes.
                // we do not want activation banner to appear while isCardActivated is in unknown state.
                const cardActivationStatusInitialized = this.$store.state.overview.cardActivationStatus !== CardActivationStatus.unknown
                return cardActivationStatusInitialized && showOnRoute && this.cardRequiresActivation
            },
        },
        methods: {
            onClick: function () {
                if (this.isWebView && !this.isSingleWebView) {
                    window.location.href = PATHS.MOBILE_REDIRECT.CARD_ACTIVATION
                } else {
                    this.$router.push({
                        name: 'CardActivation',
                    })
                }
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/banner';
</style>
