/*eslint no-console: ["off"] */
import Vue from 'vue'
import * as Sentry from '@sentry/browser'
import * as Integrations from '@sentry/integrations'
import { httpClient } from '@/services/http-client'
import { appSessionStorage, localStorageKey, platformType } from '@/utils/storage'
import inspect from 'object-inspect'
import { getDeviceInfo } from '@/utils/deviceUtils'
import store from '@/store'

export const heraclesLogPath = '/aven_app/logclientevent'

/**
 * We use this as the error that we send to Sentry when our code only sends an error message,
 * but not a thrown Error (or Error subclass). For example:
 * logger.error('some message without an error')
 * As opposed to:
 * logger.error('some message with an error', error)
 *
 * Without this message wrapper, we can't actually group different Sentry messages because Sentry
 * automatically tries to group by stacktrace, which is not appropriate for error messages without
 * thrown errors.
 */
class SentryMessage extends Error {
    constructor(msg: string) {
        super(msg)
        this.name = 'SentryMessage'
        Object.setPrototypeOf(this, new.target.prototype)
    }
}

const Logger = class {
    // either exists or not
    isDevelopment = process.env.VUE_APP_CLIENT_SIDE_LOGS_ENABLED === 'yes'

    info = (message: string, event = null) => {
        if (this.isDevelopment) {
            console.info(message)
        }
        this.sendToBackend(message, event)
    }

    log = (message: string, event = null) => {
        if (this.isDevelopment) {
            console.log(message)
        }
        this.sendToBackend(message, event)
    }

    getUserIdentities = () => {
        const creditCardCustomerId = appSessionStorage.getItem(localStorageKey.creditCardCustomerId)
        const applicantId = appSessionStorage.getItem(localStorageKey.applicantId)
        const loanApplicationId = appSessionStorage.getItem(localStorageKey.loanApplicationId)
        const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)

        const identityArray = []
        if (sessionId) {
            identityArray.push(`S: ${sessionId}`)
        }
        if (applicantId) {
            identityArray.push(`A: ${applicantId}`)
        }
        if (loanApplicationId) {
            identityArray.push(`L: ${loanApplicationId}`)
        }

        if (creditCardCustomerId) {
            identityArray.push(`AC: ${creditCardCustomerId}`)
        }

        if (store.getters.deviceInfo.deviceGuid) {
            identityArray.push(`D: ${store.getters.deviceInfo.deviceGuid}`)
        }

        let identities = 'None'
        if (identityArray.length > 0) {
            identities = '[' + identityArray.join(' | ') + ']'
        }
        return identities
    }

    logMessageToSentry(message: string, severity: Sentry.Severity, error?: any, tags?: Object) {
        try {
            const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)

            const captureContext: any = {
                level: severity ?? Sentry.Severity.Fatal,
                extra: {
                    message,
                    logRocketUrl: appSessionStorage.getItem(localStorageKey.logRocketUrl) || 'not_set',
                    identities: this.getUserIdentities(),
                    // @ts-ignore navigator connection hasn't been standardized yet.
                    networkQuality: `type: ${navigator?.connection?.effectiveType}, downlink: ${navigator?.connection?.downlink}, online: ${navigator?.onLine}`,
                },
                tags: {
                    noSessionId: !sessionId,
                    sessionId,
                    deviceGuid: store.getters.deviceInfo.deviceGuid || 'not_set',
                    runtimeType: store.getters.deviceInfo.runtimeType || 'not_set',
                    appVersion: store.getters.deviceInfo.appVersion || 'not_set',
                    creditCardCustomerId: appSessionStorage.getItem(localStorageKey.creditCardCustomerId) || 'not_set',
                    applicantId: appSessionStorage.getItem(localStorageKey.applicantId) || 'not_set',
                    loanApplicationId: appSessionStorage.getItem(localStorageKey.loanApplicationId) || 'not_set',
                    logRocketUrl: appSessionStorage.getItem(localStorageKey.logRocketUrl) || 'not_set',
                    ...tags,
                },
            }
            const errorToSend = error ?? new SentryMessage(message)
            Sentry.captureException(errorToSend, captureContext)
        } catch (e) {
            Sentry.captureException(new Error(`Failed to capture exception, error message is ${e.message}`))
        }
    }

    error = (message: string, event = null, error = null) => {
        const creditCardCustomerId = appSessionStorage.getItem(localStorageKey.creditCardCustomerId) || '_'
        const sessionId = appSessionStorage.getItem(localStorageKey.sessionId) || '_'
        const now = Date.now()

        let sentryLogMessage = `[ERROR] ${window.location.host} [C: ${creditCardCustomerId} | S: ${sessionId}] ${message}`
        if (error) {
            sentryLogMessage += sessionId ? ` see aws logs for inspect(error) output. search for {${now}}` : ` error: $${inspect(error)}`
            message += ` {${now}} error: $${inspect(error)}\``
        }

        if (this.isDevelopment) {
            console.error(message)
        } else {
            this.logMessageToSentry(sentryLogMessage, Sentry.Severity.Error, error)
        }
        this.sendToBackend(message, event)
    }

    sendToBackend = async (message: string, event: any) => {
        if (!message.trim()) {
            // Don't send empty messages
            return
        }

        try {
            const deviceGuid = store.getters.deviceInfo.deviceGuid
            if (!deviceGuid) {
                console.info(`sendToBackEnd, no device guid`)
            }
            const sessionId = appSessionStorage.getItem(localStorageKey.sessionId)
            if (!sessionId) {
                console.info('[INFO] Session ID not set yet')
            }

            let postBody = {}

            if (event) {
                postBody = {
                    sessionId,
                    deviceGuid,
                    eventName: message.substring(0, 1024 * 24),
                    eventJson: JSON.stringify(event),
                }
            } else {
                postBody = {
                    sessionId,
                    deviceGuid,
                    eventName: message.substring(0, 1024 * 24),
                }
            }

            await httpClient.post(heraclesLogPath, postBody)
        } catch (error) {
            console.error(`[ERROR] Could not send log: ${inspect(error)}`)
        }
    }

    constructor() {
        const isScraper = /google|fbid|facebook|fbav|fb_/gi.test(navigator.userAgent)
        if (!this.isDevelopment && !isScraper) {
            const platform = appSessionStorage.getItem(localStorageKey.platform)?.toLowerCase()
            let sentryID
            if (platform === platformType.android) {
                sentryID = process.env.VUE_APP_SENTRY_ID_ANDROID
            } else if (platform === platformType.iOS) {
                sentryID = process.env.VUE_APP_SENTRY_ID_IOS
            } else {
                sentryID = process.env.VUE_APP_SENTRY_ID_WEB
            }
            Sentry.init({
                dsn: sentryID,
                environment: process.env.VUE_APP_NODE_ENV,
                release: process.env.VUE_APP_SENTRY_RELEASE,
                maxValueLength: 1024 * 24,
                attachStacktrace: true,
                integrations: [new Integrations.Vue({ Vue, attachProps: true, logErrors: true }), new Sentry.Integrations.GlobalHandlers({ onerror: true, onunhandledrejection: true })],
            })
        } else {
            console.info('[INFO] Refusing to log development and/or Googlebot to sentry')
        }

        Vue.config.errorHandler = (err: Error, vm: Vue, info: string) => {
            // Can't serialize vm arg, which is a recursively defined Vue object (also no point)
            this.error(`Vuejs error ${err.message}. Info: ${info}`, err)
        }

        Vue.config.warnHandler = (err: string, vm: Vue, info: string) => {
            // Can't serialize vm arg, which is a recursively defined Vue object (also no point)
            this.log(`Vuejs warn ${err}. Info: ${info}`)
        }

        window.onunhandledrejection = (event: PromiseRejectionEvent) => {
            const reason = event.reason
            const msg = `window.onunhandledrejection error: ${inspect(reason)}`
            this.error(msg, event, reason instanceof Error ? reason : undefined)
        }

        window.onerror = (event: Event | string, source: string | undefined, lineno: number | undefined, colno: number | undefined, error: Error | undefined) => {
            const msg = `window.onerror error: ${inspect({ event, source, lineno, colno, error })}`
            this.error(msg, event, error)
        }
    }
}

const logger = new Logger()

export { logger }
