import { logger } from '@/utils/logger'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import assert from 'assert'
import store from '@/store'
import { generateFingerprint } from '@/utils/fingerprint'
export interface DeviceInfo {
    deviceGuid: string
    deviceName: string
    deviceModel: string
    browser: string
    resolution: string
    os: string
    appVersion: string
    runtimeType: string
    platform: string
}

export const getDeviceInfo = async (): Promise<DeviceInfo> => {
    const deviceInfo: DeviceInfo = store.state.deviceInfo
    if (deviceInfo.deviceGuid) {
        logger.info(`got valid deviceInfo from vuex store, ${JSON.stringify(deviceInfo)}`)
        appSessionStorage.setItem(localStorageKey.deviceGuid, deviceInfo.deviceGuid)
        return deviceInfo
    }

    const deviceGuid = await generateFingerprint()
    assert(deviceGuid, 'call getDeviceInfo after deviceGuid has been set')
    return {
        deviceGuid: deviceGuid,
        // DO NOT CHANGE! Backend hack to determine whether a session is from a browser
        // or mobile app uses the deviceName field.
        deviceName: 'N/A',
        deviceModel: window.navigator.platform,
        browser: store.getters.isWebView ? 'mobileWebView' : window.navigator.userAgent,
        resolution: `${window.screen.availWidth} x ${window.screen.availHeight}`,
        os: getOS(),
        appVersion: process.env.VUE_APP_VERSION,
        // See AvenDeviceRuntimeType on the backend
        runtimeType: 'web',
        platform: 'browser',
    }
}

export const getOS = (): string => {
    const userAgent = window.navigator.userAgent
    const platform = window.navigator.platform
    const macosPlatforms = ['Macintosh', 'MacIntel', 'MacPPC', 'Mac68K']
    const windowsPlatforms = ['Win32', 'Win64', 'Windows', 'WinCE']
    const iosPlatforms = ['iPhone', 'iPad', 'iPod']
    let os = 'unidentified'
    if (macosPlatforms.indexOf(platform) !== -1) {
        os = 'Mac OS'
    } else if (iosPlatforms.indexOf(platform) !== -1) {
        os = 'iOS'
    } else if (windowsPlatforms.indexOf(platform) !== -1) {
        os = 'Windows'
    } else if (/Android/.test(userAgent)) {
        os = 'Android'
    } else if (!os && /Linux/.test(platform)) {
        os = 'Linux'
    }
    logger.info(`Device OS: ${os}`)
    return os
}

export const isSmallScreen = (): boolean => {
    return window.innerWidth < 500
}
