<template>
    <div class="tab-activity">
        <app-header
            :title="$t('pages.activity.title')"
            class="mobile-header"
        />
        <div class="container position-relative px-0 px-md-3">
            <small-loading-indicator v-if="updating" />
            <div
                class="account-overview mb-2"
                v-show="!accountIsClosed"
            >
                <div
                    class="card-background"
                    data-testid="activity-balance"
                >
                    <h3 class="fw-bold mb-0 text-responsive">
                        {{ formatAmount(this.currentBalance) }}
                    </h3>
                    <p>{{ formatAmount(this.availableCredit) }} {{ $t('pages.activity.Available') }}</p>
                    <img
                        src="@/assets/images/pages/activity/VisaWhite.svg"
                        alt="Visa"
                    >
                </div>
                <common-actions class="pt-2 px-md-3" />
            </div>

            <!-- Pay It Forward Card -->
            <pay-it-forward-card class="mb-2" />

            <!-- Upcoming AutoPay date and amount -->
            <card-block
                :title="$t('pages.activity.Upcoming')"
                v-show="autoPaySetting.isEnabled && nextAutoPayAmount > 0"
                class="mb-2"
            >
                <transaction-row
                    icon="Payment.svg"
                    :title="$t('pages.activity.autoPay')"
                    :date="formatDateUtc(paymentDueDate)"
                    highlight-color="text-success"
                    :value="autoPayAmount"
                    :show-arrow="true"
                    @onClick="$router.push(RoutePaths.AUTO_PAY)"
                />
            </card-block>

            <!-- Outstanding Transaction with heading "PENDING" & without DateGroup -->
            <div v-if="outstandingTransaction.length > 0">
                <card-block
                    :title="$t('pages.activity.Pending')"
                    class="mb-2"
                >
                    <template v-for="LatestTransaction in outstandingTransaction">
                        <div :key="LatestTransaction.transactionID">
                            <transaction-row
                                :title="LatestTransaction.title.toString()"
                                :date="formatDateUtc(LatestTransaction.transactionDate)"
                                :highlight-color="LatestTransaction.isDeposit ? 'text-success' : 'text-primary'"
                                :value="LatestTransaction.absoluteAmount"
                            />
                            <p
                                v-if="LatestTransaction.type === TransactionType.CashOut"
                                class="text-muted small"
                            >
                                {{ $t('pages.cashOut.pendingCashOutListSubtext') }}
                            </p>
                            <p
                                v-else-if="LatestTransaction.type === TransactionType.CreditCard"
                                class="text-muted small"
                            >
                                {{ $t('components.balanceTransfer.pendingTransfer') }}
                            </p>
                        </div>
                    </template>
                    <p class="text-muted mb-2 small">
                        {{ $t('pages.cashOut.pendingCashOutListSubtext') }}
                    </p>
                </card-block>
            </div>

            <!-- Settled Transactions -->
            <div v-if="settledTransaction.size > 0">
                <div
                    v-for="[key, value] of settledTransaction"
                    :key="key"
                >
                    <card-block
                        :title="key"
                        class="mb-2"
                    >
                        <div
                            v-for="LatestTransaction in value"
                            :key="LatestTransaction.transactionID"
                            class="transaction-list-item"
                        >
                            <transaction-row
                                :title="LatestTransaction.title.toString()"
                                :date="formatDateUtc(LatestTransaction.transactionDate)"
                                :highlight-color="LatestTransaction.isDeposit ? 'text-success' : 'text-primary'"
                                :value="LatestTransaction.absoluteAmount"
                            />
                            <template v-if="isWithinDaysRange(LatestTransaction.transactionDate, 10)">
                                <p
                                    v-if="LatestTransaction.title === 'Cash Out'"
                                    class="text-muted small"
                                >
                                    {{ $t('pages.cashOut.pendingCashOutListSubtext') }}
                                </p>
                                <p
                                    v-else-if="LatestTransaction.title === 'Balance Transfer'"
                                    class="text-muted small"
                                >
                                    {{ $t('components.balanceTransfer.pendingTransfer') }}
                                </p>
                            </template>
                        </div>
                    </card-block>
                </div>
            </div>
            <div
                class="text-center my-8"
                v-if="outstandingTransaction.length === 0 && settledTransaction.size === 0"
            >
                <p class="fw-bold mb-1">
                    {{ $t('pages.activity.NoTransactionYet') }}
                </p>
                <p
                    class="text-muted"
                    v-html="$t('pages.activity.AnticipationHtml')"
                />
            </div>
        </div>
    </div>
</template>
<script>
    import { transactionHistory } from '@/services/api'
    import Header from '../components/AppHeader'
    import generic from '@/utils/generic'
    import { ApiErrorHandler } from '@/utils/exception-handler'
    import { logger } from '@/utils/logger'
    import { MMM_DD_DATE_FORMAT, MONTH_FORMAT, MONTH_YEAR_FORMAT, NYC_TIMEZONE, TransactionStatus, TransactionType } from '@/data/constants'
    import SmallLoadingIndicator from '@/components/SmallLoadingIndicator'
    import { mapActions, mapGetters } from 'vuex'
    import dayjs from 'dayjs'
    import PayItForwardCard from '@/components/PayItForwardCard'
    import CommonActions from '@/components/CommonActions'
    import { RoutePaths } from '@/routes/router'
    import CardBlock from '@/components/CardBlock'
    import TransactionRow from '@/components/TransactionRow'

    export default {
        name: 'Activity',
        components: {
            CardBlock,
            PayItForwardCard,
            'app-header': Header,
            SmallLoadingIndicator,
            CommonActions,
            TransactionRow,
        },
        data() {
            return {
                updating: false,
                settledTransaction: '',
                outstandingTransaction: '',
                TransactionType,
                RoutePaths,
                MMM_DD_DATE_FORMAT,
            }
        },
        computed: {
            ...mapGetters([
                'currentBalance',
                'availableCredit',
                'creditLimit',
                'existingCashOutTransfers',
                'existingBalanceTransfers',
                'accountIsClosed',
                'autoPaySetting',
                'paymentDueDate',
                'nextAutoPayAmount',
            ]),
            dashArray: function () {
                return `${(1.0 - this.availableCredit / this.creditLimit) * 100}, 100`
            },
            autoPayAmount: function () {
                return generic.prettyPrintDollarAmount(Math.abs(this.nextAutoPayAmount).toString())
            },
        },
        mounted: function () {
            logger.info(`------ Activity Mounted ------`)
            this.$logEvent('view_activity')
            window.refreshData = this.refreshData
            this.loadTransactions()
        },
        methods: {
            ...mapActions(['updateAccountOverview', 'refreshAutoPayDetails']),
            formatAmount(amount) {
                return generic.prettyPrintDollarAmount(amount)
            },
            formatDateUtc(date) {
                return generic.utcToTimeZone(date, NYC_TIMEZONE, MMM_DD_DATE_FORMAT)
            },
            isWithinDaysRange(date, range) {
                return dayjs().diff(date, 'day') <= range
            },
            refreshData: async function () {
                logger.info('Refresh transaction list')
                this.refreshAutoPayDetails()
                this.updateAccountOverview()
                this.loadTransactions()
            },
            loadTransactions: async function () {
                try {
                    this.updating = true
                    await this.getAllTransactions()
                } catch (error) {
                    ApiErrorHandler(error)
                } finally {
                    this.updating = false
                }
            },
            getAccountOverview: async function () {
                await this.$store.dispatch('updateAccountOverview')
            },
            formatPendingBTAndCOTransactions(transaction) {
                let titlePrefix = ''

                if (transaction.type === this.TransactionType.CreditCard) {
                    titlePrefix = 'Balance Transfer '
                } else if (transaction.type === this.TransactionType.CashOut) {
                    titlePrefix = 'Cash Out '
                }
                return {
                    type: transaction.type,
                    title: `${titlePrefix}${transaction.lender}`,
                    amount: transaction.amount,
                    transactionDate: transaction.initiationDate,
                    transactionID: transaction.initiationDate,
                }
            },
            getAllTransactions: async function () {
                const settledTxnPromise = transactionHistory('0', TransactionStatus.Settled)
                const outstandingTxnPromise = transactionHistory('0', TransactionStatus.Outstanding)
                const [settledResponse, outstandingResponse] = await Promise.all([settledTxnPromise, outstandingTxnPromise])
                logger.info('settledResponse and outstandingResponse completed without errors')
                let list = []
                let transactionMap = new Map()
                const transactionResponse = settledResponse.data.payload

                const formatTransaction = (tx) => ({
                    title: tx.title,
                    absoluteAmount: generic.prettyPrintDollarAmount(Math.abs(tx.amount).toString()),
                    isDeposit: tx.amount < 0,
                    transactionDate: tx.transactionDate,
                    transactionID: tx.transactionID,
                })

                for (let index in transactionResponse) {
                    const formattedTransactionDate = generic.convertDateTimeFormat(transactionResponse[index].transactionDate)
                    const sectionHeaderFormat = generic.isCurrentYear(formattedTransactionDate) ? MONTH_FORMAT : MONTH_YEAR_FORMAT
                    const sectionHeader = generic.convertDateTimeFormat(transactionResponse[index].transactionDate, { outputFormat: sectionHeaderFormat })

                    if (transactionMap.has(sectionHeader)) {
                        list = transactionMap.get(sectionHeader)
                        list.push(formatTransaction(transactionResponse[index]))
                        transactionMap.set(sectionHeader, list)
                    } else {
                        transactionMap.set(sectionHeader, [formatTransaction(transactionResponse[index])])
                    }
                }

                this.settledTransaction = transactionMap

                const pendingCashOuts = this.existingCashOutTransfers?.get('PENDING') || []
                const pendingBalanceTransfers = this.existingBalanceTransfers?.get('PENDING') || []
                const pendingBalanceTransfersAndCashOuts = [...pendingCashOuts, ...pendingBalanceTransfers]
                const outstandingTransactions = [...pendingBalanceTransfersAndCashOuts.map(this.formatPendingBTAndCOTransactions), ...outstandingResponse.data.payload]

                // order by transactionDate in descending order
                outstandingTransactions.sort((a, b) => {
                    return dayjs(b.transactionDate).diff(dayjs(a.transactionDate))
                })

                this.outstandingTransaction = outstandingTransactions
            },
        },
    }
</script>

<style lang="scss" scoped>
    .tab-activity {
        background-color: $gray-100;
    }

    .account-overview {
        background-color: $white;
        border-radius: 0;
        border: none;
        padding: 24px;

        @include media-breakpoint-up(md) {
            border-radius: $border-radius-lg;
            padding: 0;
        }

        .card-background {
            background-color: $black;
            color: $white;
            border-radius: $border-radius-lg;
            height: 206px;
            width: 100%;
            padding: 24px;
            position: relative;

            @include media-breakpoint-up(md) {
                padding: 24px 32px;
                height: 260px;
            }
        }

        img {
            position: absolute;
            bottom: 24px;
            right: 24px;
            width: 64px;

            @include media-breakpoint-up(md) {
                width: 80px;
            }
        }
    }

    .transaction-list-item {
        margin-bottom: $spacer;

        &:last-child {
            margin-bottom: 0;
        }
    }

    .current-balance {
        height: 292px;
        display: flex;
        justify-content: center;
        align-items: center;
    }
</style>
