import { httpClient } from '@/services/http-client'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { DeviceInfo } from '@/utils/deviceUtils'

export interface SmsTwoFactorCodePayload {
    phoneNumber: string
    deviceGuid: string
    codeDeliveryMethod: string
}

export interface EmailTwoFactorCodePayload {
    email: string
    deviceGuid: string
    codeDeliveryMethod: string
}

export interface VerifyOtpCodePayload {
    verificationCode: string
    sid: string
}

export const blockStatus = async (payload) => {
    return await httpClient.post('/aven_app/blockStatus', payload)
}

export const sessionId = async (payload: DeviceInfo) => {
    return await httpClient.post('/aven_app/v2/sessionId', payload)
}

export const requestOtpCode = async (requestPayload: SmsTwoFactorCodePayload | EmailTwoFactorCodePayload) => {
    const response = await httpClient.post('/aven_app/v2/request2FACode', requestPayload)
    if (response.data.success) {
        appSessionStorage.setItem(localStorageKey.creditCardCustomerId, response.data.payload.creditCardCustomerId)
        appSessionStorage.setItem(localStorageKey.displayPhoneNumber, response.data.payload.prettyPhoneNumber)
        appSessionStorage.setItem(localStorageKey.displayEmail, response.data.payload.displayEmail)
    }
    return response
}

export const verifyOtpCode = async (verifyCodePayload: VerifyOtpCodePayload) => {
    return await httpClient.post('/aven_app/v2/verify2FACode', verifyCodePayload)
}

export const setPassword = async (token: string, password: string, last4Ssn: string, dateOfBirth: string) => {
    return await httpClient.post(`/aven_app/setPasscode/${token}`, {
        passcode: password,
        last4Ssn,
        dateOfBirth,
    })
}

export const requestSetPasswordToken = async () => {
    return await httpClient.get('/aven_app/requestPasscodeResetToken')
}

export const passwordLogin = async (password: string) => {
    return await httpClient.post('/aven_app/v2/passcodeLogin', {
        creditCardCustomerId: parseInt(appSessionStorage.getItem(localStorageKey.creditCardCustomerId)),
        passcode: password,
    })
}

export const verifyPassword = async (password: string) => {
    return await httpClient.post('/aven_app/verifyPasscode', {
        passcode: password,
    })
}

export const getAccountOverview = async () => httpClient.get('/aven_app/overview')

// Statement
export const statementList = async () => {
    return await httpClient.get('/aven_app/statementList')
}

// get statement
export const getStatementPdf = async (statementId: number) => {
    return await httpClient.get(`/aven_app/statement/${statementId}`, { responseType: 'blob' })
}

// Tax documents
export const taxDocumentList = async () => {
    return await httpClient.get('/aven_app/taxDocumentList')
}

export const getTaxDocumentPdf = async (taxDocumentId: number) => {
    return await httpClient.get(`/aven_app/taxDocument/${taxDocumentId}`, { responseType: 'blob' })
}

// get pay-it-forward link
export const getPayItForward = async () => {
    return await httpClient.get(`/aven_app/payItForward`)
}

export const submitPayItForwardTip = async (tipAmount) => {
    return await httpClient.post('/aven_app/payItForward/saveTipAmount', {
        sourceTip: tipAmount,
    })
}

export const replaceCard = async (reason: string) => {
    return await httpClient.post('/aven_app/replaceCard', {
        reason: reason,
    })
}
