import Vue from 'vue'
import Vuex from 'vuex'
import LogRocket from 'logrocket'
import createPlugin from 'logrocket-vuex'
import { getAccountOverview, getPayItForward, replaceCard, statementList, taxDocumentList } from '@/services/avenAppApi'
import {
    accountPayoffQuote,
    aciGenerateFundingTokenBankAccount,
    aciSearchFundingSources,
    activateAvenCard,
    avenAciDeleteFundingSource,
    avenAutopayDetails,
    avenAutopayOptIn,
    avenAutopayOptOut,
    getActiveBalanceTransferAccountsEligibleForRecurrence,
    getBalanceTransfers,
    initiateBalanceTransferForRecurringAccount,
    updateAutoBalanceTransferEnabledAccounts,
} from '@/services/api'
import groupBy from 'lodash/groupBy'
import * as Sentry from '@sentry/browser'
import inspect from 'object-inspect'
import { appSessionStorage, localStorageKey } from '@/utils/storage'
import { logger } from '@/utils/logger'
import { AciAccountSubType, MAX_IN_APP_CARD_REPLACEMENT } from '@/data/constants'
import assert from 'assert'
import { DeviceCapabilities } from '@/utils/deviceCapabilities'
import { DeviceInfo } from '@/utils/deviceUtils'

export const NETWORK_ERROR = 'NETWORK_ERROR'
export const SERVER_RESPONSE_ERROR = 'SERVER_RESPONSE_ERROR'

export enum CardActivationError {
    CARD_MAX_ONLINE_ACTIVATION_ATTEMPTS_REACHED = 'CARD_MAX_ONLINE_ACTIVATION_ATTEMPTS_REACHED',
    ERROR_CARD_ACTIVATION_DATA_INVALID = 'ERROR_CARD_ACTIVATION_DATA_INVALID',
    ERROR_IN_RESCISSION_PERIOD = 'ERROR_IN_RESCISSION_PERIOD',
    ERROR_PRIMARY_CARD_NOT_ACTIVATED = 'ERROR_PRIMARY_CARD_NOT_ACTIVATED',
    ERROR_CARD_ACTIVATION_CSR_ASSISTANCE_NEEDED = 'ERROR_CARD_ACTIVATION_CSR_ASSISTANCE_NEEDED',
    ERROR_BLOCKED_BY_PENDING_CONTINGENCIES = 'ERROR_BLOCKED_BY_PENDING_CONTINGENCIES',
}

Vue.use(Vuex)
const logRocketPlugin = createPlugin(LogRocket)

enum SessionDataKeys {
    rootPath = 'rootPath',
}

export enum CardActivationStatus {
    unknown = 'unknown',
    pending = 'pending',
    activated = 'activated',
    internalError = 'internalError',
    closed = 'closed',
    canceled = 'canceled',
    exceededMaxOnlineActivationAttempts = 'exceededMaxOnlineActivationAttempts',
}

// Keep in sync with enum AvenAccountStanding in aven_backend/src/util/avenCardOverviewConstants.ts
export enum AvenAccountStanding {
    unknown = 'unknown',
    current = 'current',
    pastDue = 'pastDue',
    delinquent = 'delinquent',
    overLimit = 'overLimit',
    fraud = 'fraud',
    blocked = 'blocked',
    closed = 'closed',
    pendingClose = 'pendingClose',
    pendingPayoffThenLimitedMode = 'pendingPayoffThenLimitedMode',
}

// Keep in sync with enum AvenCardProductType in aven_backend/src/entity/aven/AvenCardProductType.ts
export enum AvenCardProductType {
    NONE = 'NONE',
    CRYPTO = 'CRYPTO',
    AUTO = 'AUTO',
    HOME = 'HOME',
    HOME_NO_LOYALTY = 'HOME_NO_LOYALTY',
    HOME_LIMITED_MODE = 'HOME_LIMITED_MODE',
}

export enum AvenCardUserType {
    UNKNOWN = 'UNKNOWN',
    PRIMARY = 'PRIMARY',
    CO_OWNER = 'CO_OWNER',
    CO_APPLICANT = 'CO_APPLICANT',
}

export enum BalanceTransferStatus {
    HumanInvestigate = 'HumanInvestigate', // Register BT and wait for a manual update on this record.
    AciSubmissionPending = 'AciSubmissionPending', // Pre-registering BT, BT API call to ACI is pending
    AciSubmissionSuccess = 'AciSubmissionSuccess', // BT API call to ACI is successful
    AciSubmissionFailed = 'AciSubmissionFailed', // BT API call to ACI failed
    CoreCardSubmissionPending = 'CoreCardSubmissionPending', // Post single transaction successful
    CoreCardSubmissionSuccess = 'CoreCardSubmissionSuccess', // Post single transaction successful
    CoreCardSubmissionFailed = 'CoreCardSubmissionFailed', // Post Single transaction failed
    Completed = 'Completed',
    Canceled = 'Canceled',
    AmountExceedsMaxAllowedAfterPayment = 'AmountExceedsMaxAllowedAfterPayment',
    TotalAmountOfCashOutsAndBalanceTransfersExceedDailyLimit = 'TotalAmountOfCashOutsAndBalanceTransfersExceedDailyLimit',
}

export enum AciPaymentTransactionType {
    PAYMENT = 'PAYMENT',
    REIMBURSEMENT = 'REIMBURSEMENT',
    REVERSAL = 'REVERSAL', // Cancelled or failed payments
}

export enum AutopayCategory {
    STATEMENT_BALANCE = 'STATEMENT_BALANCE',
    MINIMUM_DUE = 'MINIMUM_DUE',
    CUSTOM_AMOUNT = 'CUSTOM_AMOUNT',
}

export interface ICustomerInfo {
    firstName: string
    lastName: string
    email: string
    phoneNumber: string
    addressLine1: string
    addressLine2: string
    city: string
    state: string
    postalCode: string
    country: string
}

export interface IBalanceTransferAccount {
    accountId: number
    accountName: string
    isAutoBalanceTransferEnabled: boolean
}

export interface IInitiatedBalanceTransfer {
    amount: number
    token: string
    accountName: string
}

// TODO: better typings
export interface IBalanceTransfer {
    existingTransactions: {}
    activeBalanceTransferAccountsEligibleForRecurrence: IBalanceTransferAccount[]
    availableCreditLimit: number
    apr: number
    selectedAccount: object
    mostRecentRecurringBalanceTransfer: IInitiatedBalanceTransfer
}

export enum ProductCategory {
    basic = 'basic',
    prime = 'prime',
}

// current autoPay configuration
export interface AutoPayDetails {
    autoPayCategory: AutopayCategory
    aciFundingTokenId: string
    customAmount?: number
}

export interface AutoPaySetting {
    isEnabled: boolean
    autoPayDetails?: AutoPayDetails
}

export const AUTO_PAY_DISABLED: AutoPaySetting = {
    isEnabled: false,
}

export interface PaymentSource {
    nickName: string
    maskedAccountNumber: string
    accountType: AciAccountSubType
    token: string
}

export interface PayoffDetails {
    payoffDateInNycTime: Date
    totalCurrentBalance: number
    interestAndFees: number
    totalPayoffAmount: number
}

// sync with enum AccountClosureType in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureType {
    PERMANENT_CLOSE = 'permanent_close',
    KEEP_CARD = 'keep_card',
}

// sync with enum AccountClosureRequestSource in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureRequestSource {
    SELF_SERVE = 'self_serve',
    CSR_RETOOL = 'csr_retool',
}

// sync with enum AccountClosureRequester in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureRequester {
    USER = 'user',
    TITLE = 'title',
    ESCROW = 'escrow',
    LENDER = 'lender',
}

// sync with enum AccountClosureReason in aven_backend/src/entity/accountClosureRequest.ts
export enum AccountClosureReason {
    SELLING_HOME = 'selling_home',
    REFI = 'refi',
    CASH_OUT_REFI = 'cash_out_refi',
    NOT_USE = 'not_use',
    NOT_HAPPY = 'not_happy',
    NO_REASON_STATED = 'no_reason_stated',
}

const getDefaultState = () => {
    return {
        deviceInfo: {},
        overview: {
            productType: AvenCardProductType.NONE,
            cardType: AvenCardUserType.UNKNOWN,
            cardActivationStatus: CardActivationStatus.unknown,
            showEnrollAutoPayAndKeepAprDiscount: false,
            alreadyShownEnrollInAutoPayAndKeepAprDiscount: false,
            isReplacementCard: false,
            apr: 0,
            billingDay: 0,
            accountNumber: 0,
            creditLimit: 0,
            limitedModeCreditLimit: 0,
            monthlyFee: 0,
            productCategory: ProductCategory.basic,
            desiredProductCategory: ProductCategory.basic,
            showPrimeOption: false,
            loanTermOptions: [],
            currentBalance: 0,
            statementRemainingBalance: 0,
            minimumDue: 0,
            isPasscodeSet: false,
            avenAccountStanding: AvenAccountStanding.unknown,
            nextStatementDate: '',
            paymentDueDate: '',
            availableCredit: 0,
            rewardsSummary: Object.create(null),
            dollarToCashbackRewardPointsConversion: 0,
            showCashOutFeature: false,
            cashOutTotalAmountWithFeeMultiplier: 0,
            cashOutFee: 0,
            balanceTransferFee: 0,
            totalPendingCashOutPlusBalanceTransfers: 0,
            madeRecentLargePayment: false,
            isLienPerfected: false,
            last4CardNumber: 0,
            payItForward: {
                shareLink: '',
                maxAmount: 0,
                inviteType: '',
            },
            payItForwardTipInfo: {
                maxAmount: 0,
                isEligible: false,
                sourceName: '',
            },
            signOnBonus: {
                type: '',
                bonusAmount: 0,
                sourceName: '',
            },
            numberOfCancelledCards: MAX_IN_APP_CARD_REPLACEMENT,
            enableAmortizationLoan: false,
            autoPaySetting: AUTO_PAY_DISABLED,
            btcDepositAddress: '',
            isInMarginCall: false,
        },
        customerInfo: {
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            addressLine1: '',
            addressLine2: '',
            city: '',
            state: '',
            postalCode: '',
            country: '',
        },
        balanceTransfer: {
            existingTransactions: Object.create(null),
            activeBalanceTransferAccountsEligibleForRecurrence: [],
            availableCreditLimit: 0,
            apr: 0,
            selectedAccount: {},
            mostRecentRecurringBalanceTransfer: {},
        } as IBalanceTransfer,
        totalCashOutsPlusBT: 0,
        payoffDetails: {
            payoffDateInNycTime: new Date(),
            totalCurrentBalance: 0,
            interestAndFees: 0,
            totalPayoffAmount: 0,
        },
        isZeroBalancePayoff: false,
        closeAccountReason: AccountClosureReason.NO_REASON_STATED,
        closeAccountKeepCard: false,
        error: '',
        errorPayload: Object.create(null),
        loading: false,
        // browser mSite
        //  currentRootPath starts out as ACTIVITY
        //  Dashboard layout then mutates currentRootPath as user clicked on either activity, statement or card.
        // webView
        //  currentRootPath is set by native side via Splash.vue - loadRootPage
        //  currentRootPath is never changed for that webview as native app has 3 webviews each corresponding to one tab.
        currentRootPath: appSessionStorage.getItem(SessionDataKeys.rootPath) || '',
        statements: [],
        selectedPaymentAmount: '',
        paymentSources: [], //PaymentSource[]
        selectedPaymentSource: Object.create(null),
        deeplinkPath: '',
        isWebView: false,
        isSingleWebView: false,
        deviceCapabilities: {},
        taxDocuments: [],
    }
}

const parseError = (commit, error) => {
    if (error.response) {
        commit('setError', SERVER_RESPONSE_ERROR)
    } else if (error.request) {
        commit('setError', NETWORK_ERROR)
    } else {
        Sentry.captureException(inspect(error))
    }
}

const avenVuxLogger = (store) => {
    // called when the store is initialized
    store.subscribe((mutation, state) => {
        // called after every mutation.
        // The mutation comes in the format of `{ type, payload }`.
        logger.info(`store mutation: ${JSON.stringify(mutation)}, state: ${JSON.stringify(state)}`)
    })
}

export default new Vuex.Store({
    state: getDefaultState(),
    mutations: {
        setDeviceInfo: (state, payload) => {
            state.deviceInfo = payload
            appSessionStorage.setItem(localStorageKey.deviceGuid, payload.deviceGuid)
            if (['ios', 'android'].includes(state.deviceInfo.runtimeType) && !state.deviceInfo.browser) {
                //backwards compatibility. older native app versions do not include this
                //field when deviceInfo is injected.
                //session api requires this field now.
                state.deviceInfo.browser = 'mobileWebview'
            }
            Sentry.setTag('runtimeType', state.deviceInfo.runtimeType)
        },
        selectedPaymentAmount: (state, payload) => {
            state.selectedPaymentAmount = payload
        },
        updateOverview: (state, payload) => {
            state.overview = { ...state.overview, ...payload }
            state.customerInfo = { ...state.customerInfo, ...payload.customerInfo }
        },
        setError: (state, payload) => {
            state.error = payload
        },
        setErrorPayload: (state, payload) => {
            state.errorPayload = payload
        },
        clearError: (state) => {
            state.error = ''
        },
        updateLoading: (state, payload) => {
            state.loading = payload
        },
        cardActivated: (state) => {
            state.overview = { ...state.overview, cardActivationStatus: CardActivationStatus.activated }
        },
        enrollAutoPayAndKeepAprDiscountShown: (state) => {
            state.overview = { ...state.overview, alreadyShownEnrollInAutoPayAndKeepAprDiscount: true }
        },
        replacementCardOrdered: (state) => {
            state.overview = { ...state.overview, cardActivationStatus: CardActivationStatus.pending, isReplacementCard: true }
        },
        setIsWebView: (state, payload: boolean) => {
            state.isWebView = payload
        },
        setSingleWebView: (state, payload: boolean) => {
            state.isSingleWebView = payload
        },
        setCurrentRootPath: (state, payload: string) => {
            // when using single webview(iOS) update root is allowed
            // android still uses 3 webviews, one per tab
            // when not in webview, root path can be changed any time
            const allowRootPathUpdate = state.isSingleWebView || !state.isWebView
            const isRootPathInitiated = !!appSessionStorage.getItem(SessionDataKeys.rootPath)
            if (allowRootPathUpdate || (!isRootPathInitiated && payload.length > 0)) {
                logger.info(`vuex store before update, currentRootPath ${state.currentRootPath}, sessionStorage: ${appSessionStorage.getItem(SessionDataKeys.rootPath)}`)
                appSessionStorage.setItem(SessionDataKeys.rootPath, payload)
                state.currentRootPath = payload
                logger.info(`vuex store after update, currentRootPath: ${state.currentRootPath}, sessionStorage: ${appSessionStorage.getItem(SessionDataKeys.rootPath)}`)
            }
        },
        updateStatements: (state, payload) => {
            state.statements = payload
        },
        updateTaxDocuments: (state, payload) => {
            state.taxDocuments = payload
        },
        updateBalanceTransferTransactions: (state, payload) => {
            // create object group by type (currently only marking CashOuts on backend/everything else is a balance transfer)
            const transactionType = groupBy(payload, (transaction) => {
                if (transaction.type === 'CashOut') {
                    return 'CASH_OUT'
                } else {
                    return 'BALANCE_TRANSFER'
                }
            })

            // util func to group by year
            const groupObjByYear = (objToGroupBy) =>
                groupBy(objToGroupBy, (transaction) => {
                    const isReversal = transaction.transactionType === AciPaymentTransactionType.REVERSAL
                    const wasSentToCoreCard = [BalanceTransferStatus.Completed, BalanceTransferStatus.CoreCardSubmissionSuccess].includes(transaction.status)
                    if (isReversal || wasSentToCoreCard) {
                        return new Date(transaction.initiationDate).getFullYear().toString()
                    } else {
                        return 'PENDING'
                    }
                })

            // util func to ensure that PENDING items are listed first
            const createTransactionMap = (objToMap) =>
                new Map(
                    [...Object.entries(objToMap)].sort((left, right) => {
                        if (left[0] === 'PENDING') {
                            return -1
                        } else if (right[0] === 'PENDING') {
                            return 1
                        } else {
                            return left[0] < right[0] ? 1 : left[0] > right[0] ? -1 : 0
                        }
                    })
                )

            const balanceTransfersByYear = groupObjByYear(transactionType['BALANCE_TRANSFER'])
            const cashOutsByYear = groupObjByYear(transactionType['CASH_OUT'])

            state.balanceTransfer.existingTransactions = {
                BALANCE_TRANSFERS: createTransactionMap(balanceTransfersByYear),
                CASH_OUT: createTransactionMap(cashOutsByYear),
            }
        },
        updateSelectedBalanceTransferAccount: (state, payload) => {
            state.balanceTransfer.selectedAccount = payload
        },
        updateActiveBalanceTransferAccountsEligibleForRecurrence: (state, payload: IBalanceTransferAccount[]) => {
            state.balanceTransfer.activeBalanceTransferAccountsEligibleForRecurrence = payload
        },
        updateMostRecentRecurringBalanceTransfer: (state, payload: IInitiatedBalanceTransfer) => {
            state.balanceTransfer.mostRecentRecurringBalanceTransfer = payload
        },
        resetState(state) {
            const isWebView = state.isSingleWebView
            const isSingleWebView = state.isSingleWebView
            const deviceInfo = state.deviceInfo
            const deviceCapabilities = state.deviceCapabilities

            Object.assign(state, getDefaultState())

            state.deviceInfo = deviceInfo
            state.isWebView = isWebView
            state.isSingleWebView = isSingleWebView
            state.deviceCapabilities = deviceCapabilities
        },
        updatePaymentSources: (state, payload: PaymentSource[]) => {
            state.paymentSources = payload
        },
        addNewPaymentSource: (state: any, payload: PaymentSource) => {
            state.paymentSources.push(payload)
        },
        removePaymentSource: (state: any, fundingTokenId: string) => {
            state.paymentSources = state.paymentSources.filter((s) => s.token !== fundingTokenId)
        },
        updateSelectedPaymentSource: (state, payload) => {
            state.selectedPaymentSource = payload
        },
        updateDeeplinkPath: (state, path) => {
            state.deeplinkPath = path
            appSessionStorage.setItem('deeplink', path)
        },
        updatePayItForward: (state, payload) => {
            state.overview.payItForward = payload
        },
        updateNumberOfCancelledCards: (state, payload: number) => {
            state.overview.numberOfCancelledCards = payload
        },
        updateAutoPaySetting: (state, payload: any) => {
            state.overview.autoPaySetting = payload
        },
        setDeviceCapabilities: (state, payload: { [key: string]: any } = {}) => {
            state.deviceCapabilities = payload
        },
        setPayoffDetails: (state, payload: PayoffDetails) => {
            state.payoffDetails = payload
        },
        setIsZeroBalancePayoff: (state, payload: boolean) => {
            state.isZeroBalancePayoff = payload
        },
        setCloseAccountReason: (state, payload: AccountClosureReason) => {
            state.closeAccountReason = payload
        },
        setCloseAccountKeepCard: (state, payload: boolean) => {
            state.closeAccountKeepCard = payload
        },
        disableBiometrics: (state) => {
            const current = state.deviceCapabilities
            state.deviceCapabilities = {
                ...current,
                Biometrics: false,
            }
        },
        setIsPasscodeSet: (state, payload: boolean) => {
            state.overview.isPasscodeSet = payload
        },
    },
    actions: {
        async updateAccountOverview({ commit }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await getAccountOverview()
                if (data.success) {
                    commit('updateOverview', data.payload)
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async activateCard({ commit }, payload) {
            // for local dev purpose
            // console.log('****** /src/store/index.ts FAKED SUCCESSFUL CARD ACTIVATION *********')
            // commit('cardActivated')
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await activateAvenCard(payload)
                if (data.success || data.error === 'CARD_ALREADY_ACTIVATED') {
                    commit('cardActivated')
                } else if (Object.values(CardActivationError).find((e) => e === data.error)) {
                    commit('setError', data.error)
                    if (data.error === CardActivationError.ERROR_IN_RESCISSION_PERIOD) {
                        commit('setErrorPayload', data.payload)
                    }
                } else {
                    throw new Error(`Unknown card activation with data: ${JSON.stringify(data)}`)
                }
            } catch (e) {
                parseError(commit, e)
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async replaceCard({ commit }, payload) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const response = await replaceCard(payload)
                if (!response.data.payload.success) {
                    commit('setError', response.data.error)
                } else {
                    commit('replacementCardOrdered')
                    commit('updateNumberOfCancelledCards', response.data.payload.numberOfCancelledCards)
                }
            } catch (e) {
                parseError(commit, e)
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async getStatements({ commit }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await statementList()
                if (data.success) {
                    commit('updateStatements', data.payload)
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async getTaxDocuments({ commit }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await taxDocumentList()
                if (data.success) {
                    commit('updateTaxDocuments', data.payload)
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async getExistingBalanceTransfers({ commit }) {
            try {
                const { data } = await getBalanceTransfers()
                if (data.success) {
                    commit('updateBalanceTransferTransactions', data.payload)
                } else {
                    // TODO: add error state
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            }
        },
        selectBalanceTransferAccount({ commit }, payload) {
            commit('updateSelectedBalanceTransferAccount', payload)
        },
        async getActiveBalanceTransferAccountsEligibleForRecurrence({ commit }) {
            try {
                logger.info(`Getting active balance transfer accounts`)
                const activeAccountsResponse = await getActiveBalanceTransferAccountsEligibleForRecurrence()
                const accounts = activeAccountsResponse.data.payload.activeBalanceTransferAccounts
                logger.info(`Got active balance transfer accounts ${JSON.stringify(accounts)}`)
                commit('updateActiveBalanceTransferAccountsEligibleForRecurrence', accounts)
            } catch (e) {
                logger.error(`Failed to get active balance transfer accounts: ${e.message}`)
                commit('updateActiveBalanceTransferAccountsEligibleForRecurrence', [])
            }
        },
        async updateAutoBalanceTransferEnabledForAccounts({ commit }, payload: IBalanceTransferAccount[]) {
            logger.info(`Updating auto balance transfer enabled for accounts: ${JSON.stringify(payload)}`)
            await updateAutoBalanceTransferEnabledAccounts(payload)
            logger.info(`Updated auto balance transfer enabled`)
            await this.dispatch('getActiveBalanceTransferAccountsEligibleForRecurrence')
        },
        async balanceTransferForRecurringAccount({ commit }, payload: IInitiatedBalanceTransfer) {
            logger.info(`Initiating balance transfer for recurring account ${JSON.stringify(payload)}`)
            await initiateBalanceTransferForRecurringAccount({
                token: payload.token,
                amount: payload.amount,
            })
            logger.info(`Updating most recent recurring balance transfer to ${JSON.stringify(payload)}`)
            commit('updateMostRecentRecurringBalanceTransfer', payload)
        },
        async fetchPaymentSources({ commit }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await aciSearchFundingSources()
                if (data.success) {
                    commit('updatePaymentSources', data.payload)
                } else {
                    // TODO: add error state
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async addPaymentSource({ commit, getters }, payload: { bankAccountType: AciAccountSubType; routingNumber: string; bankAccountNumber: string; accountNickName: string }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await aciGenerateFundingTokenBankAccount(
                    payload.bankAccountType,
                    payload.routingNumber,
                    payload.bankAccountNumber,
                    getters.fullName,
                    getters.customerInfo.state,
                    getters.customerInfo.postalCode,
                    payload.accountNickName
                )

                if (data.success) {
                    logger.info(`Successfully added payment source ${JSON.stringify(data)}`)
                    commit('addNewPaymentSource', data.payload)
                } else {
                    logger.info(`Error adding payment source ${JSON.stringify(data)}`)
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async deletePaymentSource({ commit, state, getters }, fundingTokenId: string) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const fundingSource = state.paymentSources.find((s) => s.token === fundingTokenId)
                assert(fundingSource, `trying to delete payment, cannot find source with token ${fundingTokenId}`)
                const { data } = await avenAciDeleteFundingSource(fundingTokenId)
                if (data.success) {
                    logger.info(`Successfully removed payment source ${JSON.stringify(data)}`)
                    if (getters.autoPaySetting.isEnabled && fundingTokenId === getters.autoPaySetting.autoPayDetails?.aciFundingTokenId) {
                        commit('updateAutoPaySetting', AUTO_PAY_DISABLED)
                    }
                    commit('removePaymentSource', fundingTokenId)
                } else {
                    logger.info(`Error removing payment source ${data.error}`)
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        selectPaymentSource({ commit }, payload) {
            commit('updateSelectedPaymentSource', payload)
        },
        async getPayItForward({ commit }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await getPayItForward()
                if (data.success) {
                    commit('updatePayItForward', data.payload)
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async refreshAutoPayDetails({ commit }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await avenAutopayDetails()
                if (data.success) {
                    commit('updateAutoPaySetting', data.payload)
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async autoPayOptIn({ commit, state }, payload: AutoPayDetails) {
            // if category is custom_amount then payload.customAmount must exist
            if (payload.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT) {
                assert(payload.customAmount, 'AutoPay category is CUSTOM_AMOUNT but auto pay amount is null/undefined')
            }
            const autoPayDetails = state.overview.autoPaySetting.autoPayDetails
            const autoPayPaymentSourceChanged = autoPayDetails?.aciFundingTokenId !== payload.aciFundingTokenId
            const autoPayCategoryChanged = autoPayDetails?.autoPayCategory !== payload.autoPayCategory
            const autoPayCustomAmountChanged = autoPayDetails?.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT && autoPayDetails.customAmount !== payload.customAmount
            logger.info(
                `AutoPay setting update, autoPayPaymentSourceChanged: ${autoPayPaymentSourceChanged}, autoPayCategoryChanged: ${autoPayCategoryChanged}, autoPayCustomAmountChanged: ${autoPayCustomAmountChanged},  current: ${JSON.stringify(
                    state.overview.autoPaySetting
                )}, next: ${JSON.stringify(payload)}`
            )

            if (!autoPayPaymentSourceChanged && !autoPayCategoryChanged && !autoPayCustomAmountChanged) {
                return
            }
            try {
                commit('updateLoading', true)
                commit('clearError')
                const paymentSource = state.paymentSources.find((payment) => payment.token === payload.aciFundingTokenId)
                assert(paymentSource, `autoPayOptIn, Cannot find payment source ${payload.aciFundingTokenId} in state.paymentSources`)
                const { data } = await avenAutopayOptIn(paymentSource.accountType, paymentSource.token, payload.autoPayCategory, payload.customAmount ? `${payload.customAmount}` : '')
                if (data.success) {
                    commit('updateAutoPaySetting', {
                        isEnabled: true,
                        autoPayDetails: payload,
                    })
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        async autoPayOptOut({ commit }) {
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await avenAutopayOptOut()
                if (data.success) {
                    commit('updateAutoPaySetting', AUTO_PAY_DISABLED)
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                commit('setError', 'NETWORK_ERROR')
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
        selectCloseAccountReason({ commit }, payload) {
            commit('setCloseAccountReason', payload)
        },
        updateCloseAccountKeepCard({ commit }, payload) {
            commit('setCloseAccountKeepCard', payload)
        },
        async getPayoffQuote({ commit }, payload: { requestDateInNycTime: Date; payoffDateInNycTime: Date }) {
            logger.info(`Getting payoff details, requestDateInNycTime: ${payload.requestDateInNycTime} payoffDateInNycTime: ${payload.payoffDateInNycTime}`)
            try {
                commit('updateLoading', true)
                commit('clearError')
                const { data } = await accountPayoffQuote(payload.requestDateInNycTime, payload.payoffDateInNycTime)
                if (data.success) {
                    console.info('payoff details: ' + data.payload)
                    commit('setPayoffDetails', {
                        payoffDateInNycTime: data.payload.payoffDateInNycTime,
                        totalCurrentBalance: data.payload.currentBalance,
                        interestAndFees: data.payload.interests + data.payload.fixedInstallmentFees,
                        totalPayoffAmount: data.payload.totalAmountDue,
                    })
                    if (data.payload.totalAmountDue === 0) {
                        commit('setIsZeroBalancePayoff', true)
                    }
                } else {
                    commit('setError', data.error)
                }
            } catch (e) {
                parseError(commit, e)
                throw e
            } finally {
                commit('updateLoading', false)
            }
        },
    },
    getters: {
        deviceInfo: (state): DeviceInfo => state.deviceInfo,
        fullName: (state) => `${state.customerInfo.firstName} ${state.customerInfo.lastName}`,
        lastName: (state) => state.customerInfo.lastName,
        accountNumber: (state) => state.overview.accountNumber,
        isOverViewInitialized: (state) => state.overview.cardType !== AvenCardUserType.UNKNOWN,
        accountIsClosed: (state) => state.overview.avenAccountStanding === AvenAccountStanding.closed,
        isReplacementCard: (state) => state.overview.isReplacementCard,
        haveNotActivatedCardsBefore: (state) =>
            !state.overview.isReplacementCard &&
            [CardActivationStatus.pending, CardActivationStatus.internalError, CardActivationStatus.exceededMaxOnlineActivationAttempts].includes(state.overview.cardActivationStatus),
        isPrimaryCard: (state) => state.overview.cardType === AvenCardUserType.PRIMARY,
        isCoApplicantCard: (state) => state.overview.cardType === AvenCardUserType.CO_APPLICANT,
        showEnrollAutoPayAndKeepAprDiscount: (state) => state.overview.showEnrollAutoPayAndKeepAprDiscount,
        alreadyShownEnrollInAutoPayAndKeepAprDiscount: (state) => state.overview.alreadyShownEnrollInAutoPayAndKeepAprDiscount,
        cardRequiresActivation: (state) =>
            [CardActivationStatus.pending, CardActivationStatus.internalError, CardActivationStatus.exceededMaxOnlineActivationAttempts].includes(state.overview.cardActivationStatus),
        apr: (state) => state.overview.apr,
        billingDay: (state) => state.overview.billingDay,
        creditLimit: (state) => state.overview.creditLimit,
        limitedModeCreditLimit: (state) => state.overview.limitedModeCreditLimit,
        isLimitedMode: (state) => state.overview.productType === AvenCardProductType.HOME_LIMITED_MODE,
        monthlyFee: (state) => state.overview.monthlyFee,
        productCategory: (state) => state.overview.productCategory,
        desiredProductCategory: (state) => state.overview.desiredProductCategory,
        showPrimeOption: (state) => state.overview.showPrimeOption,
        availableCredit: (state) => state.overview.availableCredit,
        currentBalance: (state) => state.overview.currentBalance,
        statementRemainingBalance: (state) => state.overview.statementRemainingBalance,
        paymentDueDate: (state) => state.overview.paymentDueDate,
        nextStatementDate: (state) => state.overview.nextStatementDate,
        loanTermOptions: (state) => state.overview.loanTermOptions,
        minimumDue: (state) => state.overview.minimumDue,
        isPasscodeSet: (state) => state.overview.isPasscodeSet,
        avenAccountStanding: (state) => state.overview.avenAccountStanding,
        last4CardNumber: (state) => state.overview.last4CardNumber,
        customerInfo: (state) => state.customerInfo,
        error: (state) => state.error,
        loading: (state) => state.loading,
        currentRootPath: (state) => state.currentRootPath || '/activity',
        isWebView: (state) => state.isWebView,
        isSingleWebView: (state) => state.isSingleWebView,
        statements: (state) => state.statements,
        taxDocuments: (state) => state.taxDocuments,
        balanceTransfer: (state) => state.balanceTransfer,
        cashOutFee: (state) => state.overview.cashOutFee,
        balanceTransferFee: (state) => state.overview.balanceTransferFee,
        existingBalanceTransfers: (state) => state.balanceTransfer.existingTransactions['BALANCE_TRANSFERS'],
        existingCashOutTransfers: (state) => state.balanceTransfer.existingTransactions['CASH_OUT'],
        activeBalanceTransferAccountsEligibleForRecurrence: (state): IBalanceTransferAccount[] => state.balanceTransfer.activeBalanceTransferAccountsEligibleForRecurrence,
        mostRecentRecurringBalanceTransfer: (state): IInitiatedBalanceTransfer => state.balanceTransfer.mostRecentRecurringBalanceTransfer,
        totalPendingCashOutPlusBalanceTransfers: (state) => state.overview.totalPendingCashOutPlusBalanceTransfers,
        madeRecentLargePayment: (state) => state.overview.madeRecentLargePayment,
        selectedPaymentAmount: (state) => state.selectedPaymentAmount,
        remainingRewardsPoints: (state) => state.overview.rewardsSummary.remaining,
        productType: (state) => state.overview.productType,
        isLoyaltyProduct: (state) => [AvenCardProductType.HOME, AvenCardProductType.AUTO, AvenCardProductType.CRYPTO].includes(state.overview.productType),
        dollarToCashbackRewardPointsConversion: (state) => state.overview.dollarToCashbackRewardPointsConversion,
        showCashOutFeature: (state) => state.overview.showCashOutFeature,
        cashOutTotalAmountWithFeeMultiplier: (state) => state.overview.cashOutTotalAmountWithFeeMultiplier,
        isLienPerfected: (state) => state.overview.isLienPerfected,
        payItForwardShareLink: (state) => state.overview.payItForward.shareLink,
        payItForwardMaxAmount: (state) => state.overview.payItForward.maxAmount,
        payItForwardTipMaxAmount: (state) => state.overview.payItForwardTipInfo.maxAmount,
        isEligibleToPerformPayItForwardTipping: (state) => state.overview.payItForwardTipInfo.isEligible,
        signOnBonusAmount: (state) => state.overview.signOnBonus.bonusAmount,
        signOnBonusSourceName: (state) => state.overview.signOnBonus.sourceName,
        showSignOnBonusRealEstateAgentScreen: (state) => {
            const haveNotActivatedCardsBefore =
                !state.overview.isReplacementCard &&
                [CardActivationStatus.pending, CardActivationStatus.internalError, CardActivationStatus.exceededMaxOnlineActivationAttempts].includes(state.overview.cardActivationStatus)
            return state.overview.signOnBonus.type === 'realEstateAgent' && haveNotActivatedCardsBefore
        },
        payItForwardSourceName: (state) => state.overview.payItForwardTipInfo.sourceName,
        enableAmortizationLoan: (state) => state.overview.enableAmortizationLoan,
        autoPaySetting: (state) => state.overview.autoPaySetting,
        rescissionInfo: (state) => state.overview.rescissionInfo,
        nextAutoPayAmount: (state) => {
            let amount = 0
            const autoPayDetails = state.overview.autoPaySetting.autoPayDetails
            if (autoPayDetails?.autoPayCategory === AutopayCategory.CUSTOM_AMOUNT) {
                amount = Math.min(autoPayDetails.customAmount, state.overview.statementRemainingBalance)
            } else if (autoPayDetails?.autoPayCategory === AutopayCategory.STATEMENT_BALANCE) {
                amount = state.overview.statementRemainingBalance
            } else if (autoPayDetails?.autoPayCategory === AutopayCategory.MINIMUM_DUE) {
                amount = state.overview.minimumDue
            }
            return amount
        },
        isCryptoProduct: (state) => state.overview.productType === AvenCardProductType.CRYPTO,
        btcDepositAddress: (state) => state.overview.btcDepositAddress,
        isInMarginCall: (state) => state.overview.isInMarginCall,
        deviceCapabilities: (state) => state.deviceCapabilities,
        isFirstSessionForCustomer: (state) => state.overview.isFirstSessionForCustomer,
        payoffDetails: (state) => state.payoffDetails,
        isZeroBalancePayoff: (state) => state.isZeroBalancePayoff,
        closeAccountReason: (state) => state.closeAccountReason,
        shouldCloseAccountKeepCard: (state) => state.closeAccountKeepCard,
        biometricsEnabled: (state) => state.isSingleWebView && state.deviceCapabilities[DeviceCapabilities.Biometrics],
        deeplinkPath: (state) => state.deeplinkPath,
    },
    modules: {},
    plugins: [logRocketPlugin, avenVuxLogger],
})
