<template>
    <header>
        <div class="container">
            <h4 class="mb-0">
                {{ title }}
            </h4>
            <div class="d-flex">
                <div
                    @click="goToPayItForward"
                    class="btn-pill bg-success text-white me-2"
                >
                    Gift $400
                </div>
                <img
                    class="help-btn"
                    src="@/assets/images/components/navbar/Help.svg"
                    width="24"
                    height="24"
                    @click="showWebWidget"
                    @keydown.enter="showWebWidget"
                    alt="Help"
                >
            </div>
        </div>
    </header>
</template>

<script>
    import zendeskMixin from '@/mixins/zendeskMixin'
    import goToPayItForward from '@/mixins/goToPayItForward'
    export default {
        name: 'Header',
        mixins: [zendeskMixin, goToPayItForward],
        props: {
            title: {
                type: String,
                required: true,
            },
        },
    }
</script>

<style lang="scss" scoped>
    @import '../styles/components/appHeader';
</style>
