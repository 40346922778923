<template>
    <div class="d-flex justify-content-around">
        <common-action-button
            v-for="button in buttons"
            :key="button.text + button.iconSrc"
            :icon-src="button.iconSrc"
            :text="button.text"
            :invert-icon-color="button.invertIconColor"
            @click="handleButtonClick(button)"
            :data-testid="button.dataTestId"
        />
    </div>
</template>
<script>
    import CommonActionButton from './CommonActionButton'

    export default {
        name: 'CommonActions',
        components: {
            CommonActionButton,
        },
        data() {
            return {
                buttons: [
                    {
                        text: this.$t('components.commonActions.makePayment'),
                        iconSrc: 'images/components/commonActions/Payment.svg',
                        invertIconColor: false,
                        goto: 'makePayment',
                        dataTestId: 'common-action-button-make-payment',
                    },
                    {
                        text: this.$t('components.commonActions.cardBalance'),
                        iconSrc: 'images/components/commonActions/BalanceTransfer.svg',
                        invertIconColor: false,
                        goto: 'balanceTransfer',
                        dataTestId: 'common-action-button-balance-transfer',
                    },
                    {
                        text: this.$t('components.commonActions.cashOut'),
                        iconSrc: 'images/components/commonActions/Cash.svg',
                        invertIconColor: false,
                        goto: 'cashOut',
                        dataTestId: 'common-action-button-cash-out',
                    },
                ],
            }
        },
        methods: {
            handleButtonClick(button) {
                const { onClick, goto } = button
                // Not used right now but allows grater flexibility for buttons to do anything,
                // not just change routes
                if (typeof onClick === 'function') {
                    onClick(...arguments)
                }
                // Go to the specified route
                if (typeof goto === 'string') {
                    this.$router.push(goto)
                }
            },
        },
    }
</script>
